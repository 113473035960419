import React, { useEffect, Suspense } from 'react';
import { IonApp } from '@ionic/react';
import { withOAuth } from 'aws-amplify-react';
import Loading from './components/Loading';
import Amplify from 'aws-amplify';
import { useUser } from './context/UserProvider';
import * as firebase from "firebase/app";
import 'firebase/auth';
import firebaseConfig from './firebase-config';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/App.scss';
import { Role } from './API';
// import './theme/variables.css';
// import './theme/main.css';
const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

firebase.initializeApp(firebaseConfig);

const awsconfig = {
  aws_project_region: "ap-southeast-2",
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: "ap-southeast-2",
  aws_appsync_authenticationType: "OPENID_CONNECT",
  API: {
    endpoints: [
      {
        name: "REST_API",
        endpoint: process.env.REACT_APP_REST_API,
        custom_header: async () => {
          return { Authorization: await firebase?.auth().currentUser?.getIdToken() };
        }
      }
    ],
    graphql_headers: async () => ({
      Authorization: await firebase?.auth().currentUser?.getIdToken()
    })
  }
};

Amplify.configure(awsconfig);

const App: React.FC = () => {
  const { user, loading } = useUser();

  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  const renderLoader = () => <Loading>Loading...</Loading>;

  if (loading) return renderLoader();
  return (
    <IonApp>
      <Suspense fallback={renderLoader()}>
        {user && [Role.superuser].includes(user.userRole) ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </Suspense>
    </IonApp>
  );
};


export default withOAuth(App);