import React, { useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import firebase from 'firebase';
import { IonAlert, isPlatform } from '@ionic/react';
import { Browser } from '@capacitor/browser';
import { API, graphqlOperation, Cache } from 'aws-amplify';
import { GetUserQuery } from '../API';
import { getUser } from '../graphql/queries';

interface UserContextInterface {
  user: null | GetUserQuery;
  setUser: Dispatch<SetStateAction<null | GetUserQuery>>;
  loading: boolean;
  error: ReactNode | undefined;
  login: () => void;
  logout: () => void;
}

const UserContext = React.createContext<UserContextInterface | null>(null);

const UserProvider: React.FC = (props) => {
  const [user, setUser] = useState<null | GetUserQuery>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ReactNode | undefined>();

  const appLink = () => {
    if (isPlatform('android')) {
      return 'https://play.google.com/store/apps/details?id=com.compeatnutrition.client';
    } else if (isPlatform('ios')) {
      return 'https://apps.apple.com/us/app/id1506881706';
    } else {
      return 'https://client.compeatnutrition.com';
    }
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (_user) {
      if (_user) {
        _user.getIdToken().then(async (_token) => {
          copyStringToClipboard(_token);
          // alert('new token in clipboard');
          Cache.setItem('federatedInfo', { token: _token });

          (API.graphql(graphqlOperation(getUser, { id: _user.uid })) as Promise<any>).then((result) => {
            setUser(result.data.getUser);
          }).catch(() => {
            let message = `Hi there, this app is only for our Compeat staff. If you are a Compeat customer, please use the Compeat app to chat with your own specialist.`;
            setError(
              <IonAlert
                isOpen
                header="Unauthorised"
                message={message}
                buttons={[
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                      setError(false);
                    }
                  },
                  {
                    text: 'Okay, take me there!',
                    cssClass: 'font-weight-bold',
                    handler: () => {
                      Browser.open({ url: appLink() });
                    }
                  }
                ]}
              />
            );
            setUser(null);
          }).finally(() => {
            setLoading(false);
          });
        });
      } else {
        console.log("User is signed out");
        setUser(null);
        setLoading(false);
      }
    });
  }, []);

  const login = () => {
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    firebase.auth().signInWithPopup(provider);
    // firebase.auth().signInWithRedirect(provider);
  };
  const logout = () => firebase.auth().signOut();

  return (
    <UserContext.Provider value={{ user, setUser, loading, error, login, logout }} {...props} />
  );
};

function copyStringToClipboard(str: string) {
  // Create new element
  let el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.setAttribute('class', 'd-none');
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}


const useUser = () => {
  const context = React.useContext(UserContext) as UserContextInterface;
  if (context === undefined) {
    throw new Error(`useAuth must be used within a UserProvider`);
  }
  return context;
};

export { useUser };
export default UserProvider;
