/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      authId
      email
      firstName
      lastName
      userRole
      lastActiveAt
      createdAt
      updatedAt
      status
      devices {
        items {
          id
          token
          platform
          bundleId
          deviceArn
          deviceUserId
          createdAt
          updatedAt
        }
        nextToken
      }
      editors
      contactInfoId
      contactInfo {
        id
        firstName
        lastName
        avatar
        email
        phone
        permissionToShare
        createdAt
        updatedAt
      }
      clientProfile {
        items {
          id
          userId
          specialistId
          status
          servicingNote
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authId
        email
        firstName
        lastName
        lastActiveAt
        createdAt
        updatedAt
        status
        devices {
          items {
            id
            token
            platform
            bundleId
            deviceArn
            deviceUserId
            createdAt
            updatedAt
          }
          nextToken
        }
        editors
        contactInfoId
        contactInfo {
          id
          firstName
          lastName
          avatar
          email
          phone
          permissionToShare
          createdAt
          updatedAt
        }
        clientProfile {
          items {
            id
            userId
            specialistId
            status
            servicingNote
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const userByStatus = /* GraphQL */ `
  query UserByStatus(
    $status: Status
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        authId
        email
        firstName
        lastName
        lastActiveAt
        createdAt
        updatedAt
        status
        devices {
          items {
            id
            token
            platform
            bundleId
            deviceArn
            deviceUserId
            createdAt
            updatedAt
          }
          nextToken
        }
        editors
        contactInfoId
        contactInfo {
          id
          firstName
          lastName
          avatar
          email
          phone
          permissionToShare
          createdAt
          updatedAt
        }
        clientProfile {
          items {
            id
            userId
            specialistId
            contacts {
              items {
                id
                contactInfoId
                clientId
                type
                contactInfo {
                  id
                  userId
                  firstName
                  lastName
                  avatar
                  email
                  phone
                  permissionToShare
                  createdAt
                  updatedAt
                }
                client {
                  id
                  userId
                  specialistId
                  status
                  servicingNote
                  settings
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            conversations {
              items {
                id
                specialistClientId
                clientId
                specialistId
                messages {
                  items {
                    id
                    content
                    createdAt
                    messageAuthorId
                    readBy
                    messageConversationId
                    updatedAt
                  }
                  nextToken
                }
                status
                servicingNote
                notes {
                  title
                  content
                  createdAt
                  authorId
                }
                files {
                  title
                  url
                  type
                  createdAt
                }
                settings
                members
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            servicingNote
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        specialists {
          items {
            id
            clientId
            specialistId
            specialist {
              id
              displayName
              businessName
              qualifications
              clinicalSpecialities
              sportSpecialities
              bio
              trivia
              providerNumber
              businessIdNumber
              gender
              image
              video
              status
              isAvailable
              clientLimit
              ownerId
              createdAt
              updatedAt
            }
            messages {
              items {
                id
                content
                createdAt
                messageAuthorId
                readBy
                messageConversationId
                updatedAt
              }
              nextToken
            }
            status
            servicingNote
            notes {
              title
              content
              createdAt
              authorId
            }
            files {
              title
              url
              type
              createdAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getSpecialistProfile = /* GraphQL */ `
  query GetSpecialistProfile($id: ID!) {
    getSpecialistProfile(id: $id) {
      id
      displayName
      businessName
      qualifications
      clinicalSpecialities
      sportSpecialities
      bio
      trivia
      providerNumber
      businessIdNumber
      gender
      image
      video
      status
      isAvailable
      clientLimit
      isTeamsAvailable
      socialAccounts {
        network
        account
      }
      referralLink
      integrations {
        name
        attributes
      }
      ownerId
      clients {
        items {
          id
          specialistClientId
          clientId
          specialistId
          status
          servicingNote
          settings
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      myClients {
        items {
          id
          userId
          specialistId
          user {
            id
            authId
            email
            firstName
            lastName
            lastActiveAt
            createdAt
            updatedAt
            status
            devices {
              items {
                id
                token
                platform
                bundleId
                deviceArn
                deviceUserId
                createdAt
                updatedAt
              }
              nextToken
            }
            editors
            contactInfoId
            contactInfo {
              id
              firstName
              lastName
              avatar
              email
              phone
              permissionToShare
              createdAt
              updatedAt
            }
            clientProfile {
              items {
                id
                userId
                specialistId
                status
                servicingNote
                settings
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          conversations {
            items {
              id
              specialistClientId
              clientId
              specialistId
              status
              servicingNote
              notes {
                title
                content
                createdAt
                authorId
              }
              files {
                title
                url
                type
                createdAt
              }
              settings
              members
              createdAt
              updatedAt
            }
            nextToken
          }
          status
          servicingNote
          settings
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSpecialistProfiles = /* GraphQL */ `
  query ListSpecialistProfiles(
    $filter: ModelSpecialistProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialistProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        displayName
        businessName
        qualifications
        clinicalSpecialities
        sportSpecialities
        bio
        trivia
        providerNumber
        businessIdNumber
        gender
        image
        video
        status
        isAvailable
        clientLimit
        isTeamsAvailable
        socialAccounts {
          network
          account
        }
        referralLink
        integrations {
          name
          attributes
        }
        ownerId
        clients {
          items {
            id
            specialistClientId
            clientId
            specialistId
            status
            servicingNote
            settings
            members
            createdAt
            updatedAt
          }
          nextToken
        }
        myClients {
          items {
            id
            userId
            specialistId
            user {
              id
              authId
              email
              firstName
              lastName
              lastActiveAt
              createdAt
              updatedAt
              status
              devices {
                items {
                  id
                  token
                  platform
                  bundleId
                  deviceArn
                  deviceUserId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              editors
              contactInfoId
              contactInfo {
                id
                firstName
                lastName
                avatar
                email
                phone
                permissionToShare
                createdAt
                updatedAt
              }
              clientProfile {
                items {
                  id
                  userId
                  specialistId
                  status
                  servicingNote
                  settings
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            conversations {
              items {
                id
                specialistClientId
                clientId
                specialistId
                status
                servicingNote
                notes {
                  title
                  content
                  createdAt
                  authorId
                }
                files {
                  title
                  url
                  type
                  createdAt
                }
                settings
                members
                createdAt
                updatedAt
              }
              nextToken
            }
            status
            servicingNote
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!, $nextMessagesToken: String, $messageLimit: Int) {
    getConversation(id: $id) {
      id
      specialistClientId
      clientId
      specialistId
      client {
        contactInfo {
          firstName
          lastName
        }
      }
      specialist {
        displayName
      }
      messages(limit: $messageLimit, nextToken: $nextMessagesToken) {
        items {
          id
          content
          createdAt
          messageAuthorId
          readBy
          messageConversationId
          updatedAt
        }
        nextToken
      }
      status
      servicingNote
      notes {
        title
        content
        createdAt
        authorId
      }
      files {
        title
        url
        type
        createdAt
      }
      settings
      members
      createdAt
      updatedAt
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      coupon
      name
      specialistIds
      hasDiscount
      teamId
      percent_off
      max_redemptions
      createdAt
      updatedAt
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        coupon
        name
        specialistIds
        hasDiscount
        teamId
        percent_off
        max_redemptions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      logo
      coupons
      users
      specialists
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        coupons
        users
        specialists
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFood = /* GraphQL */ `
  query GetFood($id: ID!) {
    getFood(id: $id) {
      id
      item
      measurementUnits
      unit
      aisle
      allergens
      density
      createdAt
      updatedAt
    }
  }
`;
export const listFoods = /* GraphQL */ `
  query ListFoods(
    $filter: ModelFoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFoods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        item
        measurementUnits
        unit
        aisle
        allergens
        density
        createdAt
      }
      nextToken
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        specialistId
        user {
          id
          email
          firstName
          lastName
          profile {
            sex
            pronouns
            ageBracket
          }
          avatar
          phone
          gender
          lastActiveAt
          createdAt
          updatedAt
          status
          editors
          viewers
          contactInfoId
          contactInfo {
            id
            userId
            email
            firstName
            lastName
            avatar
            phone
            permissionToShare
            createdAt
            updatedAt
          }
          deletedAt
        }
        specialist {
          id
          displayName
          status
          ownerId
          createdAt
          updatedAt
        }
        conversations {
          items {
            id
            specialistClientId
            clientId
            specialistId
            status
            members
            createdAt
            updatedAt
          }
          nextToken
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;