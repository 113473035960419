import React from 'react';
import FadeIn from 'react-fade-in';
import Lottie from 'react-lottie';
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import animationData from '../animations/410-lego-loader.json';

const rowStyle = {
  height: '100vh'
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Loading: React.FC = (props) => {
  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center" style={rowStyle}>
            <IonCol size="12" className="ion-text-center">
              <FadeIn>
                {props.children}
                <Lottie options={defaultOptions} height={120} width={120} />
              </FadeIn>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>

  );
};

export default Loading;
