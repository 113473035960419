/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type User = {
  __typename: "User",
  id: string,
  authId?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  profile?: Profile | null,
  avatar?: string | null,
  phone?: string | null,
  gender?: string | null,
  dateOfBirth?: string | null,
  bodyType?: string | null,
  height?: string | null,
  weight?: number | null,
  bodyFat?: number | null,
  activityFactor?: number | null,
  nutritionNotes?: string | null,
  joinReason?: string | null,
  trainingCalendarLink?: string | null,
  guessedLocation?: string | null,
  journal?: Array<Record | null> | null,
  events?: Array<Event | null> | null,
  sports?: Array<Sport | null> | null,
  goals?: Array<Goal | null> | null,
  teams?: Array<TeamGroup | null> | null,
  foodDiary?: Array<DailyLog | null> | null,
  menuScheduleDay?: Day | null,
  nutritionInformation?: Nutrition | null,
  nutritionAssessments?: Array<NutritionAssessment | null> | null,
  lifestyle?: Lifestyle | null,
  healthInformation?: HealthInformation | null,
  membership?: Membership | null,
  stripeCustomerId?: string | null,
  permission?: Permission | null,
  priority?: Priority | null,
  priorityConfidence?: Array<PriorityConfidence | null> | null,
  userRole?: Role | null,
  lastActiveAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  paymentMethod?: PaymentMethod | null,
  status?: Status | null,
  devices?: ModelDeviceConnection | null,
  messages?: ModelMessageConnection | null,
  specialists?: ModelConversationConnection | null,
  alerts?: ModelAlertConnection | null,
  notifications?: ModelAlertConnection | null,
  editors?: Array<string | null> | null,
  viewers?: Array<string | null> | null,
  contactInfoId?: string | null,
  contactInfo?: ContactInfo | null,
  clientProfile?: ModelClientConnection | null,
  deletedAt?: string | null,
};

export type Profile = {
  __typename: "Profile",
  sex?: string | null,
  pronouns?: string | null,
  ageBracket?: string | null,
};

export type Record = {
  __typename: "Record",
  weight?: string | null,
  waist?: string | null,
  appetiteRating?: number | null,
  energyRating?: number | null,
  sleepRating?: number | null,
  motivationRating?: number | null,
  wellnessRating?: number | null,
  stressRating?: number | null,
  nutritionRating?: number | null,
  createdAt: string,
};

export type Event = {
  __typename: "Event",
  name: string,
  details?: string | null,
  date?: string | null,
  location?: string | null,
  goal?: string | null,
  preEventFoodRituals?: string | null,
  nutritionProducts?: string | null,
  nutritionLogistics?: string | null,
  issues?: string | null,
  leadupEvents?: string | null,
  accomodation?: string | null,
  leadupEventDate?: string | null,
  leadupEventDetails?: string | null,
  createdAt: string,
};

export type Sport = {
  __typename: "Sport",
  name?: string | null,
  level?: string | null,
  comments?: string | null,
};

export type Goal = {
  __typename: "Goal",
  description: string,
  comments?: string | null,
  progress?: Array<Progress | null> | null,
  targetDate?: string | null,
  createdAt: string,
};

export type Progress = {
  __typename: "Progress",
  rating: number,
  createdAt: string,
};

export type TeamGroup = {
  __typename: "TeamGroup",
  name?: string | null,
  joinedAt?: string | null,
};

export type DailyLog = {
  __typename: "DailyLog",
  date?: string | null,
  day?: string | null,
  items?: Array<LogItem | null> | null,
};

export type LogItem = {
  __typename: "LogItem",
  time?: string | null,
  detail?: string | null,
  comments?: string | null,
};

export enum Day {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday",
}


export type Nutrition = {
  __typename: "Nutrition",
  allergies?: Array<string | null> | null,
  supplements?: string | null,
  dislikes?: string | null,
  comments?: string | null,
  preferences?: string | null,
  goals?: Array<Goal | null> | null,
  dietaryRequirements?: Array<string | null> | null,
};

export type NutritionAssessment = {
  __typename: "NutritionAssessment",
  requestDate: string,
  responseStartDate?: string | null,
  responseCompleteDate?: string | null,
  responses?: Array<Response | null> | null,
  score?: number | null,
};

export type Response = {
  __typename: "Response",
  question?: string | null,
  answer?: string | null,
  comments?: string | null,
  score?: number | null,
};

export type Lifestyle = {
  __typename: "Lifestyle",
  cookingSkills?: string | null,
  cookingMethods?: Array<string | null> | null,
  easyNights?: Array<Day | null> | null,
  homeSituation?: string | null,
  peopleToFeed?: string | null,
  workDays?: Array<Day | null> | null,
  nightsEatingOut?: Array<Day | null> | null,
  workShifts?: string | null,
  breakfastOnTheRun?: Array<Day | null> | null,
  occupation?: string | null,
  preferences?: string | null,
};

export type HealthInformation = {
  __typename: "HealthInformation",
  medicalConditions?: string | null,
  medication?: string | null,
  isMenstruating?: boolean | null,
  regularPeriod?: boolean | null,
  periodStoppedOver3Months?: string | null,
};

export type Membership = {
  __typename: "Membership",
  id?: string | null,
  amount?: string | null,
  plan?: string | null,
  discount?: string | null,
  createdAt?: string | null,
  expiresAt?: string | null,
  cancelAtPeriodEnd?: boolean | null,
};

export type Permission = {
  __typename: "Permission",
  hasMeals?: boolean | null,
  hasHabits?: boolean | null,
};

export type Priority = {
  __typename: "Priority",
  bonking?: number | null,
  energyLevels?: number | null,
  gutUpset?: number | null,
  manageCramping?: number | null,
  muscleMass?: number | null,
  overallHealth?: number | null,
  performance?: number | null,
  raceDayNutrition?: number | null,
  trainingAdaptations?: number | null,
  weightLoss?: number | null,
  createdAt: string,
};

export type PriorityConfidence = {
  __typename: "PriorityConfidence",
  bonking?: number | null,
  energyLevels?: number | null,
  gutUpset?: number | null,
  manageCramping?: number | null,
  muscleMass?: number | null,
  overallHealth?: number | null,
  performance?: number | null,
  raceDayNutrition?: number | null,
  trainingAdaptations?: number | null,
  weightLoss?: number | null,
  createdAt: string,
};

export enum Role {
  user = "user",
  specialist = "specialist",
  compeat = "compeat",
  superuser = "superuser",
}


export type PaymentMethod = {
  __typename: "PaymentMethod",
  id?: string | null,
  type?: string | null,
  brand?: string | null,
  exp_month?: number | null,
  exp_year?: number | null,
  last4?: string | null,
};

export enum Status {
  active = "active",
  pending = "pending",
  inactive = "inactive",
  archived = "archived",
  draft = "draft",
  approved = "approved",
  published = "published",
}


export type ModelDeviceConnection = {
  __typename: "ModelDeviceConnection",
  items: Array<Device | null>,
  nextToken?: string | null,
};

export type Device = {
  __typename: "Device",
  id: string,
  token: string,
  platform: string,
  bundleId: string,
  deviceArn?: string | null,
  deviceUserId?: string | null,
  user?: User | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelMessageConnection = {
  __typename: "ModelMessageConnection",
  items: Array<Message | null>,
  nextToken?: string | null,
};

export type Message = {
  __typename: "Message",
  id: string,
  content?: string | null,
  createdAt?: string | null,
  messageAuthorId?: string | null,
  readBy?: Array<string | null> | null,
  author?: User | null,
  messageConversationId: string,
  conversation?: Conversation | null,
  updatedAt: string,
};

export type Conversation = {
  __typename: "Conversation",
  id: string,
  specialistClientId?: string | null,
  clientId?: string | null,
  specialistId?: string | null,
  title?: string | null,
  client?: User | null,
  specialistClient?: Client | null,
  specialist?: SpecialistProfile | null,
  messages?: ModelMessageConnection | null,
  status?: Status | null,
  servicingNote?: string | null,
  notes?: Array<Note | null> | null,
  files?: Array<File | null> | null,
  settings?: string | null,
  members?: Array<string | null> | null,
  createdAt: string,
  updatedAt: string,
};

export type Client = {
  __typename: "Client",
  id: string,
  userId: string,
  specialistId: string,
  user: User,
  specialist: SpecialistProfile,
  contacts?: ModelContactConnection | null,
  conversations?: ModelConversationConnection | null,
  status?: Status | null,
  servicingNote?: string | null,
  notes?: Array<Note | null> | null,
  clientNotes?: ModelClientNoteConnection | null,
  files?: Array<File | null> | null,
  settings?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type SpecialistProfile = {
  __typename: "SpecialistProfile",
  id: string,
  displayName?: string | null,
  businessName?: string | null,
  qualifications?: string | null,
  clinicalSpecialities?: Array<string | null> | null,
  sportSpecialities?: Array<string | null> | null,
  bio?: string | null,
  trivia?: Array<string | null> | null,
  providerNumber?: string | null,
  businessIdNumber?: string | null,
  gender?: string | null,
  dateOfBirth?: string | null,
  image?: string | null,
  video?: string | null,
  status?: string | null,
  isAvailable?: boolean | null,
  clientLimit?: number | null,
  isTeamsAvailable?: boolean | null,
  socialAccounts?: Array<SocialAccount | null> | null,
  referralLink?: string | null,
  integrations?: Array<Integration | null> | null,
  ownerId: string,
  owner?: User | null,
  clients?: ModelConversationConnection | null,
  myClients?: ModelClientConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type SocialAccount = {
  __typename: "SocialAccount",
  network?: string | null,
  account?: string | null,
};

export type Integration = {
  __typename: "Integration",
  name?: string | null,
  attributes?: string | null,
};

export type ModelConversationConnection = {
  __typename: "ModelConversationConnection",
  items: Array<Conversation | null>,
  nextToken?: string | null,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items: Array<Client | null>,
  nextToken?: string | null,
};

export type ModelContactConnection = {
  __typename: "ModelContactConnection",
  items: Array<Contact | null>,
  nextToken?: string | null,
};

export type Contact = {
  __typename: "Contact",
  id: string,
  contactInfoId: string,
  clientId: string,
  type?: ContactType | null,
  contactInfo?: ContactInfo | null,
  client?: Client | null,
  createdAt: string,
  updatedAt: string,
};

export enum ContactType {
  Client = "Client",
  Parent = "Parent",
  Guardian = "Guardian",
}


export type ContactInfo = {
  __typename: "ContactInfo",
  id: string,
  userId?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  avatar?: string | null,
  phone?: string | null,
  permissionToShare?: boolean | null,
  contacts?: ModelContactConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Note = {
  __typename: "Note",
  title: string,
  content: string,
  createdAt: string,
  authorId?: string | null,
};

export type ModelClientNoteConnection = {
  __typename: "ModelClientNoteConnection",
  items: Array<ClientNote | null>,
  nextToken?: string | null,
};

export type ClientNote = {
  __typename: "ClientNote",
  id: string,
  clientId: string,
  title: string,
  createdAt?: string | null,
  flag?: string | null,
  goals?: string | null,
  focusAreas?: string | null,
  goalsFocusAreas?: string | null,
  progressChallenges?: string | null,
  progress?: string | null,
  challenges?: string | null,
  isOnApp?: boolean | null,
  engagement?: string | null,
  engagementStatus?: string | null,
  clinical?: string | null,
  symptoms?: string | null,
  anthropometry?: string | null,
  dietHistory?: string | null,
  training?: string | null,
  results?: string | null,
  social?: string | null,
  energyRequirements?: string | null,
  supplementsMedication?: string | null,
  other?: string | null,
  futureMe?: string | null,
  actionNeeded?: string | null,
  comments?: string | null,
  updatedAt: string,
};

export type File = {
  __typename: "File",
  title: string,
  url: string,
  type?: string | null,
  createdAt: string,
};

export type ModelAlertConnection = {
  __typename: "ModelAlertConnection",
  items: Array<Alert | null>,
  nextToken?: string | null,
};

export type Alert = {
  __typename: "Alert",
  id: string,
  content?: string | null,
  action?: Action | null,
  isRead?: boolean | null,
  createdAt: string,
  updatedAt: string,
  alertUserId: string,
  user?: User | null,
};

export enum Action {
  badge = "badge",
  notification = "notification",
  splash = "splash",
  slides = "slides",
  assessment = "assessment",
}


export type UpdateAlertInput = {
  id: string,
  content?: string | null,
  action?: Action | null,
  isRead?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  alertUserId?: string | null,
};

export type ModelAlertConditionInput = {
  content?: ModelStringInput | null,
  action?: ModelActionInput | null,
  isRead?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array<ModelAlertConditionInput | null> | null,
  or?: Array<ModelAlertConditionInput | null> | null,
  not?: ModelAlertConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array<number | null> | null,
};

export type ModelActionInput = {
  eq?: Action | null,
  ne?: Action | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DeleteAlertInput = {
  id: string,
};

export type CreateClientInput = {
  id?: string | null,
  userId: string,
  specialistId: string,
  status?: Status | null,
  servicingNote?: string | null,
  notes?: Array<NoteInput | null> | null,
  files?: Array<FileInput | null> | null,
  settings?: string | null,
};

export type NoteInput = {
  title: string,
  content: string,
  createdAt: string,
  authorId?: string | null,
};

export type FileInput = {
  title: string,
  url: string,
  type?: string | null,
  createdAt: string,
};

export type ModelClientConditionInput = {
  userId?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  servicingNote?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  and?: Array<ModelClientConditionInput | null> | null,
  or?: Array<ModelClientConditionInput | null> | null,
  not?: ModelClientConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type UpdateClientInput = {
  id: string,
  userId?: string | null,
  specialistId?: string | null,
  status?: Status | null,
  servicingNote?: string | null,
  notes?: Array<NoteInput | null> | null,
  files?: Array<FileInput | null> | null,
  settings?: string | null,
};

export type DeleteClientInput = {
  id: string,
};

export type CreateContactInput = {
  id?: string | null,
  contactInfoId: string,
  clientId: string,
  type?: ContactType | null,
};

export type ModelContactConditionInput = {
  contactInfoId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  type?: ModelContactTypeInput | null,
  and?: Array<ModelContactConditionInput | null> | null,
  or?: Array<ModelContactConditionInput | null> | null,
  not?: ModelContactConditionInput | null,
};

export type ModelContactTypeInput = {
  eq?: ContactType | null,
  ne?: ContactType | null,
};

export type UpdateContactInput = {
  id: string,
  contactInfoId?: string | null,
  clientId?: string | null,
  type?: ContactType | null,
};

export type DeleteContactInput = {
  id: string,
};

export type CreateClientNoteInput = {
  id?: string | null,
  clientId: string,
  title: string,
  createdAt?: string | null,
  flag?: string | null,
  goals?: string | null,
  focusAreas?: string | null,
  goalsFocusAreas?: string | null,
  progressChallenges?: string | null,
  progress?: string | null,
  challenges?: string | null,
  isOnApp?: boolean | null,
  engagement?: string | null,
  engagementStatus?: string | null,
  clinical?: string | null,
  symptoms?: string | null,
  anthropometry?: string | null,
  dietHistory?: string | null,
  training?: string | null,
  results?: string | null,
  social?: string | null,
  energyRequirements?: string | null,
  supplementsMedication?: string | null,
  other?: string | null,
  futureMe?: string | null,
  actionNeeded?: string | null,
  comments?: string | null,
};

export type ModelClientNoteConditionInput = {
  clientId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  flag?: ModelStringInput | null,
  goals?: ModelStringInput | null,
  focusAreas?: ModelStringInput | null,
  goalsFocusAreas?: ModelStringInput | null,
  progressChallenges?: ModelStringInput | null,
  progress?: ModelStringInput | null,
  challenges?: ModelStringInput | null,
  isOnApp?: ModelBooleanInput | null,
  engagement?: ModelStringInput | null,
  engagementStatus?: ModelStringInput | null,
  clinical?: ModelStringInput | null,
  symptoms?: ModelStringInput | null,
  anthropometry?: ModelStringInput | null,
  dietHistory?: ModelStringInput | null,
  training?: ModelStringInput | null,
  results?: ModelStringInput | null,
  social?: ModelStringInput | null,
  energyRequirements?: ModelStringInput | null,
  supplementsMedication?: ModelStringInput | null,
  other?: ModelStringInput | null,
  futureMe?: ModelStringInput | null,
  actionNeeded?: ModelStringInput | null,
  comments?: ModelStringInput | null,
  and?: Array<ModelClientNoteConditionInput | null> | null,
  or?: Array<ModelClientNoteConditionInput | null> | null,
  not?: ModelClientNoteConditionInput | null,
};

export type UpdateClientNoteInput = {
  id: string,
  clientId?: string | null,
  title?: string | null,
  createdAt?: string | null,
  flag?: string | null,
  goals?: string | null,
  focusAreas?: string | null,
  goalsFocusAreas?: string | null,
  progressChallenges?: string | null,
  progress?: string | null,
  challenges?: string | null,
  isOnApp?: boolean | null,
  engagement?: string | null,
  engagementStatus?: string | null,
  clinical?: string | null,
  symptoms?: string | null,
  anthropometry?: string | null,
  dietHistory?: string | null,
  training?: string | null,
  results?: string | null,
  social?: string | null,
  energyRequirements?: string | null,
  supplementsMedication?: string | null,
  other?: string | null,
  futureMe?: string | null,
  actionNeeded?: string | null,
  comments?: string | null,
};

export type DeleteClientNoteInput = {
  id: string,
};

export type CreateConversationInput = {
  id?: string | null,
  specialistClientId?: string | null,
  clientId?: string | null,
  specialistId?: string | null,
  title?: string | null,
  status?: Status | null,
  servicingNote?: string | null,
  notes?: Array<NoteInput | null> | null,
  files?: Array<FileInput | null> | null,
  settings?: string | null,
  members?: Array<string | null> | null,
};

export type ModelConversationConditionInput = {
  specialistClientId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  servicingNote?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  and?: Array<ModelConversationConditionInput | null> | null,
  or?: Array<ModelConversationConditionInput | null> | null,
  not?: ModelConversationConditionInput | null,
};

export type UpdateConversationInput = {
  id: string,
  specialistClientId?: string | null,
  clientId?: string | null,
  specialistId?: string | null,
  title?: string | null,
  status?: Status | null,
  servicingNote?: string | null,
  notes?: Array<NoteInput | null> | null,
  files?: Array<FileInput | null> | null,
  settings?: string | null,
  members?: Array<string | null> | null,
};

export type DeleteConversationInput = {
  id: string,
};

export type CreateMessageInput = {
  id?: string | null,
  content?: string | null,
  createdAt?: string | null,
  messageAuthorId?: string | null,
  readBy?: Array<string | null> | null,
  messageConversationId: string,
};

export type ModelMessageConditionInput = {
  content?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  messageAuthorId?: ModelIDInput | null,
  readBy?: ModelStringInput | null,
  messageConversationId?: ModelStringInput | null,
  and?: Array<ModelMessageConditionInput | null> | null,
  or?: Array<ModelMessageConditionInput | null> | null,
  not?: ModelMessageConditionInput | null,
};

export type UpdateMessageInput = {
  id: string,
  content?: string | null,
  createdAt?: string | null,
  messageAuthorId?: string | null,
  readBy?: Array<string | null> | null,
  messageConversationId?: string | null,
};

export type DeleteMessageInput = {
  id: string,
};

export type CreateCouponInput = {
  id?: string | null,
  coupon?: string | null,
  name?: string | null,
  specialistIds?: Array<string | null> | null,
  hasDiscount?: boolean | null,
  teamId?: string | null,
  percent_off?: string | null,
  max_redemptions?: number | null,
};

export type ModelCouponConditionInput = {
  coupon?: ModelStringInput | null,
  name?: ModelStringInput | null,
  specialistIds?: ModelStringInput | null,
  hasDiscount?: ModelBooleanInput | null,
  teamId?: ModelStringInput | null,
  percent_off?: ModelStringInput | null,
  max_redemptions?: ModelIntInput | null,
  and?: Array<ModelCouponConditionInput | null> | null,
  or?: Array<ModelCouponConditionInput | null> | null,
  not?: ModelCouponConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array<number | null> | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Coupon = {
  __typename: "Coupon",
  id: string,
  coupon?: string | null,
  name?: string | null,
  specialistIds?: Array<string | null> | null,
  hasDiscount?: boolean | null,
  teamId?: string | null,
  percent_off?: string | null,
  max_redemptions?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCouponInput = {
  id: string,
  coupon?: string | null,
  name?: string | null,
  specialistIds?: Array<string | null> | null,
  hasDiscount?: boolean | null,
  teamId?: string | null,
  percent_off?: string | null,
  max_redemptions?: number | null,
};

export type DeleteCouponInput = {
  id: string,
};

export type CreateDeviceInput = {
  id?: string | null,
  token: string,
  platform: string,
  bundleId: string,
  deviceArn?: string | null,
  deviceUserId?: string | null,
};

export type ModelDeviceConditionInput = {
  token?: ModelStringInput | null,
  platform?: ModelStringInput | null,
  bundleId?: ModelStringInput | null,
  deviceArn?: ModelStringInput | null,
  and?: Array<ModelDeviceConditionInput | null> | null,
  or?: Array<ModelDeviceConditionInput | null> | null,
  not?: ModelDeviceConditionInput | null,
};

export type DeleteDeviceInput = {
  id: string,
};

export type CreateHabitsInput = {
  id?: string | null,
  identityGoal?: string | null,
  weeklyReflectionDay?: Day | null,
  log?: Array<HabitLogEntryInput | null> | null,
  behaviours?: Array<BehaviourInput | null> | null,
  actions?: Array<ActionTaskInput | null> | null,
  status?: Status | null,
  userId?: string | null,
  editors?: Array<string | null> | null,
  viewers?: Array<string | null> | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type HabitLogEntryInput = {
  content: string,
  type: string,
  createdAt: string,
};

export type BehaviourInput = {
  id?: string | null,
  behaviour?: string | null,
  status?: string | null,
  colour?: string | null,
};

export type ActionTaskInput = {
  id?: string | null,
  description?: string | null,
  status?: string | null,
  behaviour?: string | null,
  behaviourId?: string | null,
  notifyTimestamp?: string | null,
};

export type ModelHabitsConditionInput = {
  identityGoal?: ModelStringInput | null,
  weeklyReflectionDay?: ModelDayInput | null,
  status?: ModelStatusInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelHabitsConditionInput | null> | null,
  or?: Array<ModelHabitsConditionInput | null> | null,
  not?: ModelHabitsConditionInput | null,
};

export type ModelDayInput = {
  eq?: Day | null,
  ne?: Day | null,
};

export type Habits = {
  __typename: "Habits",
  id: string,
  identityGoal?: string | null,
  weeklyReflectionDay?: Day | null,
  log?: Array<HabitLogEntry | null> | null,
  behaviours?: Array<Behaviour | null> | null,
  actions?: Array<ActionTask | null> | null,
  status?: Status | null,
  userId?: string | null,
  editors?: Array<string | null> | null,
  viewers?: Array<string | null> | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type HabitLogEntry = {
  __typename: "HabitLogEntry",
  content: string,
  type: string,
  createdAt: string,
};

export type Behaviour = {
  __typename: "Behaviour",
  id?: string | null,
  behaviour?: string | null,
  status?: string | null,
  colour?: string | null,
};

export type ActionTask = {
  __typename: "ActionTask",
  id?: string | null,
  description?: string | null,
  status?: string | null,
  behaviour?: string | null,
  behaviourId?: string | null,
  notifyTimestamp?: string | null,
};

export type UpdateHabitsInput = {
  id: string,
  identityGoal?: string | null,
  weeklyReflectionDay?: Day | null,
  log?: Array<HabitLogEntryInput | null> | null,
  behaviours?: Array<BehaviourInput | null> | null,
  actions?: Array<ActionTaskInput | null> | null,
  status?: Status | null,
  userId?: string | null,
  editors?: Array<string | null> | null,
  viewers?: Array<string | null> | null,
  updatedAt?: string | null,
  createdAt?: string | null,
};

export type DeleteHabitsInput = {
  id: string,
};

export type CreateInvitationInput = {
  id?: string | null,
  clientId: string,
  invitedUserId?: string | null,
  email: string,
  contactType?: ContactType | null,
  firstName?: string | null,
  lastName?: string | null,
  status?: string | null,
};

export type ModelInvitationConditionInput = {
  clientId?: ModelIDInput | null,
  invitedUserId?: ModelStringInput | null,
  contactType?: ModelContactTypeInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array<ModelInvitationConditionInput | null> | null,
  or?: Array<ModelInvitationConditionInput | null> | null,
  not?: ModelInvitationConditionInput | null,
};

export type Invitation = {
  __typename: "Invitation",
  id: string,
  clientId: string,
  invitedUserId?: string | null,
  email: string,
  contactType?: ContactType | null,
  firstName?: string | null,
  lastName?: string | null,
  status?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateInvitationInput = {
  id: string,
  clientId?: string | null,
  invitedUserId?: string | null,
  email?: string | null,
  contactType?: ContactType | null,
  firstName?: string | null,
  lastName?: string | null,
  status?: string | null,
};

export type DeleteInvitationInput = {
  id: string,
};

export type CreateMealInput = {
  id?: string | null,
  author: string,
  source?: SourceInput | null,
  title: string,
  image?: string | null,
  description?: string | null,
  mealTypes?: Array<string | null> | null,
  dietary?: Array<string | null> | null,
  allergens?: Array<string | null> | null,
  cuisines?: Array<string | null> | null,
  tags?: Array<string | null> | null,
  cookingMethods?: Array<string | null> | null,
  cookingSkill?: number | null,
  serves?: number | null,
  instructions?: Array<InstructionInput | null> | null,
  times?: TimesInput | null,
  nutrition?: NutritionBreakdownInput | null,
  notes?: string | null,
  status?: Status | null,
  showInRecipe?: boolean | null,
  updatedAt?: string | null,
};

export type SourceInput = {
  name?: string | null,
  avatar?: string | null,
  url?: string | null,
};

export type InstructionInput = {
  step: number,
  direction: string,
};

export type TimesInput = {
  total?: number | null,
  prep?: number | null,
  cook?: number | null,
};

export type NutritionBreakdownInput = {
  kilojoules?: number | null,
  carbohydrates?: number | null,
  fat?: number | null,
  protein?: number | null,
  fibre?: number | null,
  iron?: number | null,
  calcium?: number | null,
};

export type ModelMealConditionInput = {
  author?: ModelStringInput | null,
  title?: ModelStringInput | null,
  image?: ModelStringInput | null,
  description?: ModelStringInput | null,
  mealTypes?: ModelStringInput | null,
  dietary?: ModelStringInput | null,
  allergens?: ModelStringInput | null,
  cuisines?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  cookingMethods?: ModelStringInput | null,
  cookingSkill?: ModelIntInput | null,
  serves?: ModelIntInput | null,
  notes?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  showInRecipe?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array<ModelMealConditionInput | null> | null,
  or?: Array<ModelMealConditionInput | null> | null,
  not?: ModelMealConditionInput | null,
};

export type Meal = {
  __typename: "Meal",
  id: string,
  author: string,
  source?: Source | null,
  title: string,
  image?: string | null,
  description?: string | null,
  mealTypes?: Array<string | null> | null,
  dietary?: Array<string | null> | null,
  allergens?: Array<string | null> | null,
  cuisines?: Array<string | null> | null,
  tags?: Array<string | null> | null,
  cookingMethods?: Array<string | null> | null,
  cookingSkill?: number | null,
  ingredients?: ModelIngredientConnection | null,
  serves?: number | null,
  instructions?: Array<Instruction | null> | null,
  times?: Times | null,
  nutrition?: NutritionBreakdown | null,
  notes?: string | null,
  status?: Status | null,
  showInRecipe?: boolean | null,
  updatedAt?: string | null,
  createdAt: string,
};

export type Source = {
  __typename: "Source",
  name?: string | null,
  avatar?: string | null,
  url?: string | null,
};

export type ModelIngredientConnection = {
  __typename: "ModelIngredientConnection",
  items: Array<Ingredient | null>,
  nextToken?: string | null,
};

export type Ingredient = {
  __typename: "Ingredient",
  id: string,
  mealId: string,
  foodId?: string | null,
  food?: Food | null,
  item?: string | null,
  qty?: number | null,
  unit?: string | null,
  isOptional?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Food = {
  __typename: "Food",
  id: string,
  item?: string | null,
  measurementUnits?: Array<string | null> | null,
  unit?: string | null,
  aisle?: string | null,
  allergens?: Array<string | null> | null,
  density?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type Instruction = {
  __typename: "Instruction",
  step: number,
  direction: string,
};

export type Times = {
  __typename: "Times",
  total?: number | null,
  prep?: number | null,
  cook?: number | null,
};

export type NutritionBreakdown = {
  __typename: "NutritionBreakdown",
  kilojoules?: number | null,
  carbohydrates?: number | null,
  fat?: number | null,
  protein?: number | null,
  fibre?: number | null,
  iron?: number | null,
  calcium?: number | null,
};

export type UpdateMealInput = {
  id: string,
  author?: string | null,
  source?: SourceInput | null,
  title?: string | null,
  image?: string | null,
  description?: string | null,
  mealTypes?: Array<string | null> | null,
  dietary?: Array<string | null> | null,
  allergens?: Array<string | null> | null,
  cuisines?: Array<string | null> | null,
  tags?: Array<string | null> | null,
  cookingMethods?: Array<string | null> | null,
  cookingSkill?: number | null,
  serves?: number | null,
  instructions?: Array<InstructionInput | null> | null,
  times?: TimesInput | null,
  nutrition?: NutritionBreakdownInput | null,
  notes?: string | null,
  status?: Status | null,
  showInRecipe?: boolean | null,
  updatedAt?: string | null,
};

export type DeleteMealInput = {
  id: string,
};

export type CreateIngredientInput = {
  id?: string | null,
  mealId: string,
  foodId?: string | null,
  item?: string | null,
  qty?: number | null,
  unit?: string | null,
  isOptional?: boolean | null,
};

export type ModelIngredientConditionInput = {
  mealId?: ModelIDInput | null,
  foodId?: ModelIDInput | null,
  item?: ModelStringInput | null,
  qty?: ModelFloatInput | null,
  unit?: ModelStringInput | null,
  isOptional?: ModelBooleanInput | null,
  and?: Array<ModelIngredientConditionInput | null> | null,
  or?: Array<ModelIngredientConditionInput | null> | null,
  not?: ModelIngredientConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array<number | null> | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateIngredientInput = {
  id: string,
  mealId?: string | null,
  foodId?: string | null,
  item?: string | null,
  qty?: number | null,
  unit?: string | null,
  isOptional?: boolean | null,
};

export type DeleteIngredientInput = {
  id: string,
};

export type CreateFoodInput = {
  id?: string | null,
  item?: string | null,
  measurementUnits?: Array<string | null> | null,
  unit?: string | null,
  aisle?: string | null,
  allergens?: Array<string | null> | null,
  density?: number | null,
};

export type ModelFoodConditionInput = {
  item?: ModelStringInput | null,
  measurementUnits?: ModelStringInput | null,
  unit?: ModelStringInput | null,
  aisle?: ModelStringInput | null,
  allergens?: ModelStringInput | null,
  density?: ModelFloatInput | null,
  and?: Array<ModelFoodConditionInput | null> | null,
  or?: Array<ModelFoodConditionInput | null> | null,
  not?: ModelFoodConditionInput | null,
};

export type UpdateFoodInput = {
  id: string,
  item?: string | null,
  measurementUnits?: Array<string | null> | null,
  unit?: string | null,
  aisle?: string | null,
  allergens?: Array<string | null> | null,
  density?: number | null,
};

export type DeleteFoodInput = {
  id: string,
};

export type CreateMealPlanSetInput = {
  id?: string | null,
  type?: string | null,
  startingAt?: string | null,
  status?: Status | null,
  userId?: string | null,
  editors?: Array<string | null> | null,
};

export type ModelMealPlanSetConditionInput = {
  type?: ModelStringInput | null,
  startingAt?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  userId?: ModelStringInput | null,
  and?: Array<ModelMealPlanSetConditionInput | null> | null,
  or?: Array<ModelMealPlanSetConditionInput | null> | null,
  not?: ModelMealPlanSetConditionInput | null,
};

export type MealPlanSet = {
  __typename: "MealPlanSet",
  id: string,
  type?: string | null,
  plans?: ModelMealPlanConnection | null,
  startingAt?: string | null,
  status?: Status | null,
  userId?: string | null,
  editors?: Array<string | null> | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelMealPlanConnection = {
  __typename: "ModelMealPlanConnection",
  items: Array<MealPlan | null>,
  nextToken?: string | null,
};

export type MealPlan = {
  __typename: "MealPlan",
  id: string,
  type?: string | null,
  kilojoules?: number | null,
  mealGroups?: Array<MealGroup | null> | null,
  meals?: ModelMealPlanMealConnection | null,
  startingAt?: string | null,
  status?: Status | null,
  userId?: string | null,
  mealPlanSetId?: string | null,
  editors?: Array<string | null> | null,
  viewers?: Array<string | null> | null,
  createdAt: string,
  updatedAt: string,
};

export type MealGroup = {
  __typename: "MealGroup",
  type?: string | null,
  count?: number | null,
};

export type ModelMealPlanMealConnection = {
  __typename: "ModelMealPlanMealConnection",
  items: Array<MealPlanMeal | null>,
  nextToken?: string | null,
};

export type MealPlanMeal = {
  __typename: "MealPlanMeal",
  id: string,
  mealPlanId?: string | null,
  mealId?: string | null,
  meal: Meal,
  type?: string | null,
  note?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMealPlanSetInput = {
  id: string,
  type?: string | null,
  startingAt?: string | null,
  status?: Status | null,
  userId?: string | null,
  editors?: Array<string | null> | null,
};

export type DeleteMealPlanSetInput = {
  id: string,
};

export type CreateMealPlanInput = {
  id?: string | null,
  type?: string | null,
  kilojoules?: number | null,
  mealGroups?: Array<MealGroupInput | null> | null,
  startingAt?: string | null,
  status?: Status | null,
  userId?: string | null,
  mealPlanSetId?: string | null,
  editors?: Array<string | null> | null,
  viewers?: Array<string | null> | null,
};

export type MealGroupInput = {
  type?: string | null,
  count?: number | null,
};

export type ModelMealPlanConditionInput = {
  type?: ModelStringInput | null,
  kilojoules?: ModelIntInput | null,
  startingAt?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  mealPlanSetId?: ModelIDInput | null,
  and?: Array<ModelMealPlanConditionInput | null> | null,
  or?: Array<ModelMealPlanConditionInput | null> | null,
  not?: ModelMealPlanConditionInput | null,
};

export type UpdateMealPlanInput = {
  id: string,
  type?: string | null,
  kilojoules?: number | null,
  mealGroups?: Array<MealGroupInput | null> | null,
  startingAt?: string | null,
  status?: Status | null,
  userId?: string | null,
  mealPlanSetId?: string | null,
  editors?: Array<string | null> | null,
  viewers?: Array<string | null> | null,
};

export type DeleteMealPlanInput = {
  id: string,
};

export type CreateMealPlanMealInput = {
  id?: string | null,
  mealPlanId?: string | null,
  mealId?: string | null,
  type?: string | null,
  note?: string | null,
};

export type ModelMealPlanMealConditionInput = {
  mealPlanId?: ModelIDInput | null,
  mealId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  note?: ModelStringInput | null,
  and?: Array<ModelMealPlanMealConditionInput | null> | null,
  or?: Array<ModelMealPlanMealConditionInput | null> | null,
  not?: ModelMealPlanMealConditionInput | null,
};

export type UpdateMealPlanMealInput = {
  id: string,
  mealPlanId?: string | null,
  mealId?: string | null,
  type?: string | null,
  note?: string | null,
};

export type DeleteMealPlanMealInput = {
  id: string,
};

export type UpdateSpecialistProfileInput = {
  id: string,
  displayName?: string | null,
  businessName?: string | null,
  qualifications?: string | null,
  clinicalSpecialities?: Array<string | null> | null,
  sportSpecialities?: Array<string | null> | null,
  bio?: string | null,
  trivia?: Array<string | null> | null,
  providerNumber?: string | null,
  businessIdNumber?: string | null,
  gender?: string | null,
  dateOfBirth?: string | null,
  image?: string | null,
  video?: string | null,
  status?: string | null,
  isAvailable?: boolean | null,
  clientLimit?: number | null,
  isTeamsAvailable?: boolean | null,
  socialAccounts?: Array<SocialAccountInput | null> | null,
  referralLink?: string | null,
  integrations?: Array<IntegrationInput | null> | null,
  ownerId?: string | null,
};

export type SocialAccountInput = {
  network?: string | null,
  account?: string | null,
};

export type IntegrationInput = {
  name?: string | null,
  attributes?: string | null,
};

export type ModelSpecialistProfileConditionInput = {
  displayName?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  qualifications?: ModelStringInput | null,
  clinicalSpecialities?: ModelStringInput | null,
  sportSpecialities?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  trivia?: ModelStringInput | null,
  providerNumber?: ModelStringInput | null,
  businessIdNumber?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  image?: ModelStringInput | null,
  video?: ModelStringInput | null,
  status?: ModelStringInput | null,
  isAvailable?: ModelBooleanInput | null,
  clientLimit?: ModelIntInput | null,
  isTeamsAvailable?: ModelBooleanInput | null,
  referralLink?: ModelStringInput | null,
  and?: Array<ModelSpecialistProfileConditionInput | null> | null,
  or?: Array<ModelSpecialistProfileConditionInput | null> | null,
  not?: ModelSpecialistProfileConditionInput | null,
};

export type DeleteSpecialistProfileInput = {
  id: string,
};

export type CreateTeamInput = {
  id?: string | null,
  name?: string | null,
  logo?: string | null,
  coupons?: Array<string | null> | null,
  users?: Array<string | null> | null,
  specialists?: Array<string | null> | null,
};

export type ModelTeamConditionInput = {
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  coupons?: ModelStringInput | null,
  users?: ModelStringInput | null,
  and?: Array<ModelTeamConditionInput | null> | null,
  or?: Array<ModelTeamConditionInput | null> | null,
  not?: ModelTeamConditionInput | null,
};

export type Team = {
  __typename: "Team",
  id: string,
  name?: string | null,
  logo?: string | null,
  coupons?: Array<string | null> | null,
  users?: Array<string | null> | null,
  specialists?: Array<string | null> | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTeamInput = {
  id: string,
  name?: string | null,
  logo?: string | null,
  coupons?: Array<string | null> | null,
  users?: Array<string | null> | null,
  specialists?: Array<string | null> | null,
};

export type DeleteTeamInput = {
  id: string,
};

export type DeleteUserInput = {
  id: string,
};

export type ModelUserConditionInput = {
  authId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  bodyType?: ModelStringInput | null,
  height?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  bodyFat?: ModelFloatInput | null,
  activityFactor?: ModelFloatInput | null,
  nutritionNotes?: ModelStringInput | null,
  joinReason?: ModelStringInput | null,
  trainingCalendarLink?: ModelStringInput | null,
  guessedLocation?: ModelStringInput | null,
  menuScheduleDay?: ModelDayInput | null,
  stripeCustomerId?: ModelStringInput | null,
  userRole?: ModelRoleInput | null,
  lastActiveAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  contactInfoId?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array<ModelUserConditionInput | null> | null,
  or?: Array<ModelUserConditionInput | null> | null,
  not?: ModelUserConditionInput | null,
};

export type ModelRoleInput = {
  eq?: Role | null,
  ne?: Role | null,
};

export type CreateContactInfoInput = {
  id?: string | null,
  userId?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  avatar?: string | null,
  phone?: string | null,
  permissionToShare?: boolean | null,
};

export type ModelContactInfoConditionInput = {
  userId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  permissionToShare?: ModelBooleanInput | null,
  and?: Array<ModelContactInfoConditionInput | null> | null,
  or?: Array<ModelContactInfoConditionInput | null> | null,
  not?: ModelContactInfoConditionInput | null,
};

export type UpdateContactInfoInput = {
  id: string,
  userId?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  avatar?: string | null,
  phone?: string | null,
  permissionToShare?: boolean | null,
};

export type DeleteContactInfoInput = {
  id: string,
};

export type DeleteUserTokenInput = {
  id: string,
};

export type ModelUserTokenConditionInput = {
  createdAt?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  token?: ModelStringInput | null,
  isUsed?: ModelBooleanInput | null,
  and?: Array<ModelUserTokenConditionInput | null> | null,
  or?: Array<ModelUserTokenConditionInput | null> | null,
  not?: ModelUserTokenConditionInput | null,
};

export type UserToken = {
  __typename: "UserToken",
  id: string,
  createdAt: string,
  userId: string,
  token: string,
  isUsed: boolean,
  updatedAt: string,
};

export type CreateAlertInput = {
  id?: string | null,
  content?: string | null,
  action?: Action | null,
  isRead?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  alertUserId: string,
};

export type CreateSpecialistProfileInput = {
  id?: string | null,
  displayName?: string | null,
  businessName?: string | null,
  qualifications?: string | null,
  clinicalSpecialities?: Array<string | null> | null,
  sportSpecialities?: Array<string | null> | null,
  bio?: string | null,
  trivia?: Array<string | null> | null,
  providerNumber?: string | null,
  businessIdNumber?: string | null,
  gender?: string | null,
  dateOfBirth?: string | null,
  image?: string | null,
  video?: string | null,
  status?: string | null,
  isAvailable?: boolean | null,
  clientLimit?: number | null,
  isTeamsAvailable?: boolean | null,
  socialAccounts?: Array<SocialAccountInput | null> | null,
  referralLink?: string | null,
  integrations?: Array<IntegrationInput | null> | null,
  ownerId: string,
};

export type CreateUserInput = {
  id?: string | null,
  authId?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  profile?: ProfileInput | null,
  avatar?: string | null,
  phone?: string | null,
  gender?: string | null,
  dateOfBirth?: string | null,
  bodyType?: string | null,
  height?: string | null,
  weight?: number | null,
  bodyFat?: number | null,
  activityFactor?: number | null,
  nutritionNotes?: string | null,
  joinReason?: string | null,
  trainingCalendarLink?: string | null,
  guessedLocation?: string | null,
  journal?: Array<RecordInput | null> | null,
  events?: Array<EventInput | null> | null,
  sports?: Array<SportInput | null> | null,
  goals?: Array<GoalInput | null> | null,
  teams?: Array<TeamGroupInput | null> | null,
  foodDiary?: Array<DailyLogInput | null> | null,
  menuScheduleDay?: Day | null,
  nutritionInformation?: NutritionInput | null,
  nutritionAssessments?: Array<NutritionAssessmentInput | null> | null,
  lifestyle?: LifestyleInput | null,
  healthInformation?: HealthInformationInput | null,
  membership?: MembershipInput | null,
  stripeCustomerId?: string | null,
  permission?: PermissionInput | null,
  priority?: PriorityInput | null,
  priorityConfidence?: Array<PriorityConfidenceInput | null> | null,
  userRole?: Role | null,
  lastActiveAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  paymentMethod?: PaymentMethodInput | null,
  status?: Status | null,
  editors?: Array<string | null> | null,
  viewers?: Array<string | null> | null,
  contactInfoId?: string | null,
  deletedAt?: string | null,
};

export type ProfileInput = {
  sex?: string | null,
  pronouns?: string | null,
  ageBracket?: string | null,
};

export type RecordInput = {
  weight?: string | null,
  waist?: string | null,
  appetiteRating?: number | null,
  energyRating?: number | null,
  sleepRating?: number | null,
  motivationRating?: number | null,
  wellnessRating?: number | null,
  stressRating?: number | null,
  nutritionRating?: number | null,
  createdAt: string,
};

export type EventInput = {
  name: string,
  details?: string | null,
  date?: string | null,
  location?: string | null,
  goal?: string | null,
  preEventFoodRituals?: string | null,
  nutritionProducts?: string | null,
  nutritionLogistics?: string | null,
  issues?: string | null,
  leadupEvents?: string | null,
  accomodation?: string | null,
  leadupEventDate?: string | null,
  leadupEventDetails?: string | null,
  createdAt: string,
};

export type SportInput = {
  name?: string | null,
  level?: string | null,
  comments?: string | null,
};

export type GoalInput = {
  description: string,
  comments?: string | null,
  progress?: Array<ProgressInput | null> | null,
  targetDate?: string | null,
  createdAt: string,
};

export type ProgressInput = {
  rating: number,
  createdAt: string,
};

export type TeamGroupInput = {
  name?: string | null,
  joinedAt?: string | null,
};

export type DailyLogInput = {
  date?: string | null,
  day?: string | null,
  items?: Array<LogItemInput | null> | null,
};

export type LogItemInput = {
  time?: string | null,
  detail?: string | null,
  comments?: string | null,
};

export type NutritionInput = {
  allergies?: Array<string | null> | null,
  supplements?: string | null,
  dislikes?: string | null,
  comments?: string | null,
  preferences?: string | null,
  goals?: Array<GoalInput | null> | null,
  dietaryRequirements?: Array<string | null> | null,
};

export type NutritionAssessmentInput = {
  requestDate: string,
  responseStartDate?: string | null,
  responseCompleteDate?: string | null,
  responses?: Array<ResponseInput | null> | null,
  score?: number | null,
};

export type ResponseInput = {
  question?: string | null,
  answer?: string | null,
  comments?: string | null,
  score?: number | null,
};

export type LifestyleInput = {
  cookingSkills?: string | null,
  cookingMethods?: Array<string | null> | null,
  easyNights?: Array<Day | null> | null,
  homeSituation?: string | null,
  peopleToFeed?: string | null,
  workDays?: Array<Day | null> | null,
  nightsEatingOut?: Array<Day | null> | null,
  workShifts?: string | null,
  breakfastOnTheRun?: Array<Day | null> | null,
  occupation?: string | null,
  preferences?: string | null,
};

export type HealthInformationInput = {
  medicalConditions?: string | null,
  medication?: string | null,
  isMenstruating?: boolean | null,
  regularPeriod?: boolean | null,
  periodStoppedOver3Months?: string | null,
};

export type MembershipInput = {
  id?: string | null,
  amount?: string | null,
  plan?: string | null,
  discount?: string | null,
  createdAt?: string | null,
  expiresAt?: string | null,
  cancelAtPeriodEnd?: boolean | null,
};

export type PermissionInput = {
  hasMeals?: boolean | null,
  hasHabits?: boolean | null,
};

export type PriorityInput = {
  bonking?: number | null,
  energyLevels?: number | null,
  gutUpset?: number | null,
  manageCramping?: number | null,
  muscleMass?: number | null,
  overallHealth?: number | null,
  performance?: number | null,
  raceDayNutrition?: number | null,
  trainingAdaptations?: number | null,
  weightLoss?: number | null,
  createdAt: string,
};

export type PriorityConfidenceInput = {
  bonking?: number | null,
  energyLevels?: number | null,
  gutUpset?: number | null,
  manageCramping?: number | null,
  muscleMass?: number | null,
  overallHealth?: number | null,
  performance?: number | null,
  raceDayNutrition?: number | null,
  trainingAdaptations?: number | null,
  weightLoss?: number | null,
  createdAt: string,
};

export type PaymentMethodInput = {
  id?: string | null,
  type?: string | null,
  brand?: string | null,
  exp_month?: number | null,
  exp_year?: number | null,
  last4?: string | null,
};

export type UpdateUserInput = {
  id: string,
  authId?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  profile?: ProfileInput | null,
  avatar?: string | null,
  phone?: string | null,
  gender?: string | null,
  dateOfBirth?: string | null,
  bodyType?: string | null,
  height?: string | null,
  weight?: number | null,
  bodyFat?: number | null,
  activityFactor?: number | null,
  nutritionNotes?: string | null,
  joinReason?: string | null,
  trainingCalendarLink?: string | null,
  guessedLocation?: string | null,
  journal?: Array<RecordInput | null> | null,
  events?: Array<EventInput | null> | null,
  sports?: Array<SportInput | null> | null,
  goals?: Array<GoalInput | null> | null,
  teams?: Array<TeamGroupInput | null> | null,
  foodDiary?: Array<DailyLogInput | null> | null,
  menuScheduleDay?: Day | null,
  nutritionInformation?: NutritionInput | null,
  nutritionAssessments?: Array<NutritionAssessmentInput | null> | null,
  lifestyle?: LifestyleInput | null,
  healthInformation?: HealthInformationInput | null,
  membership?: MembershipInput | null,
  stripeCustomerId?: string | null,
  permission?: PermissionInput | null,
  priority?: PriorityInput | null,
  priorityConfidence?: Array<PriorityConfidenceInput | null> | null,
  userRole?: Role | null,
  lastActiveAt?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  paymentMethod?: PaymentMethodInput | null,
  status?: Status | null,
  editors?: Array<string | null> | null,
  viewers?: Array<string | null> | null,
  contactInfoId?: string | null,
  deletedAt?: string | null,
};

export type CreateUserTokenInput = {
  id?: string | null,
  createdAt?: string | null,
  userId: string,
  token: string,
  isUsed: boolean,
};

export type UpdateUserTokenInput = {
  id: string,
  createdAt?: string | null,
  userId?: string | null,
  token?: string | null,
  isUsed?: boolean | null,
};

export type MealListResponse = {
  __typename: "MealListResponse",
  items?: Array<Meal | null> | null,
  nextToken?: string | null,
};

export type ModelAlertFilterInput = {
  id?: ModelIDInput | null,
  content?: ModelStringInput | null,
  action?: ModelActionInput | null,
  isRead?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alertUserId?: ModelIDInput | null,
  and?: Array<ModelAlertFilterInput | null> | null,
  or?: Array<ModelAlertFilterInput | null> | null,
  not?: ModelAlertFilterInput | null,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  specialistId?: ModelIDInput | null,
  status?: ModelStatusInput | null,
  servicingNote?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  and?: Array<ModelClientFilterInput | null> | null,
  or?: Array<ModelClientFilterInput | null> | null,
  not?: ModelClientFilterInput | null,
};

export type ModelConversationFilterInput = {
  id?: ModelIDInput | null,
  specialistClientId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  specialistId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  servicingNote?: ModelStringInput | null,
  settings?: ModelStringInput | null,
  members?: ModelStringInput | null,
  and?: Array<ModelConversationFilterInput | null> | null,
  or?: Array<ModelConversationFilterInput | null> | null,
  not?: ModelConversationFilterInput | null,
};

export type ModelCouponFilterInput = {
  id?: ModelIDInput | null,
  coupon?: ModelStringInput | null,
  name?: ModelStringInput | null,
  specialistIds?: ModelStringInput | null,
  hasDiscount?: ModelBooleanInput | null,
  teamId?: ModelStringInput | null,
  percent_off?: ModelStringInput | null,
  max_redemptions?: ModelIntInput | null,
  and?: Array<ModelCouponFilterInput | null> | null,
  or?: Array<ModelCouponFilterInput | null> | null,
  not?: ModelCouponFilterInput | null,
};

export type ModelCouponConnection = {
  __typename: "ModelCouponConnection",
  items: Array<Coupon | null>,
  nextToken?: string | null,
};

export type ModelHabitsFilterInput = {
  id?: ModelIDInput | null,
  identityGoal?: ModelStringInput | null,
  weeklyReflectionDay?: ModelDayInput | null,
  status?: ModelStatusInput | null,
  userId?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array<ModelHabitsFilterInput | null> | null,
  or?: Array<ModelHabitsFilterInput | null> | null,
  not?: ModelHabitsFilterInput | null,
};

export type ModelHabitsConnection = {
  __typename: "ModelHabitsConnection",
  items: Array<Habits | null>,
  nextToken?: string | null,
};

export type ModelInvitationFilterInput = {
  id?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  invitedUserId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  contactType?: ModelContactTypeInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  and?: Array<ModelInvitationFilterInput | null> | null,
  or?: Array<ModelInvitationFilterInput | null> | null,
  not?: ModelInvitationFilterInput | null,
};

export type ModelInvitationConnection = {
  __typename: "ModelInvitationConnection",
  items: Array<Invitation | null>,
  nextToken?: string | null,
};

export type ModelMealFilterInput = {
  id?: ModelIDInput | null,
  author?: ModelStringInput | null,
  title?: ModelStringInput | null,
  image?: ModelStringInput | null,
  description?: ModelStringInput | null,
  mealTypes?: ModelStringInput | null,
  dietary?: ModelStringInput | null,
  allergens?: ModelStringInput | null,
  cuisines?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  cookingMethods?: ModelStringInput | null,
  cookingSkill?: ModelIntInput | null,
  serves?: ModelIntInput | null,
  notes?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  showInRecipe?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array<ModelMealFilterInput | null> | null,
  or?: Array<ModelMealFilterInput | null> | null,
  not?: ModelMealFilterInput | null,
};

export type ModelMealConnection = {
  __typename: "ModelMealConnection",
  items: Array<Meal | null>,
  nextToken?: string | null,
};

export type ModelFoodFilterInput = {
  id?: ModelIDInput | null,
  item?: ModelStringInput | null,
  measurementUnits?: ModelStringInput | null,
  unit?: ModelStringInput | null,
  aisle?: ModelStringInput | null,
  allergens?: ModelStringInput | null,
  density?: ModelFloatInput | null,
  and?: Array<ModelFoodFilterInput | null> | null,
  or?: Array<ModelFoodFilterInput | null> | null,
  not?: ModelFoodFilterInput | null,
};

export type ModelFoodConnection = {
  __typename: "ModelFoodConnection",
  items: Array<Food | null>,
  nextToken?: string | null,
};

export type ModelMealPlanSetFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  startingAt?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  userId?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  and?: Array<ModelMealPlanSetFilterInput | null> | null,
  or?: Array<ModelMealPlanSetFilterInput | null> | null,
  not?: ModelMealPlanSetFilterInput | null,
};

export type ModelMealPlanSetConnection = {
  __typename: "ModelMealPlanSetConnection",
  items: Array<MealPlanSet | null>,
  nextToken?: string | null,
};

export type ModelMealPlanFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  kilojoules?: ModelIntInput | null,
  startingAt?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  userId?: ModelStringInput | null,
  mealPlanSetId?: ModelIDInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  and?: Array<ModelMealPlanFilterInput | null> | null,
  or?: Array<ModelMealPlanFilterInput | null> | null,
  not?: ModelMealPlanFilterInput | null,
};

export type Recipe = {
  __typename: "Recipe",
  id: string,
  title: string,
  image?: string | null,
  recipeLink?: string | null,
  tags?: Array<string | null> | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ModelRecipeFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  image?: ModelStringInput | null,
  recipeLink?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array<ModelRecipeFilterInput | null> | null,
  or?: Array<ModelRecipeFilterInput | null> | null,
  not?: ModelRecipeFilterInput | null,
};

export type ModelRecipeConnection = {
  __typename: "ModelRecipeConnection",
  items: Array<Recipe | null>,
  nextToken?: string | null,
};

export type ModelSpecialistProfileFilterInput = {
  id?: ModelIDInput | null,
  displayName?: ModelStringInput | null,
  businessName?: ModelStringInput | null,
  qualifications?: ModelStringInput | null,
  clinicalSpecialities?: ModelStringInput | null,
  sportSpecialities?: ModelStringInput | null,
  bio?: ModelStringInput | null,
  trivia?: ModelStringInput | null,
  providerNumber?: ModelStringInput | null,
  businessIdNumber?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  image?: ModelStringInput | null,
  video?: ModelStringInput | null,
  status?: ModelStringInput | null,
  isAvailable?: ModelBooleanInput | null,
  clientLimit?: ModelIntInput | null,
  isTeamsAvailable?: ModelBooleanInput | null,
  referralLink?: ModelStringInput | null,
  ownerId?: ModelIDInput | null,
  and?: Array<ModelSpecialistProfileFilterInput | null> | null,
  or?: Array<ModelSpecialistProfileFilterInput | null> | null,
  not?: ModelSpecialistProfileFilterInput | null,
};

export type ModelSpecialistProfileConnection = {
  __typename: "ModelSpecialistProfileConnection",
  items: Array<SpecialistProfile | null>,
  nextToken?: string | null,
};

export type ModelTeamFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  coupons?: ModelStringInput | null,
  users?: ModelStringInput | null,
  specialists?: ModelStringInput | null,
  and?: Array<ModelTeamFilterInput | null> | null,
  or?: Array<ModelTeamFilterInput | null> | null,
  not?: ModelTeamFilterInput | null,
};

export type ModelTeamConnection = {
  __typename: "ModelTeamConnection",
  items: Array<Team | null>,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  authId?: ModelStringInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  dateOfBirth?: ModelStringInput | null,
  bodyType?: ModelStringInput | null,
  height?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  bodyFat?: ModelFloatInput | null,
  activityFactor?: ModelFloatInput | null,
  nutritionNotes?: ModelStringInput | null,
  joinReason?: ModelStringInput | null,
  trainingCalendarLink?: ModelStringInput | null,
  guessedLocation?: ModelStringInput | null,
  menuScheduleDay?: ModelDayInput | null,
  stripeCustomerId?: ModelStringInput | null,
  userRole?: ModelRoleInput | null,
  lastActiveAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  editors?: ModelStringInput | null,
  viewers?: ModelStringInput | null,
  contactInfoId?: ModelIDInput | null,
  deletedAt?: ModelStringInput | null,
  and?: Array<ModelUserFilterInput | null> | null,
  or?: Array<ModelUserFilterInput | null> | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items: Array<User | null>,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAlertByUserByActionCompositeKeyConditionInput = {
  eq?: ModelAlertByUserByActionCompositeKeyInput | null,
  le?: ModelAlertByUserByActionCompositeKeyInput | null,
  lt?: ModelAlertByUserByActionCompositeKeyInput | null,
  ge?: ModelAlertByUserByActionCompositeKeyInput | null,
  gt?: ModelAlertByUserByActionCompositeKeyInput | null,
  between?: Array<ModelAlertByUserByActionCompositeKeyInput | null> | null,
  beginsWith?: ModelAlertByUserByActionCompositeKeyInput | null,
};

export type ModelAlertByUserByActionCompositeKeyInput = {
  action?: Action | null,
  createdAt?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array<string | null> | null,
  beginsWith?: string | null,
};

export type ModelDishFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  type?: ModelStringInput | null,
  image?: ModelStringInput | null,
  recipeLink?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array<ModelDishFilterInput | null> | null,
  or?: Array<ModelDishFilterInput | null> | null,
  not?: ModelDishFilterInput | null,
};

export type ModelDishConnection = {
  __typename: "ModelDishConnection",
  items: Array<Dish | null>,
  nextToken?: string | null,
};

export type Dish = {
  __typename: "Dish",
  id: string,
  title: string,
  type?: string | null,
  ingredients?: Array<Element | null> | null,
  image?: string | null,
  recipeLink?: string | null,
  tags?: Array<string | null> | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type Element = {
  __typename: "Element",
  item?: string | null,
  qty?: string | null,
  unit?: string | null,
  extraDetail?: string | null,
  isOptional?: boolean | null,
};

export type ModelHabitsByUserByStatusCompositeKeyConditionInput = {
  eq?: ModelHabitsByUserByStatusCompositeKeyInput | null,
  le?: ModelHabitsByUserByStatusCompositeKeyInput | null,
  lt?: ModelHabitsByUserByStatusCompositeKeyInput | null,
  ge?: ModelHabitsByUserByStatusCompositeKeyInput | null,
  gt?: ModelHabitsByUserByStatusCompositeKeyInput | null,
  between?: Array<ModelHabitsByUserByStatusCompositeKeyInput | null> | null,
  beginsWith?: ModelHabitsByUserByStatusCompositeKeyInput | null,
};

export type ModelHabitsByUserByStatusCompositeKeyInput = {
  status?: Status | null,
  createdAt?: string | null,
};

export type ModelMealPlanSetByUserCompositeKeyConditionInput = {
  eq?: ModelMealPlanSetByUserCompositeKeyInput | null,
  le?: ModelMealPlanSetByUserCompositeKeyInput | null,
  lt?: ModelMealPlanSetByUserCompositeKeyInput | null,
  ge?: ModelMealPlanSetByUserCompositeKeyInput | null,
  gt?: ModelMealPlanSetByUserCompositeKeyInput | null,
  between?: Array<ModelMealPlanSetByUserCompositeKeyInput | null> | null,
  beginsWith?: ModelMealPlanSetByUserCompositeKeyInput | null,
};

export type ModelMealPlanSetByUserCompositeKeyInput = {
  startingAt?: string | null,
  status?: Status | null,
};

export type ModelMealPlanByUserCompositeKeyConditionInput = {
  eq?: ModelMealPlanByUserCompositeKeyInput | null,
  le?: ModelMealPlanByUserCompositeKeyInput | null,
  lt?: ModelMealPlanByUserCompositeKeyInput | null,
  ge?: ModelMealPlanByUserCompositeKeyInput | null,
  gt?: ModelMealPlanByUserCompositeKeyInput | null,
  between?: Array<ModelMealPlanByUserCompositeKeyInput | null> | null,
  beginsWith?: ModelMealPlanByUserCompositeKeyInput | null,
};

export type ModelMealPlanByUserCompositeKeyInput = {
  startingAt?: string | null,
  status?: Status | null,
};

export type ModelMealPlanByUserByStatusCompositeKeyConditionInput = {
  eq?: ModelMealPlanByUserByStatusCompositeKeyInput | null,
  le?: ModelMealPlanByUserByStatusCompositeKeyInput | null,
  lt?: ModelMealPlanByUserByStatusCompositeKeyInput | null,
  ge?: ModelMealPlanByUserByStatusCompositeKeyInput | null,
  gt?: ModelMealPlanByUserByStatusCompositeKeyInput | null,
  between?: Array<ModelMealPlanByUserByStatusCompositeKeyInput | null> | null,
  beginsWith?: ModelMealPlanByUserByStatusCompositeKeyInput | null,
};

export type ModelMealPlanByUserByStatusCompositeKeyInput = {
  status?: Status | null,
  startingAt?: string | null,
};

export type ModelUserByRoleCompositeKeyConditionInput = {
  eq?: ModelUserByRoleCompositeKeyInput | null,
  le?: ModelUserByRoleCompositeKeyInput | null,
  lt?: ModelUserByRoleCompositeKeyInput | null,
  ge?: ModelUserByRoleCompositeKeyInput | null,
  gt?: ModelUserByRoleCompositeKeyInput | null,
  between?: Array<ModelUserByRoleCompositeKeyInput | null> | null,
  beginsWith?: ModelUserByRoleCompositeKeyInput | null,
};

export type ModelUserByRoleCompositeKeyInput = {
  createdAt?: string | null,
  status?: Status | null,
};

export type ModelUserByNameCompositeKeyConditionInput = {
  eq?: ModelUserByNameCompositeKeyInput | null,
  le?: ModelUserByNameCompositeKeyInput | null,
  lt?: ModelUserByNameCompositeKeyInput | null,
  ge?: ModelUserByNameCompositeKeyInput | null,
  gt?: ModelUserByNameCompositeKeyInput | null,
  between?: Array<ModelUserByNameCompositeKeyInput | null> | null,
  beginsWith?: ModelUserByNameCompositeKeyInput | null,
};

export type ModelUserByNameCompositeKeyInput = {
  lastName?: string | null,
  createdAt?: string | null,
  status?: Status | null,
};

export type ModelContactInfoFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  email?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  avatar?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  permissionToShare?: ModelBooleanInput | null,
  and?: Array<ModelContactInfoFilterInput | null> | null,
  or?: Array<ModelContactInfoFilterInput | null> | null,
  not?: ModelContactInfoFilterInput | null,
};

export type ModelContactInfoConnection = {
  __typename: "ModelContactInfoConnection",
  items: Array<ContactInfo | null>,
  nextToken?: string | null,
};


export type ModelUserTokenFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  userId?: ModelStringInput | null,
  token?: ModelStringInput | null,
  isUsed?: ModelBooleanInput | null,
  and?: Array<ModelUserTokenFilterInput | null> | null,
  or?: Array<ModelUserTokenFilterInput | null> | null,
  not?: ModelUserTokenFilterInput | null,
};

export type ModelUserTokenConnection = {
  __typename: "ModelUserTokenConnection",
  items: Array<UserToken | null>,
  nextToken?: string | null,
};

export type PublishUserMutationVariables = {
  id: string,
};

export type PublishUserMutation = {
  publishUser?: {
    __typename: "User",
    id: string,
    authId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profile?: {
      __typename: "Profile",
      sex?: string | null,
      pronouns?: string | null,
      ageBracket?: string | null,
    } | null,
    avatar?: string | null,
    phone?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    bodyType?: string | null,
    height?: string | null,
    weight?: number | null,
    bodyFat?: number | null,
    activityFactor?: number | null,
    nutritionNotes?: string | null,
    joinReason?: string | null,
    trainingCalendarLink?: string | null,
    guessedLocation?: string | null,
    journal?: Array<{
      __typename: "Record",
      weight?: string | null,
      waist?: string | null,
      appetiteRating?: number | null,
      energyRating?: number | null,
      sleepRating?: number | null,
      motivationRating?: number | null,
      wellnessRating?: number | null,
      stressRating?: number | null,
      nutritionRating?: number | null,
      createdAt: string,
    } | null> | null,
    events?: Array<{
      __typename: "Event",
      name: string,
      details?: string | null,
      date?: string | null,
      location?: string | null,
      goal?: string | null,
      preEventFoodRituals?: string | null,
      nutritionProducts?: string | null,
      nutritionLogistics?: string | null,
      issues?: string | null,
      leadupEvents?: string | null,
      accomodation?: string | null,
      leadupEventDate?: string | null,
      leadupEventDetails?: string | null,
      createdAt: string,
    } | null> | null,
    sports?: Array<{
      __typename: "Sport",
      name?: string | null,
      level?: string | null,
      comments?: string | null,
    } | null> | null,
    goals?: Array<{
      __typename: "Goal",
      description: string,
      comments?: string | null,
      progress?: Array<{
        __typename: "Progress",
        rating: number,
        createdAt: string,
      } | null> | null,
      targetDate?: string | null,
      createdAt: string,
    } | null> | null,
    teams?: Array<{
      __typename: "TeamGroup",
      name?: string | null,
      joinedAt?: string | null,
    } | null> | null,
    foodDiary?: Array<{
      __typename: "DailyLog",
      date?: string | null,
      day?: string | null,
      items?: Array<{
        __typename: "LogItem",
        time?: string | null,
        detail?: string | null,
        comments?: string | null,
      } | null> | null,
    } | null> | null,
    menuScheduleDay?: Day | null,
    nutritionInformation?: {
      __typename: "Nutrition",
      allergies?: Array<string | null> | null,
      supplements?: string | null,
      dislikes?: string | null,
      comments?: string | null,
      preferences?: string | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      dietaryRequirements?: Array<string | null> | null,
    } | null,
    nutritionAssessments?: Array<{
      __typename: "NutritionAssessment",
      requestDate: string,
      responseStartDate?: string | null,
      responseCompleteDate?: string | null,
      responses?: Array<{
        __typename: "Response",
        question?: string | null,
        answer?: string | null,
        comments?: string | null,
        score?: number | null,
      } | null> | null,
      score?: number | null,
    } | null> | null,
    lifestyle?: {
      __typename: "Lifestyle",
      cookingSkills?: string | null,
      cookingMethods?: Array<string | null> | null,
      easyNights?: Array<Day | null> | null,
      homeSituation?: string | null,
      peopleToFeed?: string | null,
      workDays?: Array<Day | null> | null,
      nightsEatingOut?: Array<Day | null> | null,
      workShifts?: string | null,
      breakfastOnTheRun?: Array<Day | null> | null,
      occupation?: string | null,
      preferences?: string | null,
    } | null,
    healthInformation?: {
      __typename: "HealthInformation",
      medicalConditions?: string | null,
      medication?: string | null,
      isMenstruating?: boolean | null,
      regularPeriod?: boolean | null,
      periodStoppedOver3Months?: string | null,
    } | null,
    membership?: {
      __typename: "Membership",
      id?: string | null,
      amount?: string | null,
      plan?: string | null,
      discount?: string | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      cancelAtPeriodEnd?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    permission?: {
      __typename: "Permission",
      hasMeals?: boolean | null,
      hasHabits?: boolean | null,
    } | null,
    priority?: {
      __typename: "Priority",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null,
    priorityConfidence?: Array<{
      __typename: "PriorityConfidence",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null> | null,
    userRole?: Role | null,
    lastActiveAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    paymentMethod?: {
      __typename: "PaymentMethod",
      id?: string | null,
      type?: string | null,
      brand?: string | null,
      exp_month?: number | null,
      exp_year?: number | null,
      last4?: string | null,
    } | null,
    status?: Status | null,
    devices?: {
      __typename: "ModelDeviceConnection",
      items: Array<{
        __typename: "Device",
        id: string,
        token: string,
        platform: string,
        bundleId: string,
        deviceArn?: string | null,
        deviceUserId?: string | null,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    specialists?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    alerts?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    notifications?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    contactInfoId?: string | null,
    contactInfo?: {
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientProfile?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
  } | null,
};

export type PublishSpecialistProfileMutationVariables = {
  id: string,
};

export type PublishSpecialistProfileMutation = {
  publishSpecialistProfile?: {
    __typename: "SpecialistProfile",
    id: string,
    displayName?: string | null,
    businessName?: string | null,
    qualifications?: string | null,
    clinicalSpecialities?: Array<string | null> | null,
    sportSpecialities?: Array<string | null> | null,
    bio?: string | null,
    trivia?: Array<string | null> | null,
    providerNumber?: string | null,
    businessIdNumber?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    image?: string | null,
    video?: string | null,
    status?: string | null,
    isAvailable?: boolean | null,
    clientLimit?: number | null,
    isTeamsAvailable?: boolean | null,
    socialAccounts?: Array<{
      __typename: "SocialAccount",
      network?: string | null,
      account?: string | null,
    } | null> | null,
    referralLink?: string | null,
    integrations?: Array<{
      __typename: "Integration",
      name?: string | null,
      attributes?: string | null,
    } | null> | null,
    ownerId: string,
    owner?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    clients?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    myClients?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAlertMutationVariables = {
  input: UpdateAlertInput,
  condition?: ModelAlertConditionInput | null,
};

export type UpdateAlertMutation = {
  updateAlert?: {
    __typename: "Alert",
    id: string,
    content?: string | null,
    action?: Action | null,
    isRead?: boolean | null,
    createdAt: string,
    updatedAt: string,
    alertUserId: string,
    user?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
  } | null,
};

export type DeleteAlertMutationVariables = {
  input: DeleteAlertInput,
  condition?: ModelAlertConditionInput | null,
};

export type DeleteAlertMutation = {
  deleteAlert?: {
    __typename: "Alert",
    id: string,
    content?: string | null,
    action?: Action | null,
    isRead?: boolean | null,
    createdAt: string,
    updatedAt: string,
    alertUserId: string,
    user?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?: {
    __typename: "Client",
    id: string,
    userId: string,
    specialistId: string,
    user: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    },
    specialist: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    contacts?: {
      __typename: "ModelContactConnection",
      items: Array<{
        __typename: "Contact",
        id: string,
        contactInfoId: string,
        clientId: string,
        type?: ContactType | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        client?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    conversations?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    clientNotes?: {
      __typename: "ModelClientNoteConnection",
      items: Array<{
        __typename: "ClientNote",
        id: string,
        clientId: string,
        title: string,
        createdAt?: string | null,
        flag?: string | null,
        goals?: string | null,
        focusAreas?: string | null,
        goalsFocusAreas?: string | null,
        progressChallenges?: string | null,
        progress?: string | null,
        challenges?: string | null,
        isOnApp?: boolean | null,
        engagement?: string | null,
        engagementStatus?: string | null,
        clinical?: string | null,
        symptoms?: string | null,
        anthropometry?: string | null,
        dietHistory?: string | null,
        training?: string | null,
        results?: string | null,
        social?: string | null,
        energyRequirements?: string | null,
        supplementsMedication?: string | null,
        other?: string | null,
        futureMe?: string | null,
        actionNeeded?: string | null,
        comments?: string | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?: {
    __typename: "Client",
    id: string,
    userId: string,
    specialistId: string,
    user: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    },
    specialist: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    contacts?: {
      __typename: "ModelContactConnection",
      items: Array<{
        __typename: "Contact",
        id: string,
        contactInfoId: string,
        clientId: string,
        type?: ContactType | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        client?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    conversations?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    clientNotes?: {
      __typename: "ModelClientNoteConnection",
      items: Array<{
        __typename: "ClientNote",
        id: string,
        clientId: string,
        title: string,
        createdAt?: string | null,
        flag?: string | null,
        goals?: string | null,
        focusAreas?: string | null,
        goalsFocusAreas?: string | null,
        progressChallenges?: string | null,
        progress?: string | null,
        challenges?: string | null,
        isOnApp?: boolean | null,
        engagement?: string | null,
        engagementStatus?: string | null,
        clinical?: string | null,
        symptoms?: string | null,
        anthropometry?: string | null,
        dietHistory?: string | null,
        training?: string | null,
        results?: string | null,
        social?: string | null,
        energyRequirements?: string | null,
        supplementsMedication?: string | null,
        other?: string | null,
        futureMe?: string | null,
        actionNeeded?: string | null,
        comments?: string | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?: {
    __typename: "Client",
    id: string,
    userId: string,
    specialistId: string,
    user: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    },
    specialist: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    contacts?: {
      __typename: "ModelContactConnection",
      items: Array<{
        __typename: "Contact",
        id: string,
        contactInfoId: string,
        clientId: string,
        type?: ContactType | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        client?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    conversations?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    clientNotes?: {
      __typename: "ModelClientNoteConnection",
      items: Array<{
        __typename: "ClientNote",
        id: string,
        clientId: string,
        title: string,
        createdAt?: string | null,
        flag?: string | null,
        goals?: string | null,
        focusAreas?: string | null,
        goalsFocusAreas?: string | null,
        progressChallenges?: string | null,
        progress?: string | null,
        challenges?: string | null,
        isOnApp?: boolean | null,
        engagement?: string | null,
        engagementStatus?: string | null,
        clinical?: string | null,
        symptoms?: string | null,
        anthropometry?: string | null,
        dietHistory?: string | null,
        training?: string | null,
        results?: string | null,
        social?: string | null,
        energyRequirements?: string | null,
        supplementsMedication?: string | null,
        other?: string | null,
        futureMe?: string | null,
        actionNeeded?: string | null,
        comments?: string | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContactMutationVariables = {
  input: CreateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type CreateContactMutation = {
  createContact?: {
    __typename: "Contact",
    id: string,
    contactInfoId: string,
    clientId: string,
    type?: ContactType | null,
    contactInfo?: {
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    client?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContactMutationVariables = {
  input: UpdateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type UpdateContactMutation = {
  updateContact?: {
    __typename: "Contact",
    id: string,
    contactInfoId: string,
    clientId: string,
    type?: ContactType | null,
    contactInfo?: {
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    client?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContactMutationVariables = {
  input: DeleteContactInput,
  condition?: ModelContactConditionInput | null,
};

export type DeleteContactMutation = {
  deleteContact?: {
    __typename: "Contact",
    id: string,
    contactInfoId: string,
    clientId: string,
    type?: ContactType | null,
    contactInfo?: {
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    client?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClientNoteMutationVariables = {
  input: CreateClientNoteInput,
  condition?: ModelClientNoteConditionInput | null,
};

export type CreateClientNoteMutation = {
  createClientNote?: {
    __typename: "ClientNote",
    id: string,
    clientId: string,
    title: string,
    createdAt?: string | null,
    flag?: string | null,
    goals?: string | null,
    focusAreas?: string | null,
    goalsFocusAreas?: string | null,
    progressChallenges?: string | null,
    progress?: string | null,
    challenges?: string | null,
    isOnApp?: boolean | null,
    engagement?: string | null,
    engagementStatus?: string | null,
    clinical?: string | null,
    symptoms?: string | null,
    anthropometry?: string | null,
    dietHistory?: string | null,
    training?: string | null,
    results?: string | null,
    social?: string | null,
    energyRequirements?: string | null,
    supplementsMedication?: string | null,
    other?: string | null,
    futureMe?: string | null,
    actionNeeded?: string | null,
    comments?: string | null,
    updatedAt: string,
  } | null,
};

export type UpdateClientNoteMutationVariables = {
  input: UpdateClientNoteInput,
  condition?: ModelClientNoteConditionInput | null,
};

export type UpdateClientNoteMutation = {
  updateClientNote?: {
    __typename: "ClientNote",
    id: string,
    clientId: string,
    title: string,
    createdAt?: string | null,
    flag?: string | null,
    goals?: string | null,
    focusAreas?: string | null,
    goalsFocusAreas?: string | null,
    progressChallenges?: string | null,
    progress?: string | null,
    challenges?: string | null,
    isOnApp?: boolean | null,
    engagement?: string | null,
    engagementStatus?: string | null,
    clinical?: string | null,
    symptoms?: string | null,
    anthropometry?: string | null,
    dietHistory?: string | null,
    training?: string | null,
    results?: string | null,
    social?: string | null,
    energyRequirements?: string | null,
    supplementsMedication?: string | null,
    other?: string | null,
    futureMe?: string | null,
    actionNeeded?: string | null,
    comments?: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteClientNoteMutationVariables = {
  input: DeleteClientNoteInput,
  condition?: ModelClientNoteConditionInput | null,
};

export type DeleteClientNoteMutation = {
  deleteClientNote?: {
    __typename: "ClientNote",
    id: string,
    clientId: string,
    title: string,
    createdAt?: string | null,
    flag?: string | null,
    goals?: string | null,
    focusAreas?: string | null,
    goalsFocusAreas?: string | null,
    progressChallenges?: string | null,
    progress?: string | null,
    challenges?: string | null,
    isOnApp?: boolean | null,
    engagement?: string | null,
    engagementStatus?: string | null,
    clinical?: string | null,
    symptoms?: string | null,
    anthropometry?: string | null,
    dietHistory?: string | null,
    training?: string | null,
    results?: string | null,
    social?: string | null,
    energyRequirements?: string | null,
    supplementsMedication?: string | null,
    other?: string | null,
    futureMe?: string | null,
    actionNeeded?: string | null,
    comments?: string | null,
    updatedAt: string,
  } | null,
};

export type CreateConversationMutationVariables = {
  input: CreateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type CreateConversationMutation = {
  createConversation?: {
    __typename: "Conversation",
    id: string,
    specialistClientId?: string | null,
    clientId?: string | null,
    specialistId?: string | null,
    title?: string | null,
    client?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    specialistClient?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    specialist?: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    members?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConversationMutationVariables = {
  input: UpdateConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type UpdateConversationMutation = {
  updateConversation?: {
    __typename: "Conversation",
    id: string,
    specialistClientId?: string | null,
    clientId?: string | null,
    specialistId?: string | null,
    title?: string | null,
    client?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    specialistClient?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    specialist?: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    members?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConversationMutationVariables = {
  input: DeleteConversationInput,
  condition?: ModelConversationConditionInput | null,
};

export type DeleteConversationMutation = {
  deleteConversation?: {
    __typename: "Conversation",
    id: string,
    specialistClientId?: string | null,
    clientId?: string | null,
    specialistId?: string | null,
    title?: string | null,
    client?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    specialistClient?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    specialist?: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    members?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMessageMutationVariables = {
  input: CreateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type CreateMessageMutation = {
  createMessage?: {
    __typename: "Message",
    id: string,
    content?: string | null,
    createdAt?: string | null,
    messageAuthorId?: string | null,
    readBy?: Array<string | null> | null,
    author?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    messageConversationId: string,
    conversation?: {
      __typename: "Conversation",
      id: string,
      specialistClientId?: string | null,
      clientId?: string | null,
      specialistId?: string | null,
      title?: string | null,
      client?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      specialistClient?: {
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      specialist?: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      members?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage?: {
    __typename: "Message",
    id: string,
    content?: string | null,
    createdAt?: string | null,
    messageAuthorId?: string | null,
    readBy?: Array<string | null> | null,
    author?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    messageConversationId: string,
    conversation?: {
      __typename: "Conversation",
      id: string,
      specialistClientId?: string | null,
      clientId?: string | null,
      specialistId?: string | null,
      title?: string | null,
      client?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      specialistClient?: {
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      specialist?: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      members?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage?: {
    __typename: "Message",
    id: string,
    content?: string | null,
    createdAt?: string | null,
    messageAuthorId?: string | null,
    readBy?: Array<string | null> | null,
    author?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    messageConversationId: string,
    conversation?: {
      __typename: "Conversation",
      id: string,
      specialistClientId?: string | null,
      clientId?: string | null,
      specialistId?: string | null,
      title?: string | null,
      client?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      specialistClient?: {
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      specialist?: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      members?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateCouponMutationVariables = {
  input: CreateCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type CreateCouponMutation = {
  createCoupon?: {
    __typename: "Coupon",
    id: string,
    coupon?: string | null,
    name?: string | null,
    specialistIds?: Array<string | null> | null,
    hasDiscount?: boolean | null,
    teamId?: string | null,
    percent_off?: string | null,
    max_redemptions?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCouponMutationVariables = {
  input: UpdateCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type UpdateCouponMutation = {
  updateCoupon?: {
    __typename: "Coupon",
    id: string,
    coupon?: string | null,
    name?: string | null,
    specialistIds?: Array<string | null> | null,
    hasDiscount?: boolean | null,
    teamId?: string | null,
    percent_off?: string | null,
    max_redemptions?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCouponMutationVariables = {
  input: DeleteCouponInput,
  condition?: ModelCouponConditionInput | null,
};

export type DeleteCouponMutation = {
  deleteCoupon?: {
    __typename: "Coupon",
    id: string,
    coupon?: string | null,
    name?: string | null,
    specialistIds?: Array<string | null> | null,
    hasDiscount?: boolean | null,
    teamId?: string | null,
    percent_off?: string | null,
    max_redemptions?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeviceMutationVariables = {
  input: CreateDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type CreateDeviceMutation = {
  createDevice?: {
    __typename: "Device",
    id: string,
    token: string,
    platform: string,
    bundleId: string,
    deviceArn?: string | null,
    deviceUserId?: string | null,
    user?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeviceMutationVariables = {
  input: DeleteDeviceInput,
  condition?: ModelDeviceConditionInput | null,
};

export type DeleteDeviceMutation = {
  deleteDevice?: {
    __typename: "Device",
    id: string,
    token: string,
    platform: string,
    bundleId: string,
    deviceArn?: string | null,
    deviceUserId?: string | null,
    user?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHabitsMutationVariables = {
  input: CreateHabitsInput,
  condition?: ModelHabitsConditionInput | null,
};

export type CreateHabitsMutation = {
  createHabits?: {
    __typename: "Habits",
    id: string,
    identityGoal?: string | null,
    weeklyReflectionDay?: Day | null,
    log?: Array<{
      __typename: "HabitLogEntry",
      content: string,
      type: string,
      createdAt: string,
    } | null> | null,
    behaviours?: Array<{
      __typename: "Behaviour",
      id?: string | null,
      behaviour?: string | null,
      status?: string | null,
      colour?: string | null,
    } | null> | null,
    actions?: Array<{
      __typename: "ActionTask",
      id?: string | null,
      description?: string | null,
      status?: string | null,
      behaviour?: string | null,
      behaviourId?: string | null,
      notifyTimestamp?: string | null,
    } | null> | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type UpdateHabitsMutationVariables = {
  input: UpdateHabitsInput,
  condition?: ModelHabitsConditionInput | null,
};

export type UpdateHabitsMutation = {
  updateHabits?: {
    __typename: "Habits",
    id: string,
    identityGoal?: string | null,
    weeklyReflectionDay?: Day | null,
    log?: Array<{
      __typename: "HabitLogEntry",
      content: string,
      type: string,
      createdAt: string,
    } | null> | null,
    behaviours?: Array<{
      __typename: "Behaviour",
      id?: string | null,
      behaviour?: string | null,
      status?: string | null,
      colour?: string | null,
    } | null> | null,
    actions?: Array<{
      __typename: "ActionTask",
      id?: string | null,
      description?: string | null,
      status?: string | null,
      behaviour?: string | null,
      behaviourId?: string | null,
      notifyTimestamp?: string | null,
    } | null> | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type DeleteHabitsMutationVariables = {
  input: DeleteHabitsInput,
  condition?: ModelHabitsConditionInput | null,
};

export type DeleteHabitsMutation = {
  deleteHabits?: {
    __typename: "Habits",
    id: string,
    identityGoal?: string | null,
    weeklyReflectionDay?: Day | null,
    log?: Array<{
      __typename: "HabitLogEntry",
      content: string,
      type: string,
      createdAt: string,
    } | null> | null,
    behaviours?: Array<{
      __typename: "Behaviour",
      id?: string | null,
      behaviour?: string | null,
      status?: string | null,
      colour?: string | null,
    } | null> | null,
    actions?: Array<{
      __typename: "ActionTask",
      id?: string | null,
      description?: string | null,
      status?: string | null,
      behaviour?: string | null,
      behaviourId?: string | null,
      notifyTimestamp?: string | null,
    } | null> | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type CreateInvitationMutationVariables = {
  input: CreateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type CreateInvitationMutation = {
  createInvitation?: {
    __typename: "Invitation",
    id: string,
    clientId: string,
    invitedUserId?: string | null,
    email: string,
    contactType?: ContactType | null,
    firstName?: string | null,
    lastName?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInvitationMutationVariables = {
  input: UpdateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type UpdateInvitationMutation = {
  updateInvitation?: {
    __typename: "Invitation",
    id: string,
    clientId: string,
    invitedUserId?: string | null,
    email: string,
    contactType?: ContactType | null,
    firstName?: string | null,
    lastName?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInvitationMutationVariables = {
  input: DeleteInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type DeleteInvitationMutation = {
  deleteInvitation?: {
    __typename: "Invitation",
    id: string,
    clientId: string,
    invitedUserId?: string | null,
    email: string,
    contactType?: ContactType | null,
    firstName?: string | null,
    lastName?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMealMutationVariables = {
  input: CreateMealInput,
  condition?: ModelMealConditionInput | null,
};

export type CreateMealMutation = {
  createMeal?: {
    __typename: "Meal",
    id: string,
    author: string,
    source?: {
      __typename: "Source",
      name?: string | null,
      avatar?: string | null,
      url?: string | null,
    } | null,
    title: string,
    image?: string | null,
    description?: string | null,
    mealTypes?: Array<string | null> | null,
    dietary?: Array<string | null> | null,
    allergens?: Array<string | null> | null,
    cuisines?: Array<string | null> | null,
    tags?: Array<string | null> | null,
    cookingMethods?: Array<string | null> | null,
    cookingSkill?: number | null,
    ingredients?: {
      __typename: "ModelIngredientConnection",
      items: Array<{
        __typename: "Ingredient",
        id: string,
        mealId: string,
        foodId?: string | null,
        food?: {
          __typename: "Food",
          id: string,
          item?: string | null,
          measurementUnits?: Array<string | null> | null,
          unit?: string | null,
          aisle?: string | null,
          allergens?: Array<string | null> | null,
          density?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        item?: string | null,
        qty?: number | null,
        unit?: string | null,
        isOptional?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    serves?: number | null,
    instructions?: Array<{
      __typename: "Instruction",
      step: number,
      direction: string,
    } | null> | null,
    times?: {
      __typename: "Times",
      total?: number | null,
      prep?: number | null,
      cook?: number | null,
    } | null,
    nutrition?: {
      __typename: "NutritionBreakdown",
      kilojoules?: number | null,
      carbohydrates?: number | null,
      fat?: number | null,
      protein?: number | null,
      fibre?: number | null,
      iron?: number | null,
      calcium?: number | null,
    } | null,
    notes?: string | null,
    status?: Status | null,
    showInRecipe?: boolean | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type UpdateMealMutationVariables = {
  input: UpdateMealInput,
  condition?: ModelMealConditionInput | null,
};

export type UpdateMealMutation = {
  updateMeal?: {
    __typename: "Meal",
    id: string,
    author: string,
    source?: {
      __typename: "Source",
      name?: string | null,
      avatar?: string | null,
      url?: string | null,
    } | null,
    title: string,
    image?: string | null,
    description?: string | null,
    mealTypes?: Array<string | null> | null,
    dietary?: Array<string | null> | null,
    allergens?: Array<string | null> | null,
    cuisines?: Array<string | null> | null,
    tags?: Array<string | null> | null,
    cookingMethods?: Array<string | null> | null,
    cookingSkill?: number | null,
    ingredients?: {
      __typename: "ModelIngredientConnection",
      items: Array<{
        __typename: "Ingredient",
        id: string,
        mealId: string,
        foodId?: string | null,
        food?: {
          __typename: "Food",
          id: string,
          item?: string | null,
          measurementUnits?: Array<string | null> | null,
          unit?: string | null,
          aisle?: string | null,
          allergens?: Array<string | null> | null,
          density?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        item?: string | null,
        qty?: number | null,
        unit?: string | null,
        isOptional?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    serves?: number | null,
    instructions?: Array<{
      __typename: "Instruction",
      step: number,
      direction: string,
    } | null> | null,
    times?: {
      __typename: "Times",
      total?: number | null,
      prep?: number | null,
      cook?: number | null,
    } | null,
    nutrition?: {
      __typename: "NutritionBreakdown",
      kilojoules?: number | null,
      carbohydrates?: number | null,
      fat?: number | null,
      protein?: number | null,
      fibre?: number | null,
      iron?: number | null,
      calcium?: number | null,
    } | null,
    notes?: string | null,
    status?: Status | null,
    showInRecipe?: boolean | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type DeleteMealMutationVariables = {
  input: DeleteMealInput,
  condition?: ModelMealConditionInput | null,
};

export type DeleteMealMutation = {
  deleteMeal?: {
    __typename: "Meal",
    id: string,
    author: string,
    source?: {
      __typename: "Source",
      name?: string | null,
      avatar?: string | null,
      url?: string | null,
    } | null,
    title: string,
    image?: string | null,
    description?: string | null,
    mealTypes?: Array<string | null> | null,
    dietary?: Array<string | null> | null,
    allergens?: Array<string | null> | null,
    cuisines?: Array<string | null> | null,
    tags?: Array<string | null> | null,
    cookingMethods?: Array<string | null> | null,
    cookingSkill?: number | null,
    ingredients?: {
      __typename: "ModelIngredientConnection",
      items: Array<{
        __typename: "Ingredient",
        id: string,
        mealId: string,
        foodId?: string | null,
        food?: {
          __typename: "Food",
          id: string,
          item?: string | null,
          measurementUnits?: Array<string | null> | null,
          unit?: string | null,
          aisle?: string | null,
          allergens?: Array<string | null> | null,
          density?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        item?: string | null,
        qty?: number | null,
        unit?: string | null,
        isOptional?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    serves?: number | null,
    instructions?: Array<{
      __typename: "Instruction",
      step: number,
      direction: string,
    } | null> | null,
    times?: {
      __typename: "Times",
      total?: number | null,
      prep?: number | null,
      cook?: number | null,
    } | null,
    nutrition?: {
      __typename: "NutritionBreakdown",
      kilojoules?: number | null,
      carbohydrates?: number | null,
      fat?: number | null,
      protein?: number | null,
      fibre?: number | null,
      iron?: number | null,
      calcium?: number | null,
    } | null,
    notes?: string | null,
    status?: Status | null,
    showInRecipe?: boolean | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type CreateIngredientMutationVariables = {
  input: CreateIngredientInput,
  condition?: ModelIngredientConditionInput | null,
};

export type CreateIngredientMutation = {
  createIngredient?: {
    __typename: "Ingredient",
    id: string,
    mealId: string,
    foodId?: string | null,
    food?: {
      __typename: "Food",
      id: string,
      item?: string | null,
      measurementUnits?: Array<string | null> | null,
      unit?: string | null,
      aisle?: string | null,
      allergens?: Array<string | null> | null,
      density?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    item?: string | null,
    qty?: number | null,
    unit?: string | null,
    isOptional?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIngredientMutationVariables = {
  input: UpdateIngredientInput,
  condition?: ModelIngredientConditionInput | null,
};

export type UpdateIngredientMutation = {
  updateIngredient?: {
    __typename: "Ingredient",
    id: string,
    mealId: string,
    foodId?: string | null,
    food?: {
      __typename: "Food",
      id: string,
      item?: string | null,
      measurementUnits?: Array<string | null> | null,
      unit?: string | null,
      aisle?: string | null,
      allergens?: Array<string | null> | null,
      density?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    item?: string | null,
    qty?: number | null,
    unit?: string | null,
    isOptional?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIngredientMutationVariables = {
  input: DeleteIngredientInput,
  condition?: ModelIngredientConditionInput | null,
};

export type DeleteIngredientMutation = {
  deleteIngredient?: {
    __typename: "Ingredient",
    id: string,
    mealId: string,
    foodId?: string | null,
    food?: {
      __typename: "Food",
      id: string,
      item?: string | null,
      measurementUnits?: Array<string | null> | null,
      unit?: string | null,
      aisle?: string | null,
      allergens?: Array<string | null> | null,
      density?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    item?: string | null,
    qty?: number | null,
    unit?: string | null,
    isOptional?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFoodMutationVariables = {
  input: CreateFoodInput,
  condition?: ModelFoodConditionInput | null,
};

export type CreateFoodMutation = {
  createFood?: {
    __typename: "Food",
    id: string,
    item?: string | null,
    measurementUnits?: Array<string | null> | null,
    unit?: string | null,
    aisle?: string | null,
    allergens?: Array<string | null> | null,
    density?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFoodMutationVariables = {
  input: UpdateFoodInput,
  condition?: ModelFoodConditionInput | null,
};

export type UpdateFoodMutation = {
  updateFood?: {
    __typename: "Food",
    id: string,
    item?: string | null,
    measurementUnits?: Array<string | null> | null,
    unit?: string | null,
    aisle?: string | null,
    allergens?: Array<string | null> | null,
    density?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFoodMutationVariables = {
  input: DeleteFoodInput,
  condition?: ModelFoodConditionInput | null,
};

export type DeleteFoodMutation = {
  deleteFood?: {
    __typename: "Food",
    id: string,
    item?: string | null,
    measurementUnits?: Array<string | null> | null,
    unit?: string | null,
    aisle?: string | null,
    allergens?: Array<string | null> | null,
    density?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMealPlanSetMutationVariables = {
  input: CreateMealPlanSetInput,
  condition?: ModelMealPlanSetConditionInput | null,
};

export type CreateMealPlanSetMutation = {
  createMealPlanSet?: {
    __typename: "MealPlanSet",
    id: string,
    type?: string | null,
    plans?: {
      __typename: "ModelMealPlanConnection",
      items: Array<{
        __typename: "MealPlan",
        id: string,
        type?: string | null,
        kilojoules?: number | null,
        mealGroups?: Array<{
          __typename: "MealGroup",
          type?: string | null,
          count?: number | null,
        } | null> | null,
        meals?: {
          __typename: "ModelMealPlanMealConnection",
          nextToken?: string | null,
        } | null,
        startingAt?: string | null,
        status?: Status | null,
        userId?: string | null,
        mealPlanSetId?: string | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMealPlanSetMutationVariables = {
  input: UpdateMealPlanSetInput,
  condition?: ModelMealPlanSetConditionInput | null,
};

export type UpdateMealPlanSetMutation = {
  updateMealPlanSet?: {
    __typename: "MealPlanSet",
    id: string,
    type?: string | null,
    plans?: {
      __typename: "ModelMealPlanConnection",
      items: Array<{
        __typename: "MealPlan",
        id: string,
        type?: string | null,
        kilojoules?: number | null,
        mealGroups?: Array<{
          __typename: "MealGroup",
          type?: string | null,
          count?: number | null,
        } | null> | null,
        meals?: {
          __typename: "ModelMealPlanMealConnection",
          items: Array<{
            __typename: "MealPlanMeal",
            id: string,
            mealPlanId?: string | null,
            mealId?: string | null,
            meal: {
              __typename: "Meal",
              id: string,
              author: string,
              title: string,
              image?: string | null,
              description?: string | null,
              mealTypes?: Array<string | null> | null,
              dietary?: Array<string | null> | null,
              allergens?: Array<string | null> | null,
              cuisines?: Array<string | null> | null,
              tags?: Array<string | null> | null,
              cookingMethods?: Array<string | null> | null,
              cookingSkill?: number | null,
              serves?: number | null,
              notes?: string | null,
              status?: Status | null,
              showInRecipe?: boolean | null,
              updatedAt?: string | null,
              createdAt: string,
            },
            type?: string | null,
            note?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null>,
          nextToken?: string | null,
        } | null,
        startingAt?: string | null,
        status?: Status | null,
        userId?: string | null,
        mealPlanSetId?: string | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMealPlanSetMutationVariables = {
  input: DeleteMealPlanSetInput,
  condition?: ModelMealPlanSetConditionInput | null,
};

export type DeleteMealPlanSetMutation = {
  deleteMealPlanSet?: {
    __typename: "MealPlanSet",
    id: string,
    type?: string | null,
    plans?: {
      __typename: "ModelMealPlanConnection",
      items: Array<{
        __typename: "MealPlan",
        id: string,
        type?: string | null,
        kilojoules?: number | null,
        mealGroups?: Array<{
          __typename: "MealGroup",
          type?: string | null,
          count?: number | null,
        } | null> | null,
        meals?: {
          __typename: "ModelMealPlanMealConnection",
          nextToken?: string | null,
        } | null,
        startingAt?: string | null,
        status?: Status | null,
        userId?: string | null,
        mealPlanSetId?: string | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMealPlanMutationVariables = {
  input: CreateMealPlanInput,
  condition?: ModelMealPlanConditionInput | null,
};

export type CreateMealPlanMutation = {
  createMealPlan?: {
    __typename: "MealPlan",
    id: string,
    type?: string | null,
    kilojoules?: number | null,
    mealGroups?: Array<{
      __typename: "MealGroup",
      type?: string | null,
      count?: number | null,
    } | null> | null,
    meals?: {
      __typename: "ModelMealPlanMealConnection",
      items: Array<{
        __typename: "MealPlanMeal",
        id: string,
        mealPlanId?: string | null,
        mealId?: string | null,
        meal: {
          __typename: "Meal",
          id: string,
          author: string,
          title: string,
          image?: string | null,
          description?: string | null,
          mealTypes?: Array<string | null> | null,
          dietary?: Array<string | null> | null,
          allergens?: Array<string | null> | null,
          cuisines?: Array<string | null> | null,
          tags?: Array<string | null> | null,
          cookingMethods?: Array<string | null> | null,
          cookingSkill?: number | null,
          serves?: number | null,
          notes?: string | null,
          status?: Status | null,
          showInRecipe?: boolean | null,
          updatedAt?: string | null,
          createdAt: string,
        },
        type?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    mealPlanSetId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMealPlanMutationVariables = {
  input: UpdateMealPlanInput,
  condition?: ModelMealPlanConditionInput | null,
};

export type UpdateMealPlanMutation = {
  updateMealPlan?: {
    __typename: "MealPlan",
    id: string,
    type?: string | null,
    kilojoules?: number | null,
    mealGroups?: Array<{
      __typename: "MealGroup",
      type?: string | null,
      count?: number | null,
    } | null> | null,
    meals?: {
      __typename: "ModelMealPlanMealConnection",
      items: Array<{
        __typename: "MealPlanMeal",
        id: string,
        mealPlanId?: string | null,
        mealId?: string | null,
        meal: {
          __typename: "Meal",
          id: string,
          author: string,
          title: string,
          image?: string | null,
          description?: string | null,
          mealTypes?: Array<string | null> | null,
          dietary?: Array<string | null> | null,
          allergens?: Array<string | null> | null,
          cuisines?: Array<string | null> | null,
          tags?: Array<string | null> | null,
          cookingMethods?: Array<string | null> | null,
          cookingSkill?: number | null,
          serves?: number | null,
          notes?: string | null,
          status?: Status | null,
          showInRecipe?: boolean | null,
          updatedAt?: string | null,
          createdAt: string,
        },
        type?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    mealPlanSetId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMealPlanMutationVariables = {
  input: DeleteMealPlanInput,
  condition?: ModelMealPlanConditionInput | null,
};

export type DeleteMealPlanMutation = {
  deleteMealPlan?: {
    __typename: "MealPlan",
    id: string,
    type?: string | null,
    kilojoules?: number | null,
    mealGroups?: Array<{
      __typename: "MealGroup",
      type?: string | null,
      count?: number | null,
    } | null> | null,
    meals?: {
      __typename: "ModelMealPlanMealConnection",
      items: Array<{
        __typename: "MealPlanMeal",
        id: string,
        mealPlanId?: string | null,
        mealId?: string | null,
        meal: {
          __typename: "Meal",
          id: string,
          author: string,
          title: string,
          image?: string | null,
          description?: string | null,
          mealTypes?: Array<string | null> | null,
          dietary?: Array<string | null> | null,
          allergens?: Array<string | null> | null,
          cuisines?: Array<string | null> | null,
          tags?: Array<string | null> | null,
          cookingMethods?: Array<string | null> | null,
          cookingSkill?: number | null,
          serves?: number | null,
          notes?: string | null,
          status?: Status | null,
          showInRecipe?: boolean | null,
          updatedAt?: string | null,
          createdAt: string,
        },
        type?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    mealPlanSetId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMealPlanMealMutationVariables = {
  input: CreateMealPlanMealInput,
  condition?: ModelMealPlanMealConditionInput | null,
};

export type CreateMealPlanMealMutation = {
  createMealPlanMeal?: {
    __typename: "MealPlanMeal",
    id: string,
    mealPlanId?: string | null,
    mealId?: string | null,
    meal: {
      __typename: "Meal",
      id: string,
      author: string,
      source?: {
        __typename: "Source",
        name?: string | null,
        avatar?: string | null,
        url?: string | null,
      } | null,
      title: string,
      image?: string | null,
      description?: string | null,
      mealTypes?: Array<string | null> | null,
      dietary?: Array<string | null> | null,
      allergens?: Array<string | null> | null,
      cuisines?: Array<string | null> | null,
      tags?: Array<string | null> | null,
      cookingMethods?: Array<string | null> | null,
      cookingSkill?: number | null,
      ingredients?: {
        __typename: "ModelIngredientConnection",
        items: Array<{
          __typename: "Ingredient",
          id: string,
          mealId: string,
          foodId?: string | null,
          item?: string | null,
          qty?: number | null,
          unit?: string | null,
          isOptional?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      serves?: number | null,
      instructions?: Array<{
        __typename: "Instruction",
        step: number,
        direction: string,
      } | null> | null,
      times?: {
        __typename: "Times",
        total?: number | null,
        prep?: number | null,
        cook?: number | null,
      } | null,
      nutrition?: {
        __typename: "NutritionBreakdown",
        kilojoules?: number | null,
        carbohydrates?: number | null,
        fat?: number | null,
        protein?: number | null,
        fibre?: number | null,
        iron?: number | null,
        calcium?: number | null,
      } | null,
      notes?: string | null,
      status?: Status | null,
      showInRecipe?: boolean | null,
      updatedAt?: string | null,
      createdAt: string,
    },
    type?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMealPlanMealMutationVariables = {
  input: UpdateMealPlanMealInput,
  condition?: ModelMealPlanMealConditionInput | null,
};

export type UpdateMealPlanMealMutation = {
  updateMealPlanMeal?: {
    __typename: "MealPlanMeal",
    id: string,
    mealPlanId?: string | null,
    mealId?: string | null,
    meal: {
      __typename: "Meal",
      id: string,
      author: string,
      source?: {
        __typename: "Source",
        name?: string | null,
        avatar?: string | null,
        url?: string | null,
      } | null,
      title: string,
      image?: string | null,
      description?: string | null,
      mealTypes?: Array<string | null> | null,
      dietary?: Array<string | null> | null,
      allergens?: Array<string | null> | null,
      cuisines?: Array<string | null> | null,
      tags?: Array<string | null> | null,
      cookingMethods?: Array<string | null> | null,
      cookingSkill?: number | null,
      ingredients?: {
        __typename: "ModelIngredientConnection",
        items: Array<{
          __typename: "Ingredient",
          id: string,
          mealId: string,
          foodId?: string | null,
          item?: string | null,
          qty?: number | null,
          unit?: string | null,
          isOptional?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      serves?: number | null,
      instructions?: Array<{
        __typename: "Instruction",
        step: number,
        direction: string,
      } | null> | null,
      times?: {
        __typename: "Times",
        total?: number | null,
        prep?: number | null,
        cook?: number | null,
      } | null,
      nutrition?: {
        __typename: "NutritionBreakdown",
        kilojoules?: number | null,
        carbohydrates?: number | null,
        fat?: number | null,
        protein?: number | null,
        fibre?: number | null,
        iron?: number | null,
        calcium?: number | null,
      } | null,
      notes?: string | null,
      status?: Status | null,
      showInRecipe?: boolean | null,
      updatedAt?: string | null,
      createdAt: string,
    },
    type?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMealPlanMealMutationVariables = {
  input: DeleteMealPlanMealInput,
  condition?: ModelMealPlanMealConditionInput | null,
};

export type DeleteMealPlanMealMutation = {
  deleteMealPlanMeal?: {
    __typename: "MealPlanMeal",
    id: string,
    mealPlanId?: string | null,
    mealId?: string | null,
    meal: {
      __typename: "Meal",
      id: string,
      author: string,
      source?: {
        __typename: "Source",
        name?: string | null,
        avatar?: string | null,
        url?: string | null,
      } | null,
      title: string,
      image?: string | null,
      description?: string | null,
      mealTypes?: Array<string | null> | null,
      dietary?: Array<string | null> | null,
      allergens?: Array<string | null> | null,
      cuisines?: Array<string | null> | null,
      tags?: Array<string | null> | null,
      cookingMethods?: Array<string | null> | null,
      cookingSkill?: number | null,
      ingredients?: {
        __typename: "ModelIngredientConnection",
        items: Array<{
          __typename: "Ingredient",
          id: string,
          mealId: string,
          foodId?: string | null,
          item?: string | null,
          qty?: number | null,
          unit?: string | null,
          isOptional?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      serves?: number | null,
      instructions?: Array<{
        __typename: "Instruction",
        step: number,
        direction: string,
      } | null> | null,
      times?: {
        __typename: "Times",
        total?: number | null,
        prep?: number | null,
        cook?: number | null,
      } | null,
      nutrition?: {
        __typename: "NutritionBreakdown",
        kilojoules?: number | null,
        carbohydrates?: number | null,
        fat?: number | null,
        protein?: number | null,
        fibre?: number | null,
        iron?: number | null,
        calcium?: number | null,
      } | null,
      notes?: string | null,
      status?: Status | null,
      showInRecipe?: boolean | null,
      updatedAt?: string | null,
      createdAt: string,
    },
    type?: string | null,
    note?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSpecialistProfileMutationVariables = {
  input: UpdateSpecialistProfileInput,
  condition?: ModelSpecialistProfileConditionInput | null,
};

export type UpdateSpecialistProfileMutation = {
  updateSpecialistProfile?: {
    __typename: "SpecialistProfile",
    id: string,
    displayName?: string | null,
    businessName?: string | null,
    qualifications?: string | null,
    clinicalSpecialities?: Array<string | null> | null,
    sportSpecialities?: Array<string | null> | null,
    bio?: string | null,
    trivia?: Array<string | null> | null,
    providerNumber?: string | null,
    businessIdNumber?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    image?: string | null,
    video?: string | null,
    status?: string | null,
    isAvailable?: boolean | null,
    clientLimit?: number | null,
    isTeamsAvailable?: boolean | null,
    socialAccounts?: Array<{
      __typename: "SocialAccount",
      network?: string | null,
      account?: string | null,
    } | null> | null,
    referralLink?: string | null,
    integrations?: Array<{
      __typename: "Integration",
      name?: string | null,
      attributes?: string | null,
    } | null> | null,
    ownerId: string,
    owner?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    clients?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    myClients?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSpecialistProfileMutationVariables = {
  input: DeleteSpecialistProfileInput,
  condition?: ModelSpecialistProfileConditionInput | null,
};

export type DeleteSpecialistProfileMutation = {
  deleteSpecialistProfile?: {
    __typename: "SpecialistProfile",
    id: string,
    displayName?: string | null,
    businessName?: string | null,
    qualifications?: string | null,
    clinicalSpecialities?: Array<string | null> | null,
    sportSpecialities?: Array<string | null> | null,
    bio?: string | null,
    trivia?: Array<string | null> | null,
    providerNumber?: string | null,
    businessIdNumber?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    image?: string | null,
    video?: string | null,
    status?: string | null,
    isAvailable?: boolean | null,
    clientLimit?: number | null,
    isTeamsAvailable?: boolean | null,
    socialAccounts?: Array<{
      __typename: "SocialAccount",
      network?: string | null,
      account?: string | null,
    } | null> | null,
    referralLink?: string | null,
    integrations?: Array<{
      __typename: "Integration",
      name?: string | null,
      attributes?: string | null,
    } | null> | null,
    ownerId: string,
    owner?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    clients?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    myClients?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTeamMutationVariables = {
  input: CreateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type CreateTeamMutation = {
  createTeam?: {
    __typename: "Team",
    id: string,
    name?: string | null,
    logo?: string | null,
    coupons?: Array<string | null> | null,
    users?: Array<string | null> | null,
    specialists?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type UpdateTeamMutation = {
  updateTeam?: {
    __typename: "Team",
    id: string,
    name?: string | null,
    logo?: string | null,
    coupons?: Array<string | null> | null,
    users?: Array<string | null> | null,
    specialists?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type DeleteTeamMutation = {
  deleteTeam?: {
    __typename: "Team",
    id: string,
    name?: string | null,
    logo?: string | null,
    coupons?: Array<string | null> | null,
    users?: Array<string | null> | null,
    specialists?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?: {
    __typename: "User",
    id: string,
    authId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profile?: {
      __typename: "Profile",
      sex?: string | null,
      pronouns?: string | null,
      ageBracket?: string | null,
    } | null,
    avatar?: string | null,
    phone?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    bodyType?: string | null,
    height?: string | null,
    weight?: number | null,
    bodyFat?: number | null,
    activityFactor?: number | null,
    nutritionNotes?: string | null,
    joinReason?: string | null,
    trainingCalendarLink?: string | null,
    guessedLocation?: string | null,
    journal?: Array<{
      __typename: "Record",
      weight?: string | null,
      waist?: string | null,
      appetiteRating?: number | null,
      energyRating?: number | null,
      sleepRating?: number | null,
      motivationRating?: number | null,
      wellnessRating?: number | null,
      stressRating?: number | null,
      nutritionRating?: number | null,
      createdAt: string,
    } | null> | null,
    events?: Array<{
      __typename: "Event",
      name: string,
      details?: string | null,
      date?: string | null,
      location?: string | null,
      goal?: string | null,
      preEventFoodRituals?: string | null,
      nutritionProducts?: string | null,
      nutritionLogistics?: string | null,
      issues?: string | null,
      leadupEvents?: string | null,
      accomodation?: string | null,
      leadupEventDate?: string | null,
      leadupEventDetails?: string | null,
      createdAt: string,
    } | null> | null,
    sports?: Array<{
      __typename: "Sport",
      name?: string | null,
      level?: string | null,
      comments?: string | null,
    } | null> | null,
    goals?: Array<{
      __typename: "Goal",
      description: string,
      comments?: string | null,
      progress?: Array<{
        __typename: "Progress",
        rating: number,
        createdAt: string,
      } | null> | null,
      targetDate?: string | null,
      createdAt: string,
    } | null> | null,
    teams?: Array<{
      __typename: "TeamGroup",
      name?: string | null,
      joinedAt?: string | null,
    } | null> | null,
    foodDiary?: Array<{
      __typename: "DailyLog",
      date?: string | null,
      day?: string | null,
      items?: Array<{
        __typename: "LogItem",
        time?: string | null,
        detail?: string | null,
        comments?: string | null,
      } | null> | null,
    } | null> | null,
    menuScheduleDay?: Day | null,
    nutritionInformation?: {
      __typename: "Nutrition",
      allergies?: Array<string | null> | null,
      supplements?: string | null,
      dislikes?: string | null,
      comments?: string | null,
      preferences?: string | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      dietaryRequirements?: Array<string | null> | null,
    } | null,
    nutritionAssessments?: Array<{
      __typename: "NutritionAssessment",
      requestDate: string,
      responseStartDate?: string | null,
      responseCompleteDate?: string | null,
      responses?: Array<{
        __typename: "Response",
        question?: string | null,
        answer?: string | null,
        comments?: string | null,
        score?: number | null,
      } | null> | null,
      score?: number | null,
    } | null> | null,
    lifestyle?: {
      __typename: "Lifestyle",
      cookingSkills?: string | null,
      cookingMethods?: Array<string | null> | null,
      easyNights?: Array<Day | null> | null,
      homeSituation?: string | null,
      peopleToFeed?: string | null,
      workDays?: Array<Day | null> | null,
      nightsEatingOut?: Array<Day | null> | null,
      workShifts?: string | null,
      breakfastOnTheRun?: Array<Day | null> | null,
      occupation?: string | null,
      preferences?: string | null,
    } | null,
    healthInformation?: {
      __typename: "HealthInformation",
      medicalConditions?: string | null,
      medication?: string | null,
      isMenstruating?: boolean | null,
      regularPeriod?: boolean | null,
      periodStoppedOver3Months?: string | null,
    } | null,
    membership?: {
      __typename: "Membership",
      id?: string | null,
      amount?: string | null,
      plan?: string | null,
      discount?: string | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      cancelAtPeriodEnd?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    permission?: {
      __typename: "Permission",
      hasMeals?: boolean | null,
      hasHabits?: boolean | null,
    } | null,
    priority?: {
      __typename: "Priority",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null,
    priorityConfidence?: Array<{
      __typename: "PriorityConfidence",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null> | null,
    userRole?: Role | null,
    lastActiveAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    paymentMethod?: {
      __typename: "PaymentMethod",
      id?: string | null,
      type?: string | null,
      brand?: string | null,
      exp_month?: number | null,
      exp_year?: number | null,
      last4?: string | null,
    } | null,
    status?: Status | null,
    devices?: {
      __typename: "ModelDeviceConnection",
      items: Array<{
        __typename: "Device",
        id: string,
        token: string,
        platform: string,
        bundleId: string,
        deviceArn?: string | null,
        deviceUserId?: string | null,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    specialists?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    alerts?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    notifications?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    contactInfoId?: string | null,
    contactInfo?: {
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientProfile?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
  } | null,
};

export type CreateContactInfoMutationVariables = {
  input: CreateContactInfoInput,
  condition?: ModelContactInfoConditionInput | null,
};

export type CreateContactInfoMutation = {
  createContactInfo?: {
    __typename: "ContactInfo",
    id: string,
    userId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    avatar?: string | null,
    phone?: string | null,
    permissionToShare?: boolean | null,
    contacts?: {
      __typename: "ModelContactConnection",
      items: Array<{
        __typename: "Contact",
        id: string,
        contactInfoId: string,
        clientId: string,
        type?: ContactType | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        client?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContactInfoMutationVariables = {
  input: UpdateContactInfoInput,
  condition?: ModelContactInfoConditionInput | null,
};

export type UpdateContactInfoMutation = {
  updateContactInfo?: {
    __typename: "ContactInfo",
    id: string,
    userId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    avatar?: string | null,
    phone?: string | null,
    permissionToShare?: boolean | null,
    contacts?: {
      __typename: "ModelContactConnection",
      items: Array<{
        __typename: "Contact",
        id: string,
        contactInfoId: string,
        clientId: string,
        type?: ContactType | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        client?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContactInfoMutationVariables = {
  input: DeleteContactInfoInput,
  condition?: ModelContactInfoConditionInput | null,
};

export type DeleteContactInfoMutation = {
  deleteContactInfo?: {
    __typename: "ContactInfo",
    id: string,
    userId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    avatar?: string | null,
    phone?: string | null,
    permissionToShare?: boolean | null,
    contacts?: {
      __typename: "ModelContactConnection",
      items: Array<{
        __typename: "Contact",
        id: string,
        contactInfoId: string,
        clientId: string,
        type?: ContactType | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        client?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserTokenMutationVariables = {
  input: DeleteUserTokenInput,
  condition?: ModelUserTokenConditionInput | null,
};

export type DeleteUserTokenMutation = {
  deleteUserToken?: {
    __typename: "UserToken",
    id: string,
    createdAt: string,
    userId: string,
    token: string,
    isUsed: boolean,
    updatedAt: string,
  } | null,
};

export type CreateAlertMutationVariables = {
  input: CreateAlertInput,
  condition?: ModelAlertConditionInput | null,
};

export type CreateAlertMutation = {
  createAlert?: {
    __typename: "Alert",
    id: string,
    content?: string | null,
    action?: Action | null,
    isRead?: boolean | null,
    createdAt: string,
    updatedAt: string,
    alertUserId: string,
    user?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
  } | null,
};

export type CreateSpecialistProfileMutationVariables = {
  input: CreateSpecialistProfileInput,
  condition?: ModelSpecialistProfileConditionInput | null,
};

export type CreateSpecialistProfileMutation = {
  createSpecialistProfile?: {
    __typename: "SpecialistProfile",
    id: string,
    displayName?: string | null,
    businessName?: string | null,
    qualifications?: string | null,
    clinicalSpecialities?: Array<string | null> | null,
    sportSpecialities?: Array<string | null> | null,
    bio?: string | null,
    trivia?: Array<string | null> | null,
    providerNumber?: string | null,
    businessIdNumber?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    image?: string | null,
    video?: string | null,
    status?: string | null,
    isAvailable?: boolean | null,
    clientLimit?: number | null,
    isTeamsAvailable?: boolean | null,
    socialAccounts?: Array<{
      __typename: "SocialAccount",
      network?: string | null,
      account?: string | null,
    } | null> | null,
    referralLink?: string | null,
    integrations?: Array<{
      __typename: "Integration",
      name?: string | null,
      attributes?: string | null,
    } | null> | null,
    ownerId: string,
    owner?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    clients?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    myClients?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?: {
    __typename: "User",
    id: string,
    authId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profile?: {
      __typename: "Profile",
      sex?: string | null,
      pronouns?: string | null,
      ageBracket?: string | null,
    } | null,
    avatar?: string | null,
    phone?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    bodyType?: string | null,
    height?: string | null,
    weight?: number | null,
    bodyFat?: number | null,
    activityFactor?: number | null,
    nutritionNotes?: string | null,
    joinReason?: string | null,
    trainingCalendarLink?: string | null,
    guessedLocation?: string | null,
    journal?: Array<{
      __typename: "Record",
      weight?: string | null,
      waist?: string | null,
      appetiteRating?: number | null,
      energyRating?: number | null,
      sleepRating?: number | null,
      motivationRating?: number | null,
      wellnessRating?: number | null,
      stressRating?: number | null,
      nutritionRating?: number | null,
      createdAt: string,
    } | null> | null,
    events?: Array<{
      __typename: "Event",
      name: string,
      details?: string | null,
      date?: string | null,
      location?: string | null,
      goal?: string | null,
      preEventFoodRituals?: string | null,
      nutritionProducts?: string | null,
      nutritionLogistics?: string | null,
      issues?: string | null,
      leadupEvents?: string | null,
      accomodation?: string | null,
      leadupEventDate?: string | null,
      leadupEventDetails?: string | null,
      createdAt: string,
    } | null> | null,
    sports?: Array<{
      __typename: "Sport",
      name?: string | null,
      level?: string | null,
      comments?: string | null,
    } | null> | null,
    goals?: Array<{
      __typename: "Goal",
      description: string,
      comments?: string | null,
      progress?: Array<{
        __typename: "Progress",
        rating: number,
        createdAt: string,
      } | null> | null,
      targetDate?: string | null,
      createdAt: string,
    } | null> | null,
    teams?: Array<{
      __typename: "TeamGroup",
      name?: string | null,
      joinedAt?: string | null,
    } | null> | null,
    foodDiary?: Array<{
      __typename: "DailyLog",
      date?: string | null,
      day?: string | null,
      items?: Array<{
        __typename: "LogItem",
        time?: string | null,
        detail?: string | null,
        comments?: string | null,
      } | null> | null,
    } | null> | null,
    menuScheduleDay?: Day | null,
    nutritionInformation?: {
      __typename: "Nutrition",
      allergies?: Array<string | null> | null,
      supplements?: string | null,
      dislikes?: string | null,
      comments?: string | null,
      preferences?: string | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      dietaryRequirements?: Array<string | null> | null,
    } | null,
    nutritionAssessments?: Array<{
      __typename: "NutritionAssessment",
      requestDate: string,
      responseStartDate?: string | null,
      responseCompleteDate?: string | null,
      responses?: Array<{
        __typename: "Response",
        question?: string | null,
        answer?: string | null,
        comments?: string | null,
        score?: number | null,
      } | null> | null,
      score?: number | null,
    } | null> | null,
    lifestyle?: {
      __typename: "Lifestyle",
      cookingSkills?: string | null,
      cookingMethods?: Array<string | null> | null,
      easyNights?: Array<Day | null> | null,
      homeSituation?: string | null,
      peopleToFeed?: string | null,
      workDays?: Array<Day | null> | null,
      nightsEatingOut?: Array<Day | null> | null,
      workShifts?: string | null,
      breakfastOnTheRun?: Array<Day | null> | null,
      occupation?: string | null,
      preferences?: string | null,
    } | null,
    healthInformation?: {
      __typename: "HealthInformation",
      medicalConditions?: string | null,
      medication?: string | null,
      isMenstruating?: boolean | null,
      regularPeriod?: boolean | null,
      periodStoppedOver3Months?: string | null,
    } | null,
    membership?: {
      __typename: "Membership",
      id?: string | null,
      amount?: string | null,
      plan?: string | null,
      discount?: string | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      cancelAtPeriodEnd?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    permission?: {
      __typename: "Permission",
      hasMeals?: boolean | null,
      hasHabits?: boolean | null,
    } | null,
    priority?: {
      __typename: "Priority",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null,
    priorityConfidence?: Array<{
      __typename: "PriorityConfidence",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null> | null,
    userRole?: Role | null,
    lastActiveAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    paymentMethod?: {
      __typename: "PaymentMethod",
      id?: string | null,
      type?: string | null,
      brand?: string | null,
      exp_month?: number | null,
      exp_year?: number | null,
      last4?: string | null,
    } | null,
    status?: Status | null,
    devices?: {
      __typename: "ModelDeviceConnection",
      items: Array<{
        __typename: "Device",
        id: string,
        token: string,
        platform: string,
        bundleId: string,
        deviceArn?: string | null,
        deviceUserId?: string | null,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    specialists?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    alerts?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    notifications?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    contactInfoId?: string | null,
    contactInfo?: {
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientProfile?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?: {
    __typename: "User",
    id: string,
    authId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profile?: {
      __typename: "Profile",
      sex?: string | null,
      pronouns?: string | null,
      ageBracket?: string | null,
    } | null,
    avatar?: string | null,
    phone?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    bodyType?: string | null,
    height?: string | null,
    weight?: number | null,
    bodyFat?: number | null,
    activityFactor?: number | null,
    nutritionNotes?: string | null,
    joinReason?: string | null,
    trainingCalendarLink?: string | null,
    guessedLocation?: string | null,
    journal?: Array<{
      __typename: "Record",
      weight?: string | null,
      waist?: string | null,
      appetiteRating?: number | null,
      energyRating?: number | null,
      sleepRating?: number | null,
      motivationRating?: number | null,
      wellnessRating?: number | null,
      stressRating?: number | null,
      nutritionRating?: number | null,
      createdAt: string,
    } | null> | null,
    events?: Array<{
      __typename: "Event",
      name: string,
      details?: string | null,
      date?: string | null,
      location?: string | null,
      goal?: string | null,
      preEventFoodRituals?: string | null,
      nutritionProducts?: string | null,
      nutritionLogistics?: string | null,
      issues?: string | null,
      leadupEvents?: string | null,
      accomodation?: string | null,
      leadupEventDate?: string | null,
      leadupEventDetails?: string | null,
      createdAt: string,
    } | null> | null,
    sports?: Array<{
      __typename: "Sport",
      name?: string | null,
      level?: string | null,
      comments?: string | null,
    } | null> | null,
    goals?: Array<{
      __typename: "Goal",
      description: string,
      comments?: string | null,
      progress?: Array<{
        __typename: "Progress",
        rating: number,
        createdAt: string,
      } | null> | null,
      targetDate?: string | null,
      createdAt: string,
    } | null> | null,
    teams?: Array<{
      __typename: "TeamGroup",
      name?: string | null,
      joinedAt?: string | null,
    } | null> | null,
    foodDiary?: Array<{
      __typename: "DailyLog",
      date?: string | null,
      day?: string | null,
      items?: Array<{
        __typename: "LogItem",
        time?: string | null,
        detail?: string | null,
        comments?: string | null,
      } | null> | null,
    } | null> | null,
    menuScheduleDay?: Day | null,
    nutritionInformation?: {
      __typename: "Nutrition",
      allergies?: Array<string | null> | null,
      supplements?: string | null,
      dislikes?: string | null,
      comments?: string | null,
      preferences?: string | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      dietaryRequirements?: Array<string | null> | null,
    } | null,
    nutritionAssessments?: Array<{
      __typename: "NutritionAssessment",
      requestDate: string,
      responseStartDate?: string | null,
      responseCompleteDate?: string | null,
      responses?: Array<{
        __typename: "Response",
        question?: string | null,
        answer?: string | null,
        comments?: string | null,
        score?: number | null,
      } | null> | null,
      score?: number | null,
    } | null> | null,
    lifestyle?: {
      __typename: "Lifestyle",
      cookingSkills?: string | null,
      cookingMethods?: Array<string | null> | null,
      easyNights?: Array<Day | null> | null,
      homeSituation?: string | null,
      peopleToFeed?: string | null,
      workDays?: Array<Day | null> | null,
      nightsEatingOut?: Array<Day | null> | null,
      workShifts?: string | null,
      breakfastOnTheRun?: Array<Day | null> | null,
      occupation?: string | null,
      preferences?: string | null,
    } | null,
    healthInformation?: {
      __typename: "HealthInformation",
      medicalConditions?: string | null,
      medication?: string | null,
      isMenstruating?: boolean | null,
      regularPeriod?: boolean | null,
      periodStoppedOver3Months?: string | null,
    } | null,
    membership?: {
      __typename: "Membership",
      id?: string | null,
      amount?: string | null,
      plan?: string | null,
      discount?: string | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      cancelAtPeriodEnd?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    permission?: {
      __typename: "Permission",
      hasMeals?: boolean | null,
      hasHabits?: boolean | null,
    } | null,
    priority?: {
      __typename: "Priority",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null,
    priorityConfidence?: Array<{
      __typename: "PriorityConfidence",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null> | null,
    userRole?: Role | null,
    lastActiveAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    paymentMethod?: {
      __typename: "PaymentMethod",
      id?: string | null,
      type?: string | null,
      brand?: string | null,
      exp_month?: number | null,
      exp_year?: number | null,
      last4?: string | null,
    } | null,
    status?: Status | null,
    devices?: {
      __typename: "ModelDeviceConnection",
      items: Array<{
        __typename: "Device",
        id: string,
        token: string,
        platform: string,
        bundleId: string,
        deviceArn?: string | null,
        deviceUserId?: string | null,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    specialists?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    alerts?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    notifications?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    contactInfoId?: string | null,
    contactInfo?: {
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientProfile?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
  } | null,
};

export type CreateUserTokenMutationVariables = {
  input: CreateUserTokenInput,
  condition?: ModelUserTokenConditionInput | null,
};

export type CreateUserTokenMutation = {
  createUserToken?: {
    __typename: "UserToken",
    id: string,
    createdAt: string,
    userId: string,
    token: string,
    isUsed: boolean,
    updatedAt: string,
  } | null,
};

export type UpdateUserTokenMutationVariables = {
  input: UpdateUserTokenInput,
  condition?: ModelUserTokenConditionInput | null,
};

export type UpdateUserTokenMutation = {
  updateUserToken?: {
    __typename: "UserToken",
    id: string,
    createdAt: string,
    userId: string,
    token: string,
    isUsed: boolean,
    updatedAt: string,
  } | null,
};

export type SearchMealsQueryVariables = {
  search?: string | null,
  mealTypes?: Array<string | null> | null,
  requiredDiets?: Array<string | null> | null,
  excludedAllergens?: Array<string | null> | null,
  cuisines?: Array<string | null> | null,
  requiredTags?: Array<string | null> | null,
  excludedIngredients?: Array<string | null> | null,
  cookingMethods?: Array<string | null> | null,
  allCookingMethods?: Array<string | null> | null,
  excludedMeals?: Array<string | null> | null,
  cookingSkill?: number | null,
  minKilojoules?: number | null,
  maxKilojoules?: number | null,
  minCarbohydrates?: number | null,
  maxCarbohydrates?: number | null,
  minFat?: number | null,
  maxFat?: number | null,
  minProtein?: number | null,
  maxProtein?: number | null,
  minFibre?: number | null,
  maxFibre?: number | null,
  minIron?: number | null,
  maxIron?: number | null,
  minCalcium?: number | null,
  maxCalcium?: number | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SearchMealsQuery = {
  searchMeals?: {
    __typename: "MealListResponse",
    items?: Array<{
      __typename: "Meal",
      id: string,
      author: string,
      source?: {
        __typename: "Source",
        name?: string | null,
        avatar?: string | null,
        url?: string | null,
      } | null,
      title: string,
      image?: string | null,
      description?: string | null,
      mealTypes?: Array<string | null> | null,
      dietary?: Array<string | null> | null,
      allergens?: Array<string | null> | null,
      cuisines?: Array<string | null> | null,
      tags?: Array<string | null> | null,
      cookingMethods?: Array<string | null> | null,
      cookingSkill?: number | null,
      ingredients?: {
        __typename: "ModelIngredientConnection",
        items: Array<{
          __typename: "Ingredient",
          id: string,
          mealId: string,
          foodId?: string | null,
          item?: string | null,
          qty?: number | null,
          unit?: string | null,
          isOptional?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      serves?: number | null,
      instructions?: Array<{
        __typename: "Instruction",
        step: number,
        direction: string,
      } | null> | null,
      times?: {
        __typename: "Times",
        total?: number | null,
        prep?: number | null,
        cook?: number | null,
      } | null,
      nutrition?: {
        __typename: "NutritionBreakdown",
        kilojoules?: number | null,
        carbohydrates?: number | null,
        fat?: number | null,
        protein?: number | null,
        fibre?: number | null,
        iron?: number | null,
        calcium?: number | null,
      } | null,
      notes?: string | null,
      status?: Status | null,
      showInRecipe?: boolean | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null> | null,
    nextToken?: string | null,
  } | null,
};

export type GetAlertQueryVariables = {
  id: string,
};

export type GetAlertQuery = {
  getAlert?: {
    __typename: "Alert",
    id: string,
    content?: string | null,
    action?: Action | null,
    isRead?: boolean | null,
    createdAt: string,
    updatedAt: string,
    alertUserId: string,
    user?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
  } | null,
};

export type ListAlertsQueryVariables = {
  filter?: ModelAlertFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAlertsQuery = {
  listAlerts?: {
    __typename: "ModelAlertConnection",
    items: Array<{
      __typename: "Alert",
      id: string,
      content?: string | null,
      action?: Action | null,
      isRead?: boolean | null,
      createdAt: string,
      updatedAt: string,
      alertUserId: string,
      user?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?: {
    __typename: "Client",
    id: string,
    userId: string,
    specialistId: string,
    user: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    },
    specialist: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    contacts?: {
      __typename: "ModelContactConnection",
      items: Array<{
        __typename: "Contact",
        id: string,
        contactInfoId: string,
        clientId: string,
        type?: ContactType | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        client?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    conversations?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    clientNotes?: {
      __typename: "ModelClientNoteConnection",
      items: Array<{
        __typename: "ClientNote",
        id: string,
        clientId: string,
        title: string,
        createdAt?: string | null,
        flag?: string | null,
        goals?: string | null,
        focusAreas?: string | null,
        goalsFocusAreas?: string | null,
        progressChallenges?: string | null,
        progress?: string | null,
        challenges?: string | null,
        isOnApp?: boolean | null,
        engagement?: string | null,
        engagementStatus?: string | null,
        clinical?: string | null,
        symptoms?: string | null,
        anthropometry?: string | null,
        dietHistory?: string | null,
        training?: string | null,
        results?: string | null,
        social?: string | null,
        energyRequirements?: string | null,
        supplementsMedication?: string | null,
        other?: string | null,
        futureMe?: string | null,
        actionNeeded?: string | null,
        comments?: string | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?: {
    __typename: "ModelClientConnection",
    items: Array<{
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetConversationQueryVariables = {
  id: string,
};

export type GetConversationQuery = {
  getConversation?: {
    __typename: "Conversation",
    id: string,
    specialistClientId?: string | null,
    clientId?: string | null,
    specialistId?: string | null,
    title?: string | null,
    client?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    specialistClient?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    specialist?: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    members?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConversationsQueryVariables = {
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConversationsQuery = {
  listConversations?: {
    __typename: "ModelConversationConnection",
    items: Array<{
      __typename: "Conversation",
      id: string,
      specialistClientId?: string | null,
      clientId?: string | null,
      specialistId?: string | null,
      title?: string | null,
      client?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      specialistClient?: {
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      specialist?: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      members?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetCouponQueryVariables = {
  id: string,
};

export type GetCouponQuery = {
  getCoupon?: {
    __typename: "Coupon",
    id: string,
    coupon?: string | null,
    name?: string | null,
    specialistIds?: Array<string | null> | null,
    hasDiscount?: boolean | null,
    teamId?: string | null,
    percent_off?: string | null,
    max_redemptions?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCouponsQueryVariables = {
  filter?: ModelCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCouponsQuery = {
  listCoupons?: {
    __typename: "ModelCouponConnection",
    items: Array<{
      __typename: "Coupon",
      id: string,
      coupon?: string | null,
      name?: string | null,
      specialistIds?: Array<string | null> | null,
      hasDiscount?: boolean | null,
      teamId?: string | null,
      percent_off?: string | null,
      max_redemptions?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetHabitsQueryVariables = {
  id: string,
};

export type GetHabitsQuery = {
  getHabits?: {
    __typename: "Habits",
    id: string,
    identityGoal?: string | null,
    weeklyReflectionDay?: Day | null,
    log?: Array<{
      __typename: "HabitLogEntry",
      content: string,
      type: string,
      createdAt: string,
    } | null> | null,
    behaviours?: Array<{
      __typename: "Behaviour",
      id?: string | null,
      behaviour?: string | null,
      status?: string | null,
      colour?: string | null,
    } | null> | null,
    actions?: Array<{
      __typename: "ActionTask",
      id?: string | null,
      description?: string | null,
      status?: string | null,
      behaviour?: string | null,
      behaviourId?: string | null,
      notifyTimestamp?: string | null,
    } | null> | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type ListHabitssQueryVariables = {
  filter?: ModelHabitsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHabitssQuery = {
  listHabitss?: {
    __typename: "ModelHabitsConnection",
    items: Array<{
      __typename: "Habits",
      id: string,
      identityGoal?: string | null,
      weeklyReflectionDay?: Day | null,
      log?: Array<{
        __typename: "HabitLogEntry",
        content: string,
        type: string,
        createdAt: string,
      } | null> | null,
      behaviours?: Array<{
        __typename: "Behaviour",
        id?: string | null,
        behaviour?: string | null,
        status?: string | null,
        colour?: string | null,
      } | null> | null,
      actions?: Array<{
        __typename: "ActionTask",
        id?: string | null,
        description?: string | null,
        status?: string | null,
        behaviour?: string | null,
        behaviourId?: string | null,
        notifyTimestamp?: string | null,
      } | null> | null,
      status?: Status | null,
      userId?: string | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetInvitationQueryVariables = {
  id: string,
};

export type GetInvitationQuery = {
  getInvitation?: {
    __typename: "Invitation",
    id: string,
    clientId: string,
    invitedUserId?: string | null,
    email: string,
    contactType?: ContactType | null,
    firstName?: string | null,
    lastName?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInvitationsQueryVariables = {
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvitationsQuery = {
  listInvitations?: {
    __typename: "ModelInvitationConnection",
    items: Array<{
      __typename: "Invitation",
      id: string,
      clientId: string,
      invitedUserId?: string | null,
      email: string,
      contactType?: ContactType | null,
      firstName?: string | null,
      lastName?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetMealQueryVariables = {
  id: string,
};

export type GetMealQuery = {
  getMeal?: {
    __typename: "Meal",
    id: string,
    author: string,
    source?: {
      __typename: "Source",
      name?: string | null,
      avatar?: string | null,
      url?: string | null,
    } | null,
    title: string,
    image?: string | null,
    description?: string | null,
    mealTypes?: Array<string | null> | null,
    dietary?: Array<string | null> | null,
    allergens?: Array<string | null> | null,
    cuisines?: Array<string | null> | null,
    tags?: Array<string | null> | null,
    cookingMethods?: Array<string | null> | null,
    cookingSkill?: number | null,
    ingredients?: {
      __typename: "ModelIngredientConnection",
      items: Array<{
        __typename: "Ingredient",
        id: string,
        mealId: string,
        foodId?: string | null,
        food?: {
          __typename: "Food",
          id: string,
          item?: string | null,
          measurementUnits?: Array<string | null> | null,
          unit?: string | null,
          aisle?: string | null,
          allergens?: Array<string | null> | null,
          density?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        item?: string | null,
        qty?: number | null,
        unit?: string | null,
        isOptional?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    serves?: number | null,
    instructions?: Array<{
      __typename: "Instruction",
      step: number,
      direction: string,
    } | null> | null,
    times?: {
      __typename: "Times",
      total?: number | null,
      prep?: number | null,
      cook?: number | null,
    } | null,
    nutrition?: {
      __typename: "NutritionBreakdown",
      kilojoules?: number | null,
      carbohydrates?: number | null,
      fat?: number | null,
      protein?: number | null,
      fibre?: number | null,
      iron?: number | null,
      calcium?: number | null,
    } | null,
    notes?: string | null,
    status?: Status | null,
    showInRecipe?: boolean | null,
    updatedAt?: string | null,
    createdAt: string,
  } | null,
};

export type ListMealsQueryVariables = {
  filter?: ModelMealFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMealsQuery = {
  listMeals?: {
    __typename: "ModelMealConnection",
    items: Array<{
      __typename: "Meal",
      id: string,
      author: string,
      source?: {
        __typename: "Source",
        name?: string | null,
        avatar?: string | null,
        url?: string | null,
      } | null,
      title: string,
      image?: string | null,
      description?: string | null,
      mealTypes?: Array<string | null> | null,
      dietary?: Array<string | null> | null,
      allergens?: Array<string | null> | null,
      cuisines?: Array<string | null> | null,
      tags?: Array<string | null> | null,
      cookingMethods?: Array<string | null> | null,
      cookingSkill?: number | null,
      ingredients?: {
        __typename: "ModelIngredientConnection",
        items: Array<{
          __typename: "Ingredient",
          id: string,
          mealId: string,
          foodId?: string | null,
          item?: string | null,
          qty?: number | null,
          unit?: string | null,
          isOptional?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      serves?: number | null,
      instructions?: Array<{
        __typename: "Instruction",
        step: number,
        direction: string,
      } | null> | null,
      times?: {
        __typename: "Times",
        total?: number | null,
        prep?: number | null,
        cook?: number | null,
      } | null,
      nutrition?: {
        __typename: "NutritionBreakdown",
        kilojoules?: number | null,
        carbohydrates?: number | null,
        fat?: number | null,
        protein?: number | null,
        fibre?: number | null,
        iron?: number | null,
        calcium?: number | null,
      } | null,
      notes?: string | null,
      status?: Status | null,
      showInRecipe?: boolean | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetFoodQueryVariables = {
  id: string,
};

export type GetFoodQuery = {
  getFood?: {
    __typename: "Food",
    id: string,
    item?: string | null,
    measurementUnits?: Array<string | null> | null,
    unit?: string | null,
    aisle?: string | null,
    allergens?: Array<string | null> | null,
    density?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFoodsQueryVariables = {
  filter?: ModelFoodFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFoodsQuery = {
  listFoods?: {
    __typename: "ModelFoodConnection",
    items: Array<{
      __typename: "Food",
      id: string,
      item?: string | null,
      measurementUnits?: Array<string | null> | null,
      unit?: string | null,
      aisle?: string | null,
      allergens?: Array<string | null> | null,
      density?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetMealPlanSetQueryVariables = {
  id: string,
};

export type GetMealPlanSetQuery = {
  getMealPlanSet?: {
    __typename: "MealPlanSet",
    id: string,
    type?: string | null,
    plans?: {
      __typename: "ModelMealPlanConnection",
      items: Array<{
        __typename: "MealPlan",
        id: string,
        type?: string | null,
        kilojoules?: number | null,
        mealGroups?: Array<{
          __typename: "MealGroup",
          type?: string | null,
          count?: number | null,
        } | null> | null,
        meals?: {
          __typename: "ModelMealPlanMealConnection",
          nextToken?: string | null,
        } | null,
        startingAt?: string | null,
        status?: Status | null,
        userId?: string | null,
        mealPlanSetId?: string | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMealPlanSetsQueryVariables = {
  filter?: ModelMealPlanSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMealPlanSetsQuery = {
  listMealPlanSets?: {
    __typename: "ModelMealPlanSetConnection",
    items: Array<{
      __typename: "MealPlanSet",
      id: string,
      type?: string | null,
      plans?: {
        __typename: "ModelMealPlanConnection",
        items: Array<{
          __typename: "MealPlan",
          id: string,
          type?: string | null,
          kilojoules?: number | null,
          startingAt?: string | null,
          status?: Status | null,
          userId?: string | null,
          mealPlanSetId?: string | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      startingAt?: string | null,
      status?: Status | null,
      userId?: string | null,
      editors?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetMealPlanQueryVariables = {
  id: string,
};

export type GetMealPlanQuery = {
  getMealPlan?: {
    __typename: "MealPlan",
    id: string,
    type?: string | null,
    kilojoules?: number | null,
    mealGroups?: Array<{
      __typename: "MealGroup",
      type?: string | null,
      count?: number | null,
    } | null> | null,
    meals?: {
      __typename: "ModelMealPlanMealConnection",
      items: Array<{
        __typename: "MealPlanMeal",
        id: string,
        mealPlanId?: string | null,
        mealId?: string | null,
        meal: {
          __typename: "Meal",
          id: string,
          author: string,
          title: string,
          image?: string | null,
          description?: string | null,
          mealTypes?: Array<string | null> | null,
          dietary?: Array<string | null> | null,
          allergens?: Array<string | null> | null,
          cuisines?: Array<string | null> | null,
          tags?: Array<string | null> | null,
          cookingMethods?: Array<string | null> | null,
          cookingSkill?: number | null,
          serves?: number | null,
          notes?: string | null,
          status?: Status | null,
          showInRecipe?: boolean | null,
          updatedAt?: string | null,
          createdAt: string,
        },
        type?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    mealPlanSetId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMealPlansQueryVariables = {
  filter?: ModelMealPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMealPlansQuery = {
  listMealPlans?: {
    __typename: "ModelMealPlanConnection",
    items: Array<{
      __typename: "MealPlan",
      id: string,
      type?: string | null,
      kilojoules?: number | null,
      mealGroups?: Array<{
        __typename: "MealGroup",
        type?: string | null,
        count?: number | null,
      } | null> | null,
      meals?: {
        __typename: "ModelMealPlanMealConnection",
        items: Array<{
          __typename: "MealPlanMeal",
          id: string,
          mealPlanId?: string | null,
          mealId?: string | null,
          type?: string | null,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      startingAt?: string | null,
      status?: Status | null,
      userId?: string | null,
      mealPlanSetId?: string | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetRecipeQueryVariables = {
  id: string,
};

export type GetRecipeQuery = {
  getRecipe?: {
    __typename: "Recipe",
    id: string,
    title: string,
    image?: string | null,
    recipeLink?: string | null,
    tags?: Array<string | null> | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type ListRecipesQueryVariables = {
  filter?: ModelRecipeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecipesQuery = {
  listRecipes?: {
    __typename: "ModelRecipeConnection",
    items: Array<{
      __typename: "Recipe",
      id: string,
      title: string,
      image?: string | null,
      recipeLink?: string | null,
      tags?: Array<string | null> | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetSpecialistProfileQueryVariables = {
  id: string,
};

export type GetSpecialistProfileQuery = {
  getSpecialistProfile?: {
    __typename: "SpecialistProfile",
    id: string,
    displayName?: string | null,
    businessName?: string | null,
    qualifications?: string | null,
    clinicalSpecialities?: Array<string | null> | null,
    sportSpecialities?: Array<string | null> | null,
    bio?: string | null,
    trivia?: Array<string | null> | null,
    providerNumber?: string | null,
    businessIdNumber?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    image?: string | null,
    video?: string | null,
    status?: string | null,
    isAvailable?: boolean | null,
    clientLimit?: number | null,
    isTeamsAvailable?: boolean | null,
    socialAccounts?: Array<{
      __typename: "SocialAccount",
      network?: string | null,
      account?: string | null,
    } | null> | null,
    referralLink?: string | null,
    integrations?: Array<{
      __typename: "Integration",
      name?: string | null,
      attributes?: string | null,
    } | null> | null,
    ownerId: string,
    owner?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    clients?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    myClients?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSpecialistProfilesQueryVariables = {
  filter?: ModelSpecialistProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpecialistProfilesQuery = {
  listSpecialistProfiles?: {
    __typename: "ModelSpecialistProfileConnection",
    items: Array<{
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetTeamQueryVariables = {
  id: string,
};

export type GetTeamQuery = {
  getTeam?: {
    __typename: "Team",
    id: string,
    name?: string | null,
    logo?: string | null,
    coupons?: Array<string | null> | null,
    users?: Array<string | null> | null,
    specialists?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTeamsQueryVariables = {
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsQuery = {
  listTeams?: {
    __typename: "ModelTeamConnection",
    items: Array<{
      __typename: "Team",
      id: string,
      name?: string | null,
      logo?: string | null,
      coupons?: Array<string | null> | null,
      users?: Array<string | null> | null,
      specialists?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?: {
    __typename: "User",
    id: string,
    authId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profile?: {
      __typename: "Profile",
      sex?: string | null,
      pronouns?: string | null,
      ageBracket?: string | null,
    } | null,
    avatar?: string | null,
    phone?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    bodyType?: string | null,
    height?: string | null,
    weight?: number | null,
    bodyFat?: number | null,
    activityFactor?: number | null,
    nutritionNotes?: string | null,
    joinReason?: string | null,
    trainingCalendarLink?: string | null,
    guessedLocation?: string | null,
    journal?: Array<{
      __typename: "Record",
      weight?: string | null,
      waist?: string | null,
      appetiteRating?: number | null,
      energyRating?: number | null,
      sleepRating?: number | null,
      motivationRating?: number | null,
      wellnessRating?: number | null,
      stressRating?: number | null,
      nutritionRating?: number | null,
      createdAt: string,
    } | null> | null,
    events?: Array<{
      __typename: "Event",
      name: string,
      details?: string | null,
      date?: string | null,
      location?: string | null,
      goal?: string | null,
      preEventFoodRituals?: string | null,
      nutritionProducts?: string | null,
      nutritionLogistics?: string | null,
      issues?: string | null,
      leadupEvents?: string | null,
      accomodation?: string | null,
      leadupEventDate?: string | null,
      leadupEventDetails?: string | null,
      createdAt: string,
    } | null> | null,
    sports?: Array<{
      __typename: "Sport",
      name?: string | null,
      level?: string | null,
      comments?: string | null,
    } | null> | null,
    goals?: Array<{
      __typename: "Goal",
      description: string,
      comments?: string | null,
      progress?: Array<{
        __typename: "Progress",
        rating: number,
        createdAt: string,
      } | null> | null,
      targetDate?: string | null,
      createdAt: string,
    } | null> | null,
    teams?: Array<{
      __typename: "TeamGroup",
      name?: string | null,
      joinedAt?: string | null,
    } | null> | null,
    foodDiary?: Array<{
      __typename: "DailyLog",
      date?: string | null,
      day?: string | null,
      items?: Array<{
        __typename: "LogItem",
        time?: string | null,
        detail?: string | null,
        comments?: string | null,
      } | null> | null,
    } | null> | null,
    menuScheduleDay?: Day | null,
    nutritionInformation?: {
      __typename: "Nutrition",
      allergies?: Array<string | null> | null,
      supplements?: string | null,
      dislikes?: string | null,
      comments?: string | null,
      preferences?: string | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      dietaryRequirements?: Array<string | null> | null,
    } | null,
    nutritionAssessments?: Array<{
      __typename: "NutritionAssessment",
      requestDate: string,
      responseStartDate?: string | null,
      responseCompleteDate?: string | null,
      responses?: Array<{
        __typename: "Response",
        question?: string | null,
        answer?: string | null,
        comments?: string | null,
        score?: number | null,
      } | null> | null,
      score?: number | null,
    } | null> | null,
    lifestyle?: {
      __typename: "Lifestyle",
      cookingSkills?: string | null,
      cookingMethods?: Array<string | null> | null,
      easyNights?: Array<Day | null> | null,
      homeSituation?: string | null,
      peopleToFeed?: string | null,
      workDays?: Array<Day | null> | null,
      nightsEatingOut?: Array<Day | null> | null,
      workShifts?: string | null,
      breakfastOnTheRun?: Array<Day | null> | null,
      occupation?: string | null,
      preferences?: string | null,
    } | null,
    healthInformation?: {
      __typename: "HealthInformation",
      medicalConditions?: string | null,
      medication?: string | null,
      isMenstruating?: boolean | null,
      regularPeriod?: boolean | null,
      periodStoppedOver3Months?: string | null,
    } | null,
    membership?: {
      __typename: "Membership",
      id?: string | null,
      amount?: string | null,
      plan?: string | null,
      discount?: string | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      cancelAtPeriodEnd?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    permission?: {
      __typename: "Permission",
      hasMeals?: boolean | null,
      hasHabits?: boolean | null,
    } | null,
    priority?: {
      __typename: "Priority",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null,
    priorityConfidence?: Array<{
      __typename: "PriorityConfidence",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null> | null,
    userRole?: Role | null,
    lastActiveAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    paymentMethod?: {
      __typename: "PaymentMethod",
      id?: string | null,
      type?: string | null,
      brand?: string | null,
      exp_month?: number | null,
      exp_year?: number | null,
      last4?: string | null,
    } | null,
    status?: Status | null,
    devices?: {
      __typename: "ModelDeviceConnection",
      items: Array<{
        __typename: "Device",
        id: string,
        token: string,
        platform: string,
        bundleId: string,
        deviceArn?: string | null,
        deviceUserId?: string | null,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    specialists?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    alerts?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    notifications?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    contactInfoId?: string | null,
    contactInfo?: {
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientProfile?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?: {
    __typename: "ModelUserConnection",
    items: Array<{
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type AlertByActionQueryVariables = {
  action?: Action | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAlertFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AlertByActionQuery = {
  alertByAction?: {
    __typename: "ModelAlertConnection",
    items: Array<{
      __typename: "Alert",
      id: string,
      content?: string | null,
      action?: Action | null,
      isRead?: boolean | null,
      createdAt: string,
      updatedAt: string,
      alertUserId: string,
      user?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type AlertByUserByActionQueryVariables = {
  alertUserId?: string | null,
  actionCreatedAt?: ModelAlertByUserByActionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAlertFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AlertByUserByActionQuery = {
  alertByUserByAction?: {
    __typename: "ModelAlertConnection",
    items: Array<{
      __typename: "Alert",
      id: string,
      content?: string | null,
      action?: Action | null,
      isRead?: boolean | null,
      createdAt: string,
      updatedAt: string,
      alertUserId: string,
      user?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          email?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type ClientsBySpecialistQueryVariables = {
  specialistId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClientsBySpecialistQuery = {
  clientsBySpecialist?: {
    __typename: "ModelClientConnection",
    items: Array<{
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type ConversationBySpecialistIdQueryVariables = {
  specialistId?: string | null,
  clientId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConversationBySpecialistIdQuery = {
  conversationBySpecialistId?: {
    __typename: "ModelConversationConnection",
    items: Array<{
      __typename: "Conversation",
      id: string,
      specialistClientId?: string | null,
      clientId?: string | null,
      specialistId?: string | null,
      title?: string | null,
      client?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      specialistClient?: {
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      specialist?: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      members?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type SpecialistClientsQueryVariables = {
  specialistId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpecialistClientsQuery = {
  specialistClients?: {
    __typename: "ModelConversationConnection",
    items: Array<{
      __typename: "Conversation",
      id: string,
      specialistClientId?: string | null,
      clientId?: string | null,
      specialistId?: string | null,
      title?: string | null,
      client?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      specialistClient?: {
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      specialist?: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      members?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type DishByTitleQueryVariables = {
  title?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDishFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DishByTitleQuery = {
  dishByTitle?: {
    __typename: "ModelDishConnection",
    items: Array<{
      __typename: "Dish",
      id: string,
      title: string,
      type?: string | null,
      ingredients?: Array<{
        __typename: "Element",
        item?: string | null,
        qty?: string | null,
        unit?: string | null,
        extraDetail?: string | null,
        isOptional?: boolean | null,
      } | null> | null,
      image?: string | null,
      recipeLink?: string | null,
      tags?: Array<string | null> | null,
      createdAt?: string | null,
      updatedAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetUserHabitsQueryVariables = {
  userId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHabitsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserHabitsQuery = {
  getUserHabits?: {
    __typename: "ModelHabitsConnection",
    items: Array<{
      __typename: "Habits",
      id: string,
      identityGoal?: string | null,
      weeklyReflectionDay?: Day | null,
      log?: Array<{
        __typename: "HabitLogEntry",
        content: string,
        type: string,
        createdAt: string,
      } | null> | null,
      behaviours?: Array<{
        __typename: "Behaviour",
        id?: string | null,
        behaviour?: string | null,
        status?: string | null,
        colour?: string | null,
      } | null> | null,
      actions?: Array<{
        __typename: "ActionTask",
        id?: string | null,
        description?: string | null,
        status?: string | null,
        behaviour?: string | null,
        behaviourId?: string | null,
        notifyTimestamp?: string | null,
      } | null> | null,
      status?: Status | null,
      userId?: string | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type ByStatusQueryVariables = {
  status?: Status | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHabitsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByStatusQuery = {
  byStatus?: {
    __typename: "ModelHabitsConnection",
    items: Array<{
      __typename: "Habits",
      id: string,
      identityGoal?: string | null,
      weeklyReflectionDay?: Day | null,
      log?: Array<{
        __typename: "HabitLogEntry",
        content: string,
        type: string,
        createdAt: string,
      } | null> | null,
      behaviours?: Array<{
        __typename: "Behaviour",
        id?: string | null,
        behaviour?: string | null,
        status?: string | null,
        colour?: string | null,
      } | null> | null,
      actions?: Array<{
        __typename: "ActionTask",
        id?: string | null,
        description?: string | null,
        status?: string | null,
        behaviour?: string | null,
        behaviourId?: string | null,
        notifyTimestamp?: string | null,
      } | null> | null,
      status?: Status | null,
      userId?: string | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type HabitsByUserByStatusQueryVariables = {
  userId?: string | null,
  statusCreatedAt?: ModelHabitsByUserByStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHabitsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HabitsByUserByStatusQuery = {
  habitsByUserByStatus?: {
    __typename: "ModelHabitsConnection",
    items: Array<{
      __typename: "Habits",
      id: string,
      identityGoal?: string | null,
      weeklyReflectionDay?: Day | null,
      log?: Array<{
        __typename: "HabitLogEntry",
        content: string,
        type: string,
        createdAt: string,
      } | null> | null,
      behaviours?: Array<{
        __typename: "Behaviour",
        id?: string | null,
        behaviour?: string | null,
        status?: string | null,
        colour?: string | null,
      } | null> | null,
      actions?: Array<{
        __typename: "ActionTask",
        id?: string | null,
        description?: string | null,
        status?: string | null,
        behaviour?: string | null,
        behaviourId?: string | null,
        notifyTimestamp?: string | null,
      } | null> | null,
      status?: Status | null,
      userId?: string | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      updatedAt?: string | null,
      createdAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type InvitationsByClientQueryVariables = {
  clientId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvitationsByClientQuery = {
  invitationsByClient?: {
    __typename: "ModelInvitationConnection",
    items: Array<{
      __typename: "Invitation",
      id: string,
      clientId: string,
      invitedUserId?: string | null,
      email: string,
      contactType?: ContactType | null,
      firstName?: string | null,
      lastName?: string | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type MealByStatusTitleQueryVariables = {
  status?: Status | null,
  title?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMealFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MealByStatusTitleQuery = {
  mealByStatusTitle?: {
    __typename: "ModelMealConnection",
    items: Array<{
      __typename: "Meal",
      id: string,
      author: string,
      source?: {
        __typename: "Source",
        name?: string | null,
        avatar?: string | null,
        url?: string | null,
      } | null,
      title: string,
      image?: string | null,
      description?: string | null,
      mealTypes?: Array<string | null> | null,
      dietary?: Array<string | null> | null,
      allergens?: Array<string | null> | null,
      cuisines?: Array<string | null> | null,
      tags?: Array<string | null> | null,
      cookingMethods?: Array<string | null> | null,
      cookingSkill?: number | null,
      ingredients?: {
        __typename: "ModelIngredientConnection",
        items: Array<{
          __typename: "Ingredient",
          id: string,
          mealId: string,
          foodId?: string | null,
          item?: string | null,
          qty?: number | null,
          unit?: string | null,
          isOptional?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      serves?: number | null,
      instructions?: Array<{
        __typename: "Instruction",
        step: number,
        direction: string,
      } | null> | null,
      times?: {
        __typename: "Times",
        total?: number | null,
        prep?: number | null,
        cook?: number | null,
      } | null,
      nutrition?: {
        __typename: "NutritionBreakdown",
        kilojoules?: number | null,
        carbohydrates?: number | null,
        fat?: number | null,
        protein?: number | null,
        fibre?: number | null,
        iron?: number | null,
        calcium?: number | null,
      } | null,
      notes?: string | null,
      status?: Status | null,
      showInRecipe?: boolean | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type MealByStatusUpdatedAtQueryVariables = {
  status?: Status | null,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMealFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MealByStatusUpdatedAtQuery = {
  mealByStatusUpdatedAt?: {
    __typename: "ModelMealConnection",
    items: Array<{
      __typename: "Meal",
      id: string,
      author: string,
      source?: {
        __typename: "Source",
        name?: string | null,
        avatar?: string | null,
        url?: string | null,
      } | null,
      title: string,
      image?: string | null,
      description?: string | null,
      mealTypes?: Array<string | null> | null,
      dietary?: Array<string | null> | null,
      allergens?: Array<string | null> | null,
      cuisines?: Array<string | null> | null,
      tags?: Array<string | null> | null,
      cookingMethods?: Array<string | null> | null,
      cookingSkill?: number | null,
      ingredients?: {
        __typename: "ModelIngredientConnection",
        items: Array<{
          __typename: "Ingredient",
          id: string,
          mealId: string,
          foodId?: string | null,
          item?: string | null,
          qty?: number | null,
          unit?: string | null,
          isOptional?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      serves?: number | null,
      instructions?: Array<{
        __typename: "Instruction",
        step: number,
        direction: string,
      } | null> | null,
      times?: {
        __typename: "Times",
        total?: number | null,
        prep?: number | null,
        cook?: number | null,
      } | null,
      nutrition?: {
        __typename: "NutritionBreakdown",
        kilojoules?: number | null,
        carbohydrates?: number | null,
        fat?: number | null,
        protein?: number | null,
        fibre?: number | null,
        iron?: number | null,
        calcium?: number | null,
      } | null,
      notes?: string | null,
      status?: Status | null,
      showInRecipe?: boolean | null,
      updatedAt?: string | null,
      createdAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type MealPlanSetByUserQueryVariables = {
  userId?: string | null,
  startingAtStatus?: ModelMealPlanSetByUserCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMealPlanSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MealPlanSetByUserQuery = {
  mealPlanSetByUser?: {
    __typename: "ModelMealPlanSetConnection",
    items: Array<{
      __typename: "MealPlanSet",
      id: string,
      type?: string | null,
      plans?: {
        __typename: "ModelMealPlanConnection",
        items: Array<{
          __typename: "MealPlan",
          id: string,
          type?: string | null,
          kilojoules?: number | null,
          startingAt?: string | null,
          status?: Status | null,
          userId?: string | null,
          mealPlanSetId?: string | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      startingAt?: string | null,
      status?: Status | null,
      userId?: string | null,
      editors?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type MealPlanByUserQueryVariables = {
  userId?: string | null,
  startingAtStatus?: ModelMealPlanByUserCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMealPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MealPlanByUserQuery = {
  mealPlanByUser?: {
    __typename: "ModelMealPlanConnection",
    items: Array<{
      __typename: "MealPlan",
      id: string,
      type?: string | null,
      kilojoules?: number | null,
      mealGroups?: Array<{
        __typename: "MealGroup",
        type?: string | null,
        count?: number | null,
      } | null> | null,
      meals?: {
        __typename: "ModelMealPlanMealConnection",
        items: Array<{
          __typename: "MealPlanMeal",
          id: string,
          mealPlanId?: string | null,
          mealId?: string | null,
          type?: string | null,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      startingAt?: string | null,
      status?: Status | null,
      userId?: string | null,
      mealPlanSetId?: string | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type MealPlanByUserByStatusQueryVariables = {
  userId?: string | null,
  statusStartingAt?: ModelMealPlanByUserByStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMealPlanFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MealPlanByUserByStatusQuery = {
  mealPlanByUserByStatus?: {
    __typename: "ModelMealPlanConnection",
    items: Array<{
      __typename: "MealPlan",
      id: string,
      type?: string | null,
      kilojoules?: number | null,
      mealGroups?: Array<{
        __typename: "MealGroup",
        type?: string | null,
        count?: number | null,
      } | null> | null,
      meals?: {
        __typename: "ModelMealPlanMealConnection",
        items: Array<{
          __typename: "MealPlanMeal",
          id: string,
          mealPlanId?: string | null,
          mealId?: string | null,
          type?: string | null,
          note?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      startingAt?: string | null,
      status?: Status | null,
      userId?: string | null,
      mealPlanSetId?: string | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type SpecialistByOwnerQueryVariables = {
  ownerId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpecialistProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpecialistByOwnerQuery = {
  specialistByOwner?: {
    __typename: "ModelSpecialistProfileConnection",
    items: Array<{
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type SpecialistsByStatusQueryVariables = {
  status?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpecialistProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpecialistsByStatusQuery = {
  specialistsByStatus?: {
    __typename: "ModelSpecialistProfileConnection",
    items: Array<{
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type UserByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByEmailQuery = {
  userByEmail?: {
    __typename: "ModelUserConnection",
    items: Array<{
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type UserByStatusQueryVariables = {
  status?: Status | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByStatusQuery = {
  userByStatus?: {
    __typename: "ModelUserConnection",
    items: Array<{
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type UsersByRoleQueryVariables = {
  userRole?: Role | null,
  createdAtStatus?: ModelUserByRoleCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByRoleQuery = {
  usersByRole?: {
    __typename: "ModelUserConnection",
    items: Array<{
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type UsersByNameQueryVariables = {
  firstName?: string | null,
  lastNameCreatedAtStatus?: ModelUserByNameCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByNameQuery = {
  usersByName?: {
    __typename: "ModelUserConnection",
    items: Array<{
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type ContactInfoByUserQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContactInfoByUserQuery = {
  contactInfoByUser?: {
    __typename: "ModelContactInfoConnection",
    items: Array<{
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type GetUserTokenQueryVariables = {
  id: string,
};

export type GetUserTokenQuery = {
  getUserToken?: {
    __typename: "UserToken",
    id: string,
    createdAt: string,
    userId: string,
    token: string,
    isUsed: boolean,
    updatedAt: string,
  } | null,
};

export type ListUserTokensQueryVariables = {
  filter?: ModelUserTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserTokensQuery = {
  listUserTokens?: {
    __typename: "ModelUserTokenConnection",
    items: Array<{
      __typename: "UserToken",
      id: string,
      createdAt: string,
      userId: string,
      token: string,
      isUsed: boolean,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type UserTokenByTokenQueryVariables = {
  token?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserTokenFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserTokenByTokenQuery = {
  userTokenByToken?: {
    __typename: "ModelUserTokenConnection",
    items: Array<{
      __typename: "UserToken",
      id: string,
      createdAt: string,
      userId: string,
      token: string,
      isUsed: boolean,
      updatedAt: string,
    } | null>,
    nextToken?: string | null,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  messageConversationId: string,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?: {
    __typename: "Message",
    id: string,
    content?: string | null,
    createdAt?: string | null,
    messageAuthorId?: string | null,
    readBy?: Array<string | null> | null,
    author?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    messageConversationId: string,
    conversation?: {
      __typename: "Conversation",
      id: string,
      specialistClientId?: string | null,
      clientId?: string | null,
      specialistId?: string | null,
      title?: string | null,
      client?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      specialistClient?: {
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      specialist?: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      members?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  messageConversationId: string,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage?: {
    __typename: "Message",
    id: string,
    content?: string | null,
    createdAt?: string | null,
    messageAuthorId?: string | null,
    readBy?: Array<string | null> | null,
    author?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    messageConversationId: string,
    conversation?: {
      __typename: "Conversation",
      id: string,
      specialistClientId?: string | null,
      clientId?: string | null,
      specialistId?: string | null,
      title?: string | null,
      client?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      specialistClient?: {
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      specialist?: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      members?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  messageConversationId: string,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage?: {
    __typename: "Message",
    id: string,
    content?: string | null,
    createdAt?: string | null,
    messageAuthorId?: string | null,
    readBy?: Array<string | null> | null,
    author?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    messageConversationId: string,
    conversation?: {
      __typename: "Conversation",
      id: string,
      specialistClientId?: string | null,
      clientId?: string | null,
      specialistId?: string | null,
      title?: string | null,
      client?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      specialistClient?: {
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      specialist?: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      members?: Array<string | null> | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateConversationByClientSubscriptionVariables = {
  specialistClientId: string,
};

export type OnCreateConversationByClientSubscription = {
  onCreateConversationByClient?: {
    __typename: "Conversation",
    id: string,
    specialistClientId?: string | null,
    clientId?: string | null,
    specialistId?: string | null,
    title?: string | null,
    client?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    specialistClient?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    specialist?: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    members?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConversationByClientSubscriptionVariables = {
  specialistClientId: string,
};

export type OnUpdateConversationByClientSubscription = {
  onUpdateConversationByClient?: {
    __typename: "Conversation",
    id: string,
    specialistClientId?: string | null,
    clientId?: string | null,
    specialistId?: string | null,
    title?: string | null,
    client?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    specialistClient?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    specialist?: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    members?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConversationSubscriptionVariables = {
  id: string,
};

export type OnUpdateConversationSubscription = {
  onUpdateConversation?: {
    __typename: "Conversation",
    id: string,
    specialistClientId?: string | null,
    clientId?: string | null,
    specialistId?: string | null,
    title?: string | null,
    client?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    specialistClient?: {
      __typename: "Client",
      id: string,
      userId: string,
      specialistId: string,
      user: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      },
      specialist: {
        __typename: "SpecialistProfile",
        id: string,
        displayName?: string | null,
        businessName?: string | null,
        qualifications?: string | null,
        clinicalSpecialities?: Array<string | null> | null,
        sportSpecialities?: Array<string | null> | null,
        bio?: string | null,
        trivia?: Array<string | null> | null,
        providerNumber?: string | null,
        businessIdNumber?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        image?: string | null,
        video?: string | null,
        status?: string | null,
        isAvailable?: boolean | null,
        clientLimit?: number | null,
        isTeamsAvailable?: boolean | null,
        socialAccounts?: Array<{
          __typename: "SocialAccount",
          network?: string | null,
          account?: string | null,
        } | null> | null,
        referralLink?: string | null,
        integrations?: Array<{
          __typename: "Integration",
          name?: string | null,
          attributes?: string | null,
        } | null> | null,
        ownerId: string,
        owner?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        clients?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        myClients?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      conversations?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      status?: Status | null,
      servicingNote?: string | null,
      notes?: Array<{
        __typename: "Note",
        title: string,
        content: string,
        createdAt: string,
        authorId?: string | null,
      } | null> | null,
      clientNotes?: {
        __typename: "ModelClientNoteConnection",
        items: Array<{
          __typename: "ClientNote",
          id: string,
          clientId: string,
          title: string,
          createdAt?: string | null,
          flag?: string | null,
          goals?: string | null,
          focusAreas?: string | null,
          goalsFocusAreas?: string | null,
          progressChallenges?: string | null,
          progress?: string | null,
          challenges?: string | null,
          isOnApp?: boolean | null,
          engagement?: string | null,
          engagementStatus?: string | null,
          clinical?: string | null,
          symptoms?: string | null,
          anthropometry?: string | null,
          dietHistory?: string | null,
          training?: string | null,
          results?: string | null,
          social?: string | null,
          energyRequirements?: string | null,
          supplementsMedication?: string | null,
          other?: string | null,
          futureMe?: string | null,
          actionNeeded?: string | null,
          comments?: string | null,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      files?: Array<{
        __typename: "File",
        title: string,
        url: string,
        type?: string | null,
        createdAt: string,
      } | null> | null,
      settings?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    specialist?: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    members?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClientNoteByClientSubscriptionVariables = {
  clientId: string,
};

export type OnCreateClientNoteByClientSubscription = {
  onCreateClientNoteByClient?: {
    __typename: "ClientNote",
    id: string,
    clientId: string,
    title: string,
    createdAt?: string | null,
    flag?: string | null,
    goals?: string | null,
    focusAreas?: string | null,
    goalsFocusAreas?: string | null,
    progressChallenges?: string | null,
    progress?: string | null,
    challenges?: string | null,
    isOnApp?: boolean | null,
    engagement?: string | null,
    engagementStatus?: string | null,
    clinical?: string | null,
    symptoms?: string | null,
    anthropometry?: string | null,
    dietHistory?: string | null,
    training?: string | null,
    results?: string | null,
    social?: string | null,
    energyRequirements?: string | null,
    supplementsMedication?: string | null,
    other?: string | null,
    futureMe?: string | null,
    actionNeeded?: string | null,
    comments?: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateClientNoteByClientSubscriptionVariables = {
  clientId: string,
};

export type OnUpdateClientNoteByClientSubscription = {
  onUpdateClientNoteByClient?: {
    __typename: "ClientNote",
    id: string,
    clientId: string,
    title: string,
    createdAt?: string | null,
    flag?: string | null,
    goals?: string | null,
    focusAreas?: string | null,
    goalsFocusAreas?: string | null,
    progressChallenges?: string | null,
    progress?: string | null,
    challenges?: string | null,
    isOnApp?: boolean | null,
    engagement?: string | null,
    engagementStatus?: string | null,
    clinical?: string | null,
    symptoms?: string | null,
    anthropometry?: string | null,
    dietHistory?: string | null,
    training?: string | null,
    results?: string | null,
    social?: string | null,
    energyRequirements?: string | null,
    supplementsMedication?: string | null,
    other?: string | null,
    futureMe?: string | null,
    actionNeeded?: string | null,
    comments?: string | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAlertSubscriptionVariables = {
  alertUserId: string,
};

export type OnCreateAlertSubscription = {
  onCreateAlert?: {
    __typename: "Alert",
    id: string,
    content?: string | null,
    action?: Action | null,
    isRead?: boolean | null,
    createdAt: string,
    updatedAt: string,
    alertUserId: string,
    user?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  id: string,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?: {
    __typename: "User",
    id: string,
    authId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    profile?: {
      __typename: "Profile",
      sex?: string | null,
      pronouns?: string | null,
      ageBracket?: string | null,
    } | null,
    avatar?: string | null,
    phone?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    bodyType?: string | null,
    height?: string | null,
    weight?: number | null,
    bodyFat?: number | null,
    activityFactor?: number | null,
    nutritionNotes?: string | null,
    joinReason?: string | null,
    trainingCalendarLink?: string | null,
    guessedLocation?: string | null,
    journal?: Array<{
      __typename: "Record",
      weight?: string | null,
      waist?: string | null,
      appetiteRating?: number | null,
      energyRating?: number | null,
      sleepRating?: number | null,
      motivationRating?: number | null,
      wellnessRating?: number | null,
      stressRating?: number | null,
      nutritionRating?: number | null,
      createdAt: string,
    } | null> | null,
    events?: Array<{
      __typename: "Event",
      name: string,
      details?: string | null,
      date?: string | null,
      location?: string | null,
      goal?: string | null,
      preEventFoodRituals?: string | null,
      nutritionProducts?: string | null,
      nutritionLogistics?: string | null,
      issues?: string | null,
      leadupEvents?: string | null,
      accomodation?: string | null,
      leadupEventDate?: string | null,
      leadupEventDetails?: string | null,
      createdAt: string,
    } | null> | null,
    sports?: Array<{
      __typename: "Sport",
      name?: string | null,
      level?: string | null,
      comments?: string | null,
    } | null> | null,
    goals?: Array<{
      __typename: "Goal",
      description: string,
      comments?: string | null,
      progress?: Array<{
        __typename: "Progress",
        rating: number,
        createdAt: string,
      } | null> | null,
      targetDate?: string | null,
      createdAt: string,
    } | null> | null,
    teams?: Array<{
      __typename: "TeamGroup",
      name?: string | null,
      joinedAt?: string | null,
    } | null> | null,
    foodDiary?: Array<{
      __typename: "DailyLog",
      date?: string | null,
      day?: string | null,
      items?: Array<{
        __typename: "LogItem",
        time?: string | null,
        detail?: string | null,
        comments?: string | null,
      } | null> | null,
    } | null> | null,
    menuScheduleDay?: Day | null,
    nutritionInformation?: {
      __typename: "Nutrition",
      allergies?: Array<string | null> | null,
      supplements?: string | null,
      dislikes?: string | null,
      comments?: string | null,
      preferences?: string | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      dietaryRequirements?: Array<string | null> | null,
    } | null,
    nutritionAssessments?: Array<{
      __typename: "NutritionAssessment",
      requestDate: string,
      responseStartDate?: string | null,
      responseCompleteDate?: string | null,
      responses?: Array<{
        __typename: "Response",
        question?: string | null,
        answer?: string | null,
        comments?: string | null,
        score?: number | null,
      } | null> | null,
      score?: number | null,
    } | null> | null,
    lifestyle?: {
      __typename: "Lifestyle",
      cookingSkills?: string | null,
      cookingMethods?: Array<string | null> | null,
      easyNights?: Array<Day | null> | null,
      homeSituation?: string | null,
      peopleToFeed?: string | null,
      workDays?: Array<Day | null> | null,
      nightsEatingOut?: Array<Day | null> | null,
      workShifts?: string | null,
      breakfastOnTheRun?: Array<Day | null> | null,
      occupation?: string | null,
      preferences?: string | null,
    } | null,
    healthInformation?: {
      __typename: "HealthInformation",
      medicalConditions?: string | null,
      medication?: string | null,
      isMenstruating?: boolean | null,
      regularPeriod?: boolean | null,
      periodStoppedOver3Months?: string | null,
    } | null,
    membership?: {
      __typename: "Membership",
      id?: string | null,
      amount?: string | null,
      plan?: string | null,
      discount?: string | null,
      createdAt?: string | null,
      expiresAt?: string | null,
      cancelAtPeriodEnd?: boolean | null,
    } | null,
    stripeCustomerId?: string | null,
    permission?: {
      __typename: "Permission",
      hasMeals?: boolean | null,
      hasHabits?: boolean | null,
    } | null,
    priority?: {
      __typename: "Priority",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null,
    priorityConfidence?: Array<{
      __typename: "PriorityConfidence",
      bonking?: number | null,
      energyLevels?: number | null,
      gutUpset?: number | null,
      manageCramping?: number | null,
      muscleMass?: number | null,
      overallHealth?: number | null,
      performance?: number | null,
      raceDayNutrition?: number | null,
      trainingAdaptations?: number | null,
      weightLoss?: number | null,
      createdAt: string,
    } | null> | null,
    userRole?: Role | null,
    lastActiveAt?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    paymentMethod?: {
      __typename: "PaymentMethod",
      id?: string | null,
      type?: string | null,
      brand?: string | null,
      exp_month?: number | null,
      exp_year?: number | null,
      last4?: string | null,
    } | null,
    status?: Status | null,
    devices?: {
      __typename: "ModelDeviceConnection",
      items: Array<{
        __typename: "Device",
        id: string,
        token: string,
        platform: string,
        bundleId: string,
        deviceArn?: string | null,
        deviceUserId?: string | null,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    messages?: {
      __typename: "ModelMessageConnection",
      items: Array<{
        __typename: "Message",
        id: string,
        content?: string | null,
        createdAt?: string | null,
        messageAuthorId?: string | null,
        readBy?: Array<string | null> | null,
        author?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        messageConversationId: string,
        conversation?: {
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    specialists?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    alerts?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    notifications?: {
      __typename: "ModelAlertConnection",
      items: Array<{
        __typename: "Alert",
        id: string,
        content?: string | null,
        action?: Action | null,
        isRead?: boolean | null,
        createdAt: string,
        updatedAt: string,
        alertUserId: string,
        user?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
      } | null>,
      nextToken?: string | null,
    } | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    contactInfoId?: string | null,
    contactInfo?: {
      __typename: "ContactInfo",
      id: string,
      userId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      avatar?: string | null,
      phone?: string | null,
      permissionToShare?: boolean | null,
      contacts?: {
        __typename: "ModelContactConnection",
        items: Array<{
          __typename: "Contact",
          id: string,
          contactInfoId: string,
          clientId: string,
          type?: ContactType | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    clientProfile?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    deletedAt?: string | null,
  } | null,
};

export type OnUpdateContactInfoSubscriptionVariables = {
  id: string,
};

export type OnUpdateContactInfoSubscription = {
  onUpdateContactInfo?: {
    __typename: "ContactInfo",
    id: string,
    userId?: string | null,
    email?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    avatar?: string | null,
    phone?: string | null,
    permissionToShare?: boolean | null,
    contacts?: {
      __typename: "ModelContactConnection",
      items: Array<{
        __typename: "Contact",
        id: string,
        contactInfoId: string,
        clientId: string,
        type?: ContactType | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        client?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSpecialistProfileSubscriptionVariables = {
  id: string,
};

export type OnUpdateSpecialistProfileSubscription = {
  onUpdateSpecialistProfile?: {
    __typename: "SpecialistProfile",
    id: string,
    displayName?: string | null,
    businessName?: string | null,
    qualifications?: string | null,
    clinicalSpecialities?: Array<string | null> | null,
    sportSpecialities?: Array<string | null> | null,
    bio?: string | null,
    trivia?: Array<string | null> | null,
    providerNumber?: string | null,
    businessIdNumber?: string | null,
    gender?: string | null,
    dateOfBirth?: string | null,
    image?: string | null,
    video?: string | null,
    status?: string | null,
    isAvailable?: boolean | null,
    clientLimit?: number | null,
    isTeamsAvailable?: boolean | null,
    socialAccounts?: Array<{
      __typename: "SocialAccount",
      network?: string | null,
      account?: string | null,
    } | null> | null,
    referralLink?: string | null,
    integrations?: Array<{
      __typename: "Integration",
      name?: string | null,
      attributes?: string | null,
    } | null> | null,
    ownerId: string,
    owner?: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    } | null,
    clients?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    myClients?: {
      __typename: "ModelClientConnection",
      items: Array<{
        __typename: "Client",
        id: string,
        userId: string,
        specialistId: string,
        user: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        },
        specialist: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        },
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        conversations?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        clientNotes?: {
          __typename: "ModelClientNoteConnection",
          nextToken?: string | null,
        } | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClientSubscriptionVariables = {
  id: string,
};

export type OnUpdateClientSubscription = {
  onUpdateClient?: {
    __typename: "Client",
    id: string,
    userId: string,
    specialistId: string,
    user: {
      __typename: "User",
      id: string,
      authId?: string | null,
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      profile?: {
        __typename: "Profile",
        sex?: string | null,
        pronouns?: string | null,
        ageBracket?: string | null,
      } | null,
      avatar?: string | null,
      phone?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      bodyType?: string | null,
      height?: string | null,
      weight?: number | null,
      bodyFat?: number | null,
      activityFactor?: number | null,
      nutritionNotes?: string | null,
      joinReason?: string | null,
      trainingCalendarLink?: string | null,
      guessedLocation?: string | null,
      journal?: Array<{
        __typename: "Record",
        weight?: string | null,
        waist?: string | null,
        appetiteRating?: number | null,
        energyRating?: number | null,
        sleepRating?: number | null,
        motivationRating?: number | null,
        wellnessRating?: number | null,
        stressRating?: number | null,
        nutritionRating?: number | null,
        createdAt: string,
      } | null> | null,
      events?: Array<{
        __typename: "Event",
        name: string,
        details?: string | null,
        date?: string | null,
        location?: string | null,
        goal?: string | null,
        preEventFoodRituals?: string | null,
        nutritionProducts?: string | null,
        nutritionLogistics?: string | null,
        issues?: string | null,
        leadupEvents?: string | null,
        accomodation?: string | null,
        leadupEventDate?: string | null,
        leadupEventDetails?: string | null,
        createdAt: string,
      } | null> | null,
      sports?: Array<{
        __typename: "Sport",
        name?: string | null,
        level?: string | null,
        comments?: string | null,
      } | null> | null,
      goals?: Array<{
        __typename: "Goal",
        description: string,
        comments?: string | null,
        progress?: Array<{
          __typename: "Progress",
          rating: number,
          createdAt: string,
        } | null> | null,
        targetDate?: string | null,
        createdAt: string,
      } | null> | null,
      teams?: Array<{
        __typename: "TeamGroup",
        name?: string | null,
        joinedAt?: string | null,
      } | null> | null,
      foodDiary?: Array<{
        __typename: "DailyLog",
        date?: string | null,
        day?: string | null,
        items?: Array<{
          __typename: "LogItem",
          time?: string | null,
          detail?: string | null,
          comments?: string | null,
        } | null> | null,
      } | null> | null,
      menuScheduleDay?: Day | null,
      nutritionInformation?: {
        __typename: "Nutrition",
        allergies?: Array<string | null> | null,
        supplements?: string | null,
        dislikes?: string | null,
        comments?: string | null,
        preferences?: string | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        dietaryRequirements?: Array<string | null> | null,
      } | null,
      nutritionAssessments?: Array<{
        __typename: "NutritionAssessment",
        requestDate: string,
        responseStartDate?: string | null,
        responseCompleteDate?: string | null,
        responses?: Array<{
          __typename: "Response",
          question?: string | null,
          answer?: string | null,
          comments?: string | null,
          score?: number | null,
        } | null> | null,
        score?: number | null,
      } | null> | null,
      lifestyle?: {
        __typename: "Lifestyle",
        cookingSkills?: string | null,
        cookingMethods?: Array<string | null> | null,
        easyNights?: Array<Day | null> | null,
        homeSituation?: string | null,
        peopleToFeed?: string | null,
        workDays?: Array<Day | null> | null,
        nightsEatingOut?: Array<Day | null> | null,
        workShifts?: string | null,
        breakfastOnTheRun?: Array<Day | null> | null,
        occupation?: string | null,
        preferences?: string | null,
      } | null,
      healthInformation?: {
        __typename: "HealthInformation",
        medicalConditions?: string | null,
        medication?: string | null,
        isMenstruating?: boolean | null,
        regularPeriod?: boolean | null,
        periodStoppedOver3Months?: string | null,
      } | null,
      membership?: {
        __typename: "Membership",
        id?: string | null,
        amount?: string | null,
        plan?: string | null,
        discount?: string | null,
        createdAt?: string | null,
        expiresAt?: string | null,
        cancelAtPeriodEnd?: boolean | null,
      } | null,
      stripeCustomerId?: string | null,
      permission?: {
        __typename: "Permission",
        hasMeals?: boolean | null,
        hasHabits?: boolean | null,
      } | null,
      priority?: {
        __typename: "Priority",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null,
      priorityConfidence?: Array<{
        __typename: "PriorityConfidence",
        bonking?: number | null,
        energyLevels?: number | null,
        gutUpset?: number | null,
        manageCramping?: number | null,
        muscleMass?: number | null,
        overallHealth?: number | null,
        performance?: number | null,
        raceDayNutrition?: number | null,
        trainingAdaptations?: number | null,
        weightLoss?: number | null,
        createdAt: string,
      } | null> | null,
      userRole?: Role | null,
      lastActiveAt?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      paymentMethod?: {
        __typename: "PaymentMethod",
        id?: string | null,
        type?: string | null,
        brand?: string | null,
        exp_month?: number | null,
        exp_year?: number | null,
        last4?: string | null,
      } | null,
      status?: Status | null,
      devices?: {
        __typename: "ModelDeviceConnection",
        items: Array<{
          __typename: "Device",
          id: string,
          token: string,
          platform: string,
          bundleId: string,
          deviceArn?: string | null,
          deviceUserId?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      messages?: {
        __typename: "ModelMessageConnection",
        items: Array<{
          __typename: "Message",
          id: string,
          content?: string | null,
          createdAt?: string | null,
          messageAuthorId?: string | null,
          readBy?: Array<string | null> | null,
          messageConversationId: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      specialists?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      alerts?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      notifications?: {
        __typename: "ModelAlertConnection",
        items: Array<{
          __typename: "Alert",
          id: string,
          content?: string | null,
          action?: Action | null,
          isRead?: boolean | null,
          createdAt: string,
          updatedAt: string,
          alertUserId: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      editors?: Array<string | null> | null,
      viewers?: Array<string | null> | null,
      contactInfoId?: string | null,
      contactInfo?: {
        __typename: "ContactInfo",
        id: string,
        userId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        avatar?: string | null,
        phone?: string | null,
        permissionToShare?: boolean | null,
        contacts?: {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      clientProfile?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      deletedAt?: string | null,
    },
    specialist: {
      __typename: "SpecialistProfile",
      id: string,
      displayName?: string | null,
      businessName?: string | null,
      qualifications?: string | null,
      clinicalSpecialities?: Array<string | null> | null,
      sportSpecialities?: Array<string | null> | null,
      bio?: string | null,
      trivia?: Array<string | null> | null,
      providerNumber?: string | null,
      businessIdNumber?: string | null,
      gender?: string | null,
      dateOfBirth?: string | null,
      image?: string | null,
      video?: string | null,
      status?: string | null,
      isAvailable?: boolean | null,
      clientLimit?: number | null,
      isTeamsAvailable?: boolean | null,
      socialAccounts?: Array<{
        __typename: "SocialAccount",
        network?: string | null,
        account?: string | null,
      } | null> | null,
      referralLink?: string | null,
      integrations?: Array<{
        __typename: "Integration",
        name?: string | null,
        attributes?: string | null,
      } | null> | null,
      ownerId: string,
      owner?: {
        __typename: "User",
        id: string,
        authId?: string | null,
        email?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        profile?: {
          __typename: "Profile",
          sex?: string | null,
          pronouns?: string | null,
          ageBracket?: string | null,
        } | null,
        avatar?: string | null,
        phone?: string | null,
        gender?: string | null,
        dateOfBirth?: string | null,
        bodyType?: string | null,
        height?: string | null,
        weight?: number | null,
        bodyFat?: number | null,
        activityFactor?: number | null,
        nutritionNotes?: string | null,
        joinReason?: string | null,
        trainingCalendarLink?: string | null,
        guessedLocation?: string | null,
        journal?: Array<{
          __typename: "Record",
          weight?: string | null,
          waist?: string | null,
          appetiteRating?: number | null,
          energyRating?: number | null,
          sleepRating?: number | null,
          motivationRating?: number | null,
          wellnessRating?: number | null,
          stressRating?: number | null,
          nutritionRating?: number | null,
          createdAt: string,
        } | null> | null,
        events?: Array<{
          __typename: "Event",
          name: string,
          details?: string | null,
          date?: string | null,
          location?: string | null,
          goal?: string | null,
          preEventFoodRituals?: string | null,
          nutritionProducts?: string | null,
          nutritionLogistics?: string | null,
          issues?: string | null,
          leadupEvents?: string | null,
          accomodation?: string | null,
          leadupEventDate?: string | null,
          leadupEventDetails?: string | null,
          createdAt: string,
        } | null> | null,
        sports?: Array<{
          __typename: "Sport",
          name?: string | null,
          level?: string | null,
          comments?: string | null,
        } | null> | null,
        goals?: Array<{
          __typename: "Goal",
          description: string,
          comments?: string | null,
          targetDate?: string | null,
          createdAt: string,
        } | null> | null,
        teams?: Array<{
          __typename: "TeamGroup",
          name?: string | null,
          joinedAt?: string | null,
        } | null> | null,
        foodDiary?: Array<{
          __typename: "DailyLog",
          date?: string | null,
          day?: string | null,
        } | null> | null,
        menuScheduleDay?: Day | null,
        nutritionInformation?: {
          __typename: "Nutrition",
          allergies?: Array<string | null> | null,
          supplements?: string | null,
          dislikes?: string | null,
          comments?: string | null,
          preferences?: string | null,
          dietaryRequirements?: Array<string | null> | null,
        } | null,
        nutritionAssessments?: Array<{
          __typename: "NutritionAssessment",
          requestDate: string,
          responseStartDate?: string | null,
          responseCompleteDate?: string | null,
          score?: number | null,
        } | null> | null,
        lifestyle?: {
          __typename: "Lifestyle",
          cookingSkills?: string | null,
          cookingMethods?: Array<string | null> | null,
          easyNights?: Array<Day | null> | null,
          homeSituation?: string | null,
          peopleToFeed?: string | null,
          workDays?: Array<Day | null> | null,
          nightsEatingOut?: Array<Day | null> | null,
          workShifts?: string | null,
          breakfastOnTheRun?: Array<Day | null> | null,
          occupation?: string | null,
          preferences?: string | null,
        } | null,
        healthInformation?: {
          __typename: "HealthInformation",
          medicalConditions?: string | null,
          medication?: string | null,
          isMenstruating?: boolean | null,
          regularPeriod?: boolean | null,
          periodStoppedOver3Months?: string | null,
        } | null,
        membership?: {
          __typename: "Membership",
          id?: string | null,
          amount?: string | null,
          plan?: string | null,
          discount?: string | null,
          createdAt?: string | null,
          expiresAt?: string | null,
          cancelAtPeriodEnd?: boolean | null,
        } | null,
        stripeCustomerId?: string | null,
        permission?: {
          __typename: "Permission",
          hasMeals?: boolean | null,
          hasHabits?: boolean | null,
        } | null,
        priority?: {
          __typename: "Priority",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null,
        priorityConfidence?: Array<{
          __typename: "PriorityConfidence",
          bonking?: number | null,
          energyLevels?: number | null,
          gutUpset?: number | null,
          manageCramping?: number | null,
          muscleMass?: number | null,
          overallHealth?: number | null,
          performance?: number | null,
          raceDayNutrition?: number | null,
          trainingAdaptations?: number | null,
          weightLoss?: number | null,
          createdAt: string,
        } | null> | null,
        userRole?: Role | null,
        lastActiveAt?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        paymentMethod?: {
          __typename: "PaymentMethod",
          id?: string | null,
          type?: string | null,
          brand?: string | null,
          exp_month?: number | null,
          exp_year?: number | null,
          last4?: string | null,
        } | null,
        status?: Status | null,
        devices?: {
          __typename: "ModelDeviceConnection",
          nextToken?: string | null,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        specialists?: {
          __typename: "ModelConversationConnection",
          nextToken?: string | null,
        } | null,
        alerts?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        notifications?: {
          __typename: "ModelAlertConnection",
          nextToken?: string | null,
        } | null,
        editors?: Array<string | null> | null,
        viewers?: Array<string | null> | null,
        contactInfoId?: string | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        clientProfile?: {
          __typename: "ModelClientConnection",
          nextToken?: string | null,
        } | null,
        deletedAt?: string | null,
      } | null,
      clients?: {
        __typename: "ModelConversationConnection",
        items: Array<{
          __typename: "Conversation",
          id: string,
          specialistClientId?: string | null,
          clientId?: string | null,
          specialistId?: string | null,
          title?: string | null,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          members?: Array<string | null> | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      myClients?: {
        __typename: "ModelClientConnection",
        items: Array<{
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null>,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    contacts?: {
      __typename: "ModelContactConnection",
      items: Array<{
        __typename: "Contact",
        id: string,
        contactInfoId: string,
        clientId: string,
        type?: ContactType | null,
        contactInfo?: {
          __typename: "ContactInfo",
          id: string,
          userId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          permissionToShare?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        client?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    conversations?: {
      __typename: "ModelConversationConnection",
      items: Array<{
        __typename: "Conversation",
        id: string,
        specialistClientId?: string | null,
        clientId?: string | null,
        specialistId?: string | null,
        title?: string | null,
        client?: {
          __typename: "User",
          id: string,
          authId?: string | null,
          email?: string | null,
          firstName?: string | null,
          lastName?: string | null,
          avatar?: string | null,
          phone?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          bodyType?: string | null,
          height?: string | null,
          weight?: number | null,
          bodyFat?: number | null,
          activityFactor?: number | null,
          nutritionNotes?: string | null,
          joinReason?: string | null,
          trainingCalendarLink?: string | null,
          guessedLocation?: string | null,
          menuScheduleDay?: Day | null,
          stripeCustomerId?: string | null,
          userRole?: Role | null,
          lastActiveAt?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          status?: Status | null,
          editors?: Array<string | null> | null,
          viewers?: Array<string | null> | null,
          contactInfoId?: string | null,
          deletedAt?: string | null,
        } | null,
        specialistClient?: {
          __typename: "Client",
          id: string,
          userId: string,
          specialistId: string,
          status?: Status | null,
          servicingNote?: string | null,
          settings?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        specialist?: {
          __typename: "SpecialistProfile",
          id: string,
          displayName?: string | null,
          businessName?: string | null,
          qualifications?: string | null,
          clinicalSpecialities?: Array<string | null> | null,
          sportSpecialities?: Array<string | null> | null,
          bio?: string | null,
          trivia?: Array<string | null> | null,
          providerNumber?: string | null,
          businessIdNumber?: string | null,
          gender?: string | null,
          dateOfBirth?: string | null,
          image?: string | null,
          video?: string | null,
          status?: string | null,
          isAvailable?: boolean | null,
          clientLimit?: number | null,
          isTeamsAvailable?: boolean | null,
          referralLink?: string | null,
          ownerId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        messages?: {
          __typename: "ModelMessageConnection",
          nextToken?: string | null,
        } | null,
        status?: Status | null,
        servicingNote?: string | null,
        notes?: Array<{
          __typename: "Note",
          title: string,
          content: string,
          createdAt: string,
          authorId?: string | null,
        } | null> | null,
        files?: Array<{
          __typename: "File",
          title: string,
          url: string,
          type?: string | null,
          createdAt: string,
        } | null> | null,
        settings?: string | null,
        members?: Array<string | null> | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    status?: Status | null,
    servicingNote?: string | null,
    notes?: Array<{
      __typename: "Note",
      title: string,
      content: string,
      createdAt: string,
      authorId?: string | null,
    } | null> | null,
    clientNotes?: {
      __typename: "ModelClientNoteConnection",
      items: Array<{
        __typename: "ClientNote",
        id: string,
        clientId: string,
        title: string,
        createdAt?: string | null,
        flag?: string | null,
        goals?: string | null,
        focusAreas?: string | null,
        goalsFocusAreas?: string | null,
        progressChallenges?: string | null,
        progress?: string | null,
        challenges?: string | null,
        isOnApp?: boolean | null,
        engagement?: string | null,
        engagementStatus?: string | null,
        clinical?: string | null,
        symptoms?: string | null,
        anthropometry?: string | null,
        dietHistory?: string | null,
        training?: string | null,
        results?: string | null,
        social?: string | null,
        energyRequirements?: string | null,
        supplementsMedication?: string | null,
        other?: string | null,
        futureMe?: string | null,
        actionNeeded?: string | null,
        comments?: string | null,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    files?: Array<{
      __typename: "File",
      title: string,
      url: string,
      type?: string | null,
      createdAt: string,
    } | null> | null,
    settings?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInvitationSubscriptionVariables = {
  id: string,
};

export type OnUpdateInvitationSubscription = {
  onUpdateInvitation?: {
    __typename: "Invitation",
    id: string,
    clientId: string,
    invitedUserId?: string | null,
    email: string,
    contactType?: ContactType | null,
    firstName?: string | null,
    lastName?: string | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateHabitsByUserSubscriptionVariables = {
  userId: string,
};

export type OnCreateHabitsByUserSubscription = {
  onCreateHabitsByUser?: {
    __typename: "Habits",
    id: string,
    identityGoal?: string | null,
    weeklyReflectionDay?: Day | null,
    log?: Array<{
      __typename: "HabitLogEntry",
      content: string,
      type: string,
      createdAt: string,
    } | null> | null,
    behaviours?: Array<{
      __typename: "Behaviour",
      id?: string | null,
      behaviour?: string | null,
      status?: string | null,
      colour?: string | null,
    } | null> | null,
    actions?: Array<{
      __typename: "ActionTask",
      id?: string | null,
      description?: string | null,
      status?: string | null,
      behaviour?: string | null,
      behaviourId?: string | null,
      notifyTimestamp?: string | null,
    } | null> | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type OnUpdateHabitsSubscriptionVariables = {
  id: string,
};

export type OnUpdateHabitsSubscription = {
  onUpdateHabits?: {
    __typename: "Habits",
    id: string,
    identityGoal?: string | null,
    weeklyReflectionDay?: Day | null,
    log?: Array<{
      __typename: "HabitLogEntry",
      content: string,
      type: string,
      createdAt: string,
    } | null> | null,
    behaviours?: Array<{
      __typename: "Behaviour",
      id?: string | null,
      behaviour?: string | null,
      status?: string | null,
      colour?: string | null,
    } | null> | null,
    actions?: Array<{
      __typename: "ActionTask",
      id?: string | null,
      description?: string | null,
      status?: string | null,
      behaviour?: string | null,
      behaviourId?: string | null,
      notifyTimestamp?: string | null,
    } | null> | null,
    status?: Status | null,
    userId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    updatedAt?: string | null,
    createdAt?: string | null,
  } | null,
};

export type OnCreateMealPlanSubscriptionVariables = {
  userId: string,
};

export type OnCreateMealPlanSubscription = {
  onCreateMealPlan?: {
    __typename: "MealPlan",
    id: string,
    type?: string | null,
    kilojoules?: number | null,
    mealGroups?: Array<{
      __typename: "MealGroup",
      type?: string | null,
      count?: number | null,
    } | null> | null,
    meals?: {
      __typename: "ModelMealPlanMealConnection",
      items: Array<{
        __typename: "MealPlanMeal",
        id: string,
        mealPlanId?: string | null,
        mealId?: string | null,
        meal: {
          __typename: "Meal",
          id: string,
          author: string,
          title: string,
          image?: string | null,
          description?: string | null,
          mealTypes?: Array<string | null> | null,
          dietary?: Array<string | null> | null,
          allergens?: Array<string | null> | null,
          cuisines?: Array<string | null> | null,
          tags?: Array<string | null> | null,
          cookingMethods?: Array<string | null> | null,
          cookingSkill?: number | null,
          serves?: number | null,
          notes?: string | null,
          status?: Status | null,
          showInRecipe?: boolean | null,
          updatedAt?: string | null,
          createdAt: string,
        },
        type?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    mealPlanSetId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMealPlanSubscriptionVariables = {
  userId: string,
};

export type OnUpdateMealPlanSubscription = {
  onUpdateMealPlan?: {
    __typename: "MealPlan",
    id: string,
    type?: string | null,
    kilojoules?: number | null,
    mealGroups?: Array<{
      __typename: "MealGroup",
      type?: string | null,
      count?: number | null,
    } | null> | null,
    meals?: {
      __typename: "ModelMealPlanMealConnection",
      items: Array<{
        __typename: "MealPlanMeal",
        id: string,
        mealPlanId?: string | null,
        mealId?: string | null,
        meal: {
          __typename: "Meal",
          id: string,
          author: string,
          title: string,
          image?: string | null,
          description?: string | null,
          mealTypes?: Array<string | null> | null,
          dietary?: Array<string | null> | null,
          allergens?: Array<string | null> | null,
          cuisines?: Array<string | null> | null,
          tags?: Array<string | null> | null,
          cookingMethods?: Array<string | null> | null,
          cookingSkill?: number | null,
          serves?: number | null,
          notes?: string | null,
          status?: Status | null,
          showInRecipe?: boolean | null,
          updatedAt?: string | null,
          createdAt: string,
        },
        type?: string | null,
        note?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null>,
      nextToken?: string | null,
    } | null,
    startingAt?: string | null,
    status?: Status | null,
    userId?: string | null,
    mealPlanSetId?: string | null,
    editors?: Array<string | null> | null,
    viewers?: Array<string | null> | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
